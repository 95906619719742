import React from 'react';
import Brandphilosophymodel from '../../GlobalComponent/Model/Brandphilosophyhindimodel';
import { Table } from 'react-bootstrap';
import { MdFileDownloadDone, MdDeleteOutline } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useGetBrandPhilosophyEnglishQuery, useGetBrandPhilosophyHindiQuery } from './brandPhilosophyApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandphilosophyhindimodel from '../../GlobalComponent/Model/Brandphilosophyhindimodel';
import Brandphilosophyenglishmodel from '../../GlobalComponent/Model/Brandphilosophyenglishmodel';
function Brandphilosophy() {
  const Philosophyhindi = useGetBrandPhilosophyHindiQuery()
  const Philosophyenglish = useGetBrandPhilosophyEnglishQuery()
  return (
    <>
      <div className="container-fluid">
        <div>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Brand Philosophy Hindi">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">
                  Brand Philosophy Section For Hindi
                </h1>
              </div> */}

              <div className="row ">
                <div className=" col-md-12">
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Web Image</th>
                        <th>Mobile Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Philosophyhindi?.isLoading ? (
                        <Loader />
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>
                            <img
                              style={{ width: "auto", height: "150px" }}
                              src={Philosophyhindi?.data?.philosophy?.imageUrl}
                            ></img>
                          </td>
                          <td>
                            <img
                              style={{ width: "auto", height: "150px" }}
                              src={
                                Philosophyhindi?.data?.philosophy?.mobileImage
                              }
                            ></img>
                          </td>
                          <td>
                            <Brandphilosophyhindimodel
                              previousData={Philosophyhindi?.data?.philosophy}
                              value={"brandphilosophyhindi"}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>

            <Tab
              eventKey="profile"
              title="Brand Philosophy English"
            >
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">
                  Brand Philosophy Section For English
                </h1>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Web Image</th>
                        <th>Mobile Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Philosophyenglish?.isLoading ? (
                        <Loader />
                      ) : (
                        <tr>
                          <td>1</td>
                          <td>
                            <img
                              style={{ width: "auto", height: "150px" }}
                              src={
                                Philosophyenglish?.data?.philosophy?.imageUrl
                              }
                            ></img>
                          </td>

                          <td>
                            <img
                              style={{ width: "auto", height: "150px" }}
                              src={
                                Philosophyenglish?.data?.philosophy?.mobileImage
                              }
                            ></img>
                          </td>
                          <td>
                            <Brandphilosophyenglishmodel
                              previousData={Philosophyenglish?.data?.philosophy}
                              value={"brandphilosophyenglish"}
                              className="text-success"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandphilosophy;