import React, { useState, useEffect } from "react";

const OrderInvoice = ({ orderId, medusatoken }) => {
  // console.log(orderId);
  const [OrderInvoice, setOrderInvoiceData] = useState(""); // State to store the selected status

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${medusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}store/invoice/${orderId}`,
          requestOptions
        );
        const result = await response.json();
        setOrderInvoiceData(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* <input
        type="text"
        placeholder="Order Edit ID"
        value={orderId}
        // onChange={(e) => setOrderEditId(e.target.value)}
      /> */}
      {/* <select
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
      >
        <option value="">Select Status</option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select> */}
      {OrderInvoice.invoice !== null ? (
        <a href={OrderInvoice.invoice?.file_path} target="_blank">
          View Invoice
        </a>
      ) : (
        <small>Not Genrated</small>
      )}
    </div>
  );
};

export default OrderInvoice;
