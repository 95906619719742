import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../../features/auth/authSlice'
const baseQuery = fetchBaseQuery({
  // baseUrl: "https://dev.bailkolhu.com/api/",
  baseUrl: `${process.env.REACT_APP_BASE_URL}api/`,
  // baseUrl: "https://www.bailkolhu.com/api/",
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accesstoken
        if (!headers.has("authorization")) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  },
})
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  
  //console.log(result)
  // console.log(api.getState().auth)
  if (result?.error?.status === 401 || result.meta?.response?.status === 401) {
    // console.log("access token expired")
    const state = api.getState()

    // send refresh token to get new access token 
    // console.log(state.auth.refreshToken)
    const refreshUserResult = await baseQuery({ url: 'token', method: 'POST', headers: { authorization: `Bearer ${state.auth.refreshToken}` } }, api, extraOptions)
    console.log(refreshUserResult)
    if (refreshUserResult?.data) {
      // console.log("refresh token applied")
      // console.log(refreshUserResult)
      // store the new access token 
      api.dispatch(setCredentials({ ...refreshUserResult.data }))

      // retry the original query with new access token 
      result = await baseQuery(args, api, extraOptions)

      // console.log(api?.getState()?.auth)

    } else {
      api.dispatch(logOut())
      localStorage.removeItem("userInfo")
    }
  }
  return result
}
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({})
})