import React, { useMemo } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useGetAllAgentListQuery } from "../Forms/formApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import ComicAgentCreateForm from "../Forms/ComicAgentCreateForm";

function CreateAgent() {
  const { data, isLoading, isFetching, refetch } = useGetAllAgentListQuery();

  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) {
      return "";
    }

    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); //Use array instead of data

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "ContactData.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
  const Export = ({ onExport }) => (
    <button
      className="btn btn-sm btn-primary shadow-sm"
      onClick={(e) => onExport(e.target.value)}
    >
      <i className="fas fa-download fa-sm text-white-50"></i> Export Contact Us
      Data
    </button>
  );

  // Download csv Code End

  const columns = [
    {
      name: "Agent ID",
      selector: (row) => row.agentId,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Mobile",
      selector: (row) => row.contact,
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
  ];
  const queries = data?.agents || [];
  const Tabledata = queries.map((item, index) => ({
    agentId: (item.agentId !== undefined ? item.agentId : "NAN"),
    createdAt: format(new Date(item.createdAt), "MM/dd/yyyy HH:mm:ss"),
    name: item.name,
    email: item.email,
    contact: item.contact,
    role: item.role,
    status: item.status,
  }));

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(Tabledata)} />,
    []
  );

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Contact Us Form</h1>
          <ComicAgentCreateForm />
        </div>
        <div className="row">
          <div className="col-md-12">
            {isFetching && isLoading ? (
              <Loader />
            ) : (
              <DataTable
                columns={columns}
                data={Tabledata}
                pagination
                defaultSortDesc={true}
                actions={actionsMemo}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAgent;
