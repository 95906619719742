import React from "react";
// import './imageshowing.css'
import { useContext } from "react";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import deleteImage from "../../images/delete-332.png";
import {
  useDeleteImagesMutation,
  useGetAllImagesQuery,
} from "../GlobalComponent/Media/mediaApiSlice";

function Imageshowing({ data, value }) {
  const getAllMediaImages = useGetAllImagesQuery();
  const [deleteImages] = useDeleteImagesMutation();
  const { Swal } = useContext(Auth);
  const onHandleDelete = (value) => {
    let lastElement = value.split("/")[value.split("/").length - 1];
    const raw = {
      filename: lastElement,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteImages(raw)
          .unwrap()
          .then((data) => {
            getAllMediaImages.refetch();
          })
          .catch((error) => {
            alert(error);
          });
      }
    });
  };

  const { images, setImages } = useContext(Auth);
  const handleSubmit = (e, item) => {
    const id = data?.imageurls.indexOf(item) + 1;
    if (
      value === "campaignaboutLeftimage" ||
      value === "campaignsliderimage" ||
      value === "campaignslidermobileimage"
    ) {
      if (e.target.checked) {
        setImages({
          mediaImages: images.mediaImages.concat(item),
        });
      } else {
        setImages({
          mediaImages: images.mediaImages.filter(function (val) {
            return val !== item;
          }),
        });
      }
    } else {
      for (var i = 1; i <= data?.imageurls?.length; i++) {
        if (i === id && document.getElementById(id).checked === true) {
          document.getElementById(i).checked = true;
          setImages({
            mediaImages: item,
          });
        } else {
          document.getElementById(i).checked = false;
        }
      }
    }
    // for single image
    // const id = data?.imageurls.indexOf(item) + 1
    // for (var i = 1; i <= data?.imageurls?.length; i++) {
    //     if (i === id && document.getElementById(id).checked === true) {
    //         document.getElementById(i).checked = true;
    //         setImages({
    //             mediaImages: item
    //         })
    //     } else {
    //         document.getElementById(i).checked = false;
    //     }
    // }

    //  for multiple add a image
    // if (e.target.checked) {
    //     setImages({
    //         mediaImages: images.mediaImages.concat([item])
    //     })
    // } else {
    //     setImages({ mediaImages: images.mediaImages.filter(function (val) { return val !== item }) })
    // }
  };
  return (
    <>
      <div className="row">
        {data?.imageurls?.map((item, i) => {
          return (
            <div className="col-md-4" key={i}>
              <div className="d-flex justify-content-between">
                <input
                  onClick={(e) => {
                    handleSubmit(e, item);
                  }}
                  type="checkbox"
                  name="language"
                  id={i + 1}
                />

                {/* <RxCrossCircled onClick={() => { onHandleDelete(item) }} className='delteicon' /> */}
                <a
                  onClick={() => {
                    onHandleDelete(item);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash text-danger"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                  </svg>
                </a>
              </div>

              <label for={i + 1} className="imageDiv">
                <img src={item} className="img-fluid" />
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Imageshowing;
