import React, { useState } from 'react';

const OrderEditStatusChanger = ({orderId, medusatoken}) => {
    console.log(orderId)
  const [orderEditId, setOrderEditId] = useState('');
  const [newStatus, setNewStatus] = useState(''); // State to store the selected status

  const statusOptions = ['confirmed', 'declined', 'requested', 'created', 'canceled'];

  const handleChangeStatus = async () => {
    try {
      // Create a request object with the new status
      const orderEditData = {
        status: newStatus, // Use the selected status
      };

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}admin/orders/${orderId}/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${medusatoken}`,
        },
        body: JSON.stringify(orderEditData),
      });

      if (response.ok) {
        // Status change successful
        console.log('Order edit status changed successfully');
      } else {
        // Handle error if status change failed
        console.error('Failed to change order edit status');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      {/* <input
        type="text"
        placeholder="Order Edit ID"
        value={orderId}
        // onChange={(e) => setOrderEditId(e.target.value)}
      /> */}
      {/* <select
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
      >
        <option value="">Select Status</option>
        {statusOptions.map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select> */}
      <button type="button" onClick={handleChangeStatus} className='text-wrap btn btn-warning bold '><small style={{fontSize : "12px"}}>Mark as Order Delivered to User </small></button>
    </div>
  );
};

export default OrderEditStatusChanger;
