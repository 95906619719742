import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext'
import { useContext } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import './model.css'
import { HeaderHindiVerticallyCenteredModal } from '../../Common/Header.js/updateheader';

function Headermodelhindi({ previousData, value }) {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <HeaderHindiVerticallyCenteredModal
                value={value}
                previousData={previousData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Headermodelhindi;