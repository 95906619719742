import React, { useState } from "react";
import Files from "react-files";
import Card from "react-bootstrap/Card";
import drapanddrop from "../../images/Headerimages/jquery-file-upload-scripts.webp";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import { useContext } from "react";
import { Accordion, Button, Tab, Tabs } from "react-bootstrap";
import Editor from "../GlobalComponent/Editor/Editor";
import Select from "react-select";

import {
  useCreateSliderMutation,
  useGetAllSliderQuery,
} from "../../features/slider/sliderApiSlice";
import {
  useGetAllImagesQuery,
  useImportImageMutation,
} from "../GlobalComponent/Media/mediaApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import Imageshowing from "./Imageshowing";
import Media from "../GlobalComponent/Media/Media";
import {
  useCreateAboutOilMutation,
  useGetAllAboutOilEnglishQuery,
  useGetAllAboutOilHindiQuery,
} from "../Home/Brandaboutoil.js/brandAboutOilSplice";
import { useEffect } from "react";
import {
  useAddTvcMutation,
  useGetTvcQuery,
} from "../Home/Brandtvc/tvcApiSlice";
import {
  useCreateBlogMutation,
  useGetAllBlogQuery,
} from "../Home/Brandblogs/blogApiSlice";
import { useCreateComicMutation } from "../Campaign/campaignApiSlice";
import { useGetAllComicQuery } from "../Campaign/campaignApiSlice";
import Multipleinput from "../GlobalComponent/Multipleinput.js/Multipleinput";
import Campabouteditor from "../GlobalComponent/Editor/Campabouteditor/Campabouteditor";
import Campstoryeditor from "../GlobalComponent/Editor/Campstoryeditor/Campstoryeditor";
import { getSlug } from "../GlobalComponent/global";
import swal from "sweetalert";
import "react-toastify/dist/ReactToastify.css";
import CardHeader from "react-bootstrap/esm/CardHeader";
import Editortinyme from "../GlobalComponent/Editor/EditorForAdd";
import Commoneditor from "../GlobalComponent/Editor/Globaleditor/Editor";
import EditorForUpdate from "../GlobalComponent/Editor/Globaleditor/EditorForUpdate";


export function CampaignVerticallyCenteredModal(props) {
  const [selectedOptions, setSelectedOptions] = useState();
  const optionList = [
    { value: "Slider", label: "Slider" },
    { value: "About", label: "About" },
    { value: "productpage", label: "Product page" },
    { value: "testimonials", label: "   Testimonials" },
    { value: "opinionbox", label: "Opinion Box" },
    { value: "tvc", label: "TVC" },
    { value: "contact", label: "Contact" },
  ];
  function handleSelect(data) {
    setSelectedOptions(data);
  }
  const { handleError } = useContext(Auth);
  const [state, setState] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState([]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title>Create Your New Campaign Header </Card.Title>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Enter Your Campaign Name
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Enter Your Slug Name
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100 "
                          type="text"
                          placeholder="Enter Your Campaign Name"
                        />
                      </div>

                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          type="text"
                          placeholder="Enter Your  Slug Name "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          {" "}
                          Choose Language
                        </Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Select Component
                        </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Select aria-label="Default select example">
                          <option value="1" selected>
                            Hindi
                          </option>
                          <option value="2">English</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-6 ">
                        <Select
                          options={optionList}
                          placeholder="Select color"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isSearchable={true}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                  <Card.Title>Choose Small Campaign Image</Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Files
                        className="files-dropzone"
                        onChange={(files) => {
                          setState(files);
                        }}
                        onError={handleError}
                        accepts={["image/png", ".pdf", "audio/*"]}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <img
                          alt="draganddropimage"
                          src={drapanddrop}
                          className="draganddropimage"
                        ></img>
                      </Files>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      {state?.map((item) => {
                        return (
                          <div>
                            <div className="nav-link" href="#">
                              <img
                                alt="logoiamge"
                                src={item?.preview?.url}
                                className="logo-image mt-3"
                              ></img>
                              <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setState([]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Card.Title>Choose Your Background Image</Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Files
                        className="files-dropzone"
                        onChange={(files) => {
                          setBackgroundImage(files);
                        }}
                        onError={handleError}
                        accepts={["image/png", ".pdf", "audio/*"]}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <img
                          alt="draganddropimage"
                          src={drapanddrop}
                          className="draganddropimage"
                        ></img>
                      </Files>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-5">
                      {backgroundImage?.map((item) => {
                        return (
                          <div>
                            <div className="nav-link" href="#">
                              <img
                                alt="draganddropimage"
                                src={item?.preview?.url}
                                className="logo-image mt-3"
                              ></img>
                              <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setBackgroundImage([]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Card.Title>Enter Your Script Here </Card.Title>
                      <Form.Control as="textarea" rows={4} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button>Save </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function ProductVerticallyCenteredModal(props) {
  const { handleError } = useContext(Auth);
  const [state, setState] = useState([]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100 "
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Your  Slug Name "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Create Your New Product{" "}
                  </Card.Title>
                  <Editor />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Files
                        className="files-dropzone"
                        onChange={(files) => {
                          setState(files);
                        }}
                        onError={handleError}
                        accepts={["image/png", ".pdf", "audio/*"]}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <img
                          alt="draganddropimage"
                          src={drapanddrop}
                          className="draganddropimage"
                        ></img>
                      </Files>
                    </div>
                    <div className="col-md-2"></div>

                    <div className="col-md-5">
                      {state?.map((item) => {
                        return (
                          <div>
                            <div className="nav-link" href="#">
                              <img
                                alt="draganddropimage"
                                src={item?.preview?.url}
                                className="logo-image-about"
                              ></img>
                              <RxCrossCircled
                                className="delteiconabout"
                                onClick={() => {
                                  setState([]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button>Save </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for add product oil
export function AboutOilAddVerticallyCenteredModal(props) {
  const { brandAboutOil, setBrandAboutOil } = useContext(Auth);
  const [addAboutOil] = useCreateAboutOilMutation();
  const hindi = useGetAllAboutOilHindiQuery();
  const english = useGetAllAboutOilEnglishQuery();
  const [lang] = useState(
    brandAboutOil.lang == "" ? "hindi" : brandAboutOil?.lang
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: brandAboutOil.lang,
      heading: brandAboutOil.heading,
      imageurl: brandAboutOil.imageUrl,
      description: brandAboutOil.description,
    };
    addAboutOil(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        hindi.refetch();
        english.refetch();
        swal({
          title: "Blog Added Succesfully",
          icon: "success",
          dangerMode: true,
        });
        setBrandAboutOil({ ...brandAboutOil, imageUrl: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setBrandAboutOil({ ...brandAboutOil, lang: lang });
  }, [lang]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Enter your Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        lang: e.target.value,
                      });
                    }}
                    aria-label="Default select example"
                  >
                    <option value="hindi" selected>
                      Hindi
                    </option>
                    <option value="english">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        heading: e.target.value,
                      });
                    }}
                    className="w-100"
                    type="text"
                    placeholder="Enter your Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Description{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        description: e.target.value,
                      });
                    }}
                    className="w-100"
                    type="text"
                    placeholder="Enter Your Description"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your About Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={"addproduct"} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandAboutOil.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={brandAboutOil.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandAboutOil({
                                  ...brandAboutOil,
                                  imageUrl: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e) => handleSubmit(e)}>Save</Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
// for add hindi blog
export function BlogAddVerticallyCenteredModal(props) {
  const { blog, setBlog } = useContext(Auth);
  const getAllHindiBlogs = useGetAllBlogQuery({ language: "hindi" });
  const { editorState } = useContext(Auth);
  const [createblog] = useCreateBlogMutation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      title: blog.blogTitle,
      imageurl: blog.imageUrl,
      author: blog.author,
      specialtext: blog.specialText,
      content: editorState.editorHtml,
      tags: blog.tags,
      slug: getSlug(blog.slug),
    };
    createblog(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllHindiBlogs.refetch();
        swal({
          title: "Blog Added successfully",
          icon: "success",
          dangerMode: true,
        });
        setBlog({ ...blog, imageUrl: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Custom Slug
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={(e) => {
                      setBlog({ ...blog, blogTitle: e.target.value });
                    }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={(e) => {
                      setBlog({ ...blog, slug: e.target.value });
                    }}
                    placeholder="Custom slug"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Author Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Tags
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={(e) => {
                      setBlog({ ...blog, author: e.target.value });
                    }}
                    placeholder="Enter Your Author Title"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={(e) => {
                      setBlog({ ...blog, tags: e.target.value });
                    }}
                    placeholder="Enter Tags"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Special Text
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    // defaultValue={props?.previousData?.url}
                    onChange={(e) => {
                      setBlog({ ...blog, specialText: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Blog Inner Content"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Blog Inner Content
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Editor />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Upload Your Blog Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {blog.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={blog.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBlog({ ...blog, imageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function BlogAddEnglishVerticallyCenteredModal(props) {
  const { blog, setBlog } = useContext(Auth);
  const getAllEnglishBlogs = useGetAllBlogQuery({ language: "english" });
  const { editorState, setEditorState } = useContext(Auth);
  const [createblog] = useCreateBlogMutation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      title: blog.blogTitle,
      imageurl: blog.imageUrl,
      author: blog.author,
      specialtext: blog.specialText,
      content: editorState.editorHtml,
      tags: blog.tags,
      slug: getSlug(blog.slug),
    };
    createblog(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllEnglishBlogs.refetch();
        swal({
          title: "Blog Added successfully",
          icon: "success",
          dangerMode: true,
        });
        setBlog({ ...blog, imageUrl: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Custom Slug
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.title}
                    onChange={(e) => {
                      setBlog({ ...blog, blogTitle: e.target.value });
                    }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBlog({ ...blog, slug: e.target.value });
                    }}
                    placeholder="Enter Custom Slug"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Author Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Tags
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.title}
                    onChange={(e) => {
                      setBlog({ ...blog, author: e.target.value });
                    }}
                    placeholder="Enter Your Author Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBlog({ ...blog, tags: e.target.value });
                    }}
                    placeholder="Enter Tags"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Special Text
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    // defaultValue={props?.previousData?.url}
                    onChange={(e) => {
                      setBlog({ ...blog, specialText: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Blog Inner Content"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Blog Inner Content
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Editor />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Upload Your Blog Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {blog.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={blog.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBlog({ ...blog, imageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
// for add tvc


// for add tvc
export function BrandVerticallyCenteredModal(props) {
  const { handleError } = useContext(Auth);
  const [state, setState] = useState([]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100 "
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    placeholder="Enter Your  Slug Name "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Create Your New Product{" "}
                  </Card.Title>
                  <Editor />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Files
                        className="files-dropzone"
                        onChange={(files) => {
                          setState(files);
                        }}
                        onError={handleError}
                        accepts={["image/png", ".pdf", "audio/*"]}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <img
                          alt="draganddropimage"
                          src={drapanddrop}
                          className="draganddropimage"
                        ></img>
                      </Files>
                    </div>
                    <div className="col-md-2"></div>

                    <div className="col-md-5">
                      {state?.map((item) => {
                        return (
                          <div>
                            <div className="nav-link" href="#">
                              <img
                                alt="draganddropimage"
                                src={item?.preview?.url}
                                className="logo-image-about"
                              ></img>
                              <RxCrossCircled
                                className="delteiconabout"
                                onClick={() => {
                                  setState([]);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button>Save </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
// export function AddCampaignCenteredModal(props) {
//   const {
//     createCategoriesComic,
//     setCreateCategoriesComic,
//     inputFields,
//     editorState,
//     setEditorState,
//   } = useContext(Auth);
//   const [addComic] = useCreateComicMutation();
//   const getComic = useGetAllComicQuery();

//   const handleStory = (value) => {
//     if (value === "desEnglish") {
//       setCreateCategoriesComic({
//         ...createCategoriesComic,
//         storyDescription: {
//           ...createCategoriesComic.storyDescription,
//           forEnglish: editorState.editorHtml,
//         },
//       });
//       setEditorState({ ...editorState, editorHtml: "" });
//     } else if (value === "desHindi") {
//       setCreateCategoriesComic({
//         ...createCategoriesComic,
//         storyDescription: {
//           ...createCategoriesComic.storyDescription,
//           forHindi: editorState.editorHtml,
//         },
//       });
//       setEditorState({ ...editorState, editorHtml: "" });
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const raw = {
//       // language:createCategoriesComic.lang === ""  ? "hindi"  : createCategoriesComic.lang,
//       name: {
//         hindi: createCategoriesComic.comicName.forHindi,
//         english: createCategoriesComic.comicName.forEnglish,
//       },
//       slug: getSlug(createCategoriesComic.comicName.forEnglish),
//       mainimage: createCategoriesComic.mainImage,
//       backgroundimage: createCategoriesComic.backgroundImage,
//       sliderimage:
//         createCategoriesComic.siderImages !== ""
//           ? createCategoriesComic.siderImages
//           : [],
//       mobilesliderimage: createCategoriesComic.mobileSliderImage,
//       aboutpage: {
//         mainHeading: createCategoriesComic.aboutMainHeading.forHindi,
//         subHeading: createCategoriesComic.aboutsubHeading.forHindi,
//         description: createCategoriesComic.aboutDescription.forHindi,
//         rightImage: createCategoriesComic.rightImage,
//         leftSliderImage: createCategoriesComic.leftSliderImage,
//         buttonText: createCategoriesComic.buttonText.forHindi,
//         buttonLink: createCategoriesComic.buttonLink,
//       },
//       aboutpageenglish: {
//         mainHeading: createCategoriesComic.aboutMainHeading.forEnglish,
//         subHeading: createCategoriesComic.aboutsubHeading.forEnglish,
//         description: createCategoriesComic.aboutDescription.forEnglish,
//         buttonText: createCategoriesComic.buttonText.forEnglish,
//       },
//       storypage: {
//         mainPage: createCategoriesComic.storyMainHeading.forHindi,
//         subHeading: createCategoriesComic.storySubHeading.forHindi,
//         description: createCategoriesComic.storyDescription.forHindi,
//         rightImage: createCategoriesComic.storyRightImage,
//         leftImage: createCategoriesComic.storyLeftImage,
//         buttonText: createCategoriesComic.storyButtonText.forHindi,
//         buttonLink: createCategoriesComic.buttonStoryLink,
//       },
//       storypageenglish: {
//         mainPage: createCategoriesComic.storyMainHeading.forEnglish,
//         subHeading: createCategoriesComic.storySubHeading.forEnglish,
//         description: createCategoriesComic.storyDescription.forEnglish,
//         buttonText: createCategoriesComic.storyButtonText.forEnglish,
//       },
//       testimonials: {
//         mainHeading: createCategoriesComic.campaignHeading,
//         listing: inputFields,
//         subHeading: createCategoriesComic.campaignHeading,
//       },
//     };
//     addComic(raw)
//       .unwrap()
//       .then((data) => {
//         props.onHide();
//         setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: "" });
//         setCreateCategoriesComic({
//           ...createCategoriesComic,
//           siderImages: "",
//           rightImage: "",
//           leftSliderImage: "",
//           storyRightImage: "",
//           storyLeftImage: "",
//           mainImage: "",
//           backgroundImage: "",
//         });
//         getComic.refetch();
//         swal({
//           title: data.message,
//           icon: "success",
//           dangerMode: true,
//         });
//       })
//       .catch((error) => {
//         swal({
//           title: "Something Went Wrong Please Try Again",
//           icon: "error",
//           dangerMode: true,
//         });
//       });
//   };
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Body>
//         <div className="container">
//           <div className="row">
//             {/* comic name fields */}
//             <Accordion>
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Comic name field</Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For English">
//                       <div>
//                         {/* <Form.Label >Enter Your Email</Form.Label> */}
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter your comic name in english
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter slug of your comic
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         {/* <Form.Label >Enter Your Email</Form.Label> */}
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   comicName: {
//                                     ...createCategoriesComic.comicName,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Comic Name"
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               disabled
//                               defaultValue={"Slug Will Be Auto Generate"}
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter Slug"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter your comic name in hindi
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter slug of your comic
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   comicName: {
//                                     ...createCategoriesComic.comicName,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Comic Name"
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               disabled
//                               defaultValue={"Slug Will Be Auto Generate"}
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter Slug"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//             {/* accordation for main image and background image */}
//             <Accordion className="mt-2">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>
//                   Upload Main Image and Background Image
//                 </Accordion.Header>
//                 <Accordion.Body>
//                   <div className="d-flex row">
//                     <div className="col-md-6">
//                       {" "}
//                       <Form.Label className="font-weight-bold">
//                         {" "}
//                         Enter Your Main Image{" "}
//                       </Form.Label>{" "}
//                     </div>
//                     <div className="col-md-6">
//                       {" "}
//                       <Form.Label className="font-weight-bold">
//                         {" "}
//                         Enter Your Background Image{" "}
//                       </Form.Label>{" "}
//                     </div>
//                     <div className="col-md-6">
//                       <Media value={"campaignmainImage"} />
//                       <div className="mt-3">
//                         <ul>
//                           <li>
//                             <img
//                               alt="slider"
//                               src={
//                                 createCategoriesComic.mainImage.length !== 0
//                                   ? createCategoriesComic.mainImage
//                                   : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                               }
//                               className="slider-image mt-3"
//                             ></img>
//                             {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                             {createCategoriesComic.mainImage.length !== 0 ? (
//                               <RxCrossCircled
//                                 className="delteicon"
//                                 onClick={() => {
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     mainImage: "",
//                                   });
//                                 }}
//                               />
//                             ) : (
//                               ""
//                             )}
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                     <div className="col-md-6">
//                       <Media value={"campaignbackgroundimage"} />
//                       <div className="mt-3">
//                         <ul>
//                           <li>
//                             <img
//                               alt="slider"
//                               src={
//                                 createCategoriesComic.backgroundImage.length !==
//                                 0
//                                   ? createCategoriesComic.backgroundImage
//                                   : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                               }
//                               className="slider-image mt-3"
//                             ></img>
//                             {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                             {createCategoriesComic.backgroundImage.length !==
//                             0 ? (
//                               <RxCrossCircled
//                                 className="delteicon"
//                                 onClick={() => {
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     backgroundImage: "",
//                                   });
//                                 }}
//                               />
//                             ) : (
//                               ""
//                             )}
//                           </li>
//                         </ul>
//                       </div>
//                     </div>
//                   </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//             {/*accordation for Slider Menu */}
//             <Accordion className="mt-2">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Slider Menu field</Accordion.Header>
//                 <Accordion.Body>
//                   <Form.Label className="h5 font-weight-bold text-center mt-4">
//                     Slider Menu-:
//                   </Form.Label>
//                   <div>
//                     <div className="d-flex row">
//                       <div className="col-md-6">
//                         <Form.Label className="font-weight-bold">
//                           Upload Your Sliders Web Images &nbsp;
//                         </Form.Label>
//                         <Media value={"campaignsliderimage"} />
//                       </div>
//                       <div className="col-md-6">
//                         <Form.Label className="font-weight-bold">
//                           Upload Your Sliders Mobile Images
//                         </Form.Label>
//                         <Media value={"campaignslidermobileimage"} />
//                       </div>
//                     </div>
//                   </div>
//                   <div>
//                     {/* <Form.Label >Enter Your Email</Form.Label> */}
//                     <div className="d-flex row">
//                       <div className="col-md-6">
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               {createCategoriesComic.siderImages.length !==
//                               0 ? (
//                                 <>
//                                   {createCategoriesComic.siderImages.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           <RxCrossCircled
//                                             className="delteicon"
//                                             onClick={() => {
//                                               setCreateCategoriesComic({
//                                                 ...createCategoriesComic,
//                                                 siderImages:
//                                                   createCategoriesComic.siderImages.filter(
//                                                     function (val) {
//                                                       return val !== item;
//                                                     }
//                                                   ),
//                                               });
//                                             }}
//                                           />
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               ) : (
//                                 <img
//                                   alt="slider"
//                                   src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                   className="slider-image mt-3"
//                                 ></img>
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       {/* mobile image */}
//                       <div className="col-md-6">
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               {createCategoriesComic.mobileSliderImage
//                                 .length !== 0 ? (
//                                 <>
//                                   {createCategoriesComic.mobileSliderImage.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           <RxCrossCircled
//                                             className="delteicon"
//                                             onClick={() => {
//                                               setCreateCategoriesComic({
//                                                 ...createCategoriesComic,
//                                                 mobileSliderImage:
//                                                   createCategoriesComic.mobileSliderImage.filter(
//                                                     function (val) {
//                                                       return val !== item;
//                                                     }
//                                                   ),
//                                               });
//                                             }}
//                                           />
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               ) : (
//                                 <img
//                                   alt="slider"
//                                   src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                   className="slider-image mt-3"
//                                 ></img>
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//             {/*accordation for About Page */}
//             <Accordion className="mt-2">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>About Page</Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For english">
//                       <Form.Label className="h5 font-weight-bold text-center mt-4">
//                         {" "}
//                         About Menu-:{" "}
//                       </Form.Label>
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Main Heading
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Sub Heading
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutMainHeading: {
//                                       ...createCategoriesComic.aboutMainHeading,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Main Heading "
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutsubHeading: {
//                                       ...createCategoriesComic.aboutsubHeading,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder="Sub Heading"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* description row */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Commoneditor value="englishabout" />
//                               {/* <Button
//                                 onClick={() => {
//                                   handleAdd("desEnglish");
//                                 }}
//                                 className="mt-2"
//                               >
//                                 {" "}
//                                 Save editor content english{" "}
//                               </Button> */}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* right and left slider */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Left Slider Images
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           <div className="d-flex row">
//                             {/* <div className="col-md-6">
//                     <Media value={"campaignaboutrightimage"} />
//                     <div className="mt-3">
//                       <ul>
//                         <li>
//                           <img
//                             alt="slider"
//                             src={
//                               createCategoriesComic.rightImage.length !== 0
//                                 ? createCategoriesComic.rightImage
//                                 : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                             }
//                             className="slider-image mt-3"
//                           ></img>
//                           {createCategoriesComic.rightImage.length !== 0 ? (
//                             <RxCrossCircled
//                               className="delteicon"
//                               onClick={() => {
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   rightImage: "",
//                                 });
//                               }}
//                             />
//                           ) : (
//                             ""
//                           )}
//                         </li>
//                       </ul>
//                     </div>
//                   </div> */}

//                             <div className="col-md-6">
//                               <Media value={"campaignaboutLeftimage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     {createCategoriesComic.leftSliderImage
//                                       .length !== 0 ? (
//                                       <>
//                                         {createCategoriesComic.leftSliderImage.map(
//                                           (item) => {
//                                             return (
//                                               <>
//                                                 <img
//                                                   alt="slider"
//                                                   src={item}
//                                                   className="slider-image mt-3"
//                                                 ></img>
//                                                 <RxCrossCircled
//                                                   className="delteicon"
//                                                   onClick={() => {
//                                                     setCreateCategoriesComic({
//                                                       ...createCategoriesComic,
//                                                       leftSliderImage:
//                                                         createCategoriesComic.leftSliderImage.filter(
//                                                           function (val) {
//                                                             return val !== item;
//                                                           }
//                                                         ),
//                                                     });
//                                                   }}
//                                                 />
//                                               </>
//                                             );
//                                           }
//                                         )}
//                                       </>
//                                     ) : (
//                                       <img
//                                         alt="slider"
//                                         src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                         className="slider-image mt-3"
//                                       ></img>
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       {/* button text and button link */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Link
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonText: {
//                                       ...createCategoriesComic.buttonText,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonLink: e.target.value,
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder=" Button Url Where You want to Navigate"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       <Form.Label className="h5 font-weight-bold text-center mt-4">
//                         {" "}
//                         About Menu-:{" "}
//                       </Form.Label>
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Main Heading
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Sub Heading
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutMainHeading: {
//                                       ...createCategoriesComic.aboutMainHeading,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Main Heading "
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutsubHeading: {
//                                       ...createCategoriesComic.aboutsubHeading,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder="Sub Heading"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* description row */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Commoneditor value="hindiabout" />
//                               {/* <Button
//                                 onClick={() => {
//                                   handleAdd("desHindi");
//                                 }}
//                                 className="mt-2"
//                               >
//                                 {" "}
//                                 Save editor content{" "}
//                               </Button> */}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* right and left slider */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             {/* <div className="col-md-6">
//                     <Form.Label className="font-weight-bold">
//                       Enter Your Right Image
//                     </Form.Label>
//                   </div> */}
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Left Slider Images
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             {/* <div className="col-md-6">
//                     <Media value={"campaignaboutrightimage"} />
//                     <div className="mt-3">
//                       <ul>
//                         <li>
//                           <img
//                             alt="slider"
//                             src={
//                               createCategoriesComic.rightImage.length !== 0
//                                 ? createCategoriesComic.rightImage
//                                 : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                             }
//                             className="slider-image mt-3"
//                           ></img>
//                           {createCategoriesComic.rightImage.length !== 0 ? (
//                             <RxCrossCircled
//                               className="delteicon"
//                               onClick={() => {
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   rightImage: "",
//                                 });
//                               }}
//                             />
//                           ) : (
//                             ""
//                           )}
//                         </li>
//                       </ul>
//                     </div>
//                   </div> */}

//                             <div className="col-md-6">
//                               <Media value={"campaignaboutLeftimage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     {createCategoriesComic.leftSliderImage
//                                       .length !== 0 ? (
//                                       <>
//                                         {createCategoriesComic.leftSliderImage.map(
//                                           (item) => {
//                                             return (
//                                               <>
//                                                 <img
//                                                   alt="slider"
//                                                   src={item}
//                                                   className="slider-image mt-3"
//                                                 ></img>
//                                                 <RxCrossCircled
//                                                   className="delteicon"
//                                                   onClick={() => {
//                                                     setCreateCategoriesComic({
//                                                       ...createCategoriesComic,
//                                                       leftSliderImage:
//                                                         createCategoriesComic.leftSliderImage.filter(
//                                                           function (val) {
//                                                             return val !== item;
//                                                           }
//                                                         ),
//                                                     });
//                                                   }}
//                                                 />
//                                               </>
//                                             );
//                                           }
//                                         )}
//                                       </>
//                                     ) : (
//                                       <img
//                                         alt="slider"
//                                         src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                         className="slider-image mt-3"
//                                       ></img>
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       {/* button text and button link */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Link
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonText: {
//                                       ...createCategoriesComic.buttonText,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonLink: e.target.value,
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder=" Button Url Where You want to Navigate"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//             {/*accordation for Story Page */}
//             <Accordion className="mt-2">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Story Field </Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For english">
//                       <Form.Label className="h5 font-weight-bold text-center mt-4">
//                         {" "}
//                         Story Page-:{" "}
//                       </Form.Label>
//                       {/* 1st row of story  */}
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Your Main Heading{" "}
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Sub Heading{" "}
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyMainHeading: {
//                                     ...createCategoriesComic.storyMainHeading,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Enter your main heading "
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storySubHeading: {
//                                     ...createCategoriesComic.storySubHeading,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter your sub heading "
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       {/* story description */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Story Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Commoneditor value="englishstory" />
//                               {/* <Button
//                                 onClick={() => {
//                                   handleStory("desEnglish");
//                                 }}
//                                 className="mt-2"
//                               >
//                                 {" "}
//                                 Save editor content{" "}
//                               </Button> */}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* story right and left images */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Right Image
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Left Slider Images
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Media value={"storyRightImage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     <img
//                                       alt="slider"
//                                       src={
//                                         createCategoriesComic.storyRightImage
//                                           .length !== 0
//                                           ? createCategoriesComic.storyRightImage
//                                           : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                       }
//                                       className="slider-image mt-3"
//                                     ></img>
//                                     {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                                     {createCategoriesComic.storyRightImage
//                                       .length !== 0 ? (
//                                       <RxCrossCircled
//                                         className="delteicon"
//                                         onClick={() => {
//                                           setCreateCategoriesComic({
//                                             ...createCategoriesComic,
//                                             storyRightImage: "",
//                                           });
//                                         }}
//                                       />
//                                     ) : (
//                                       ""
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <Media value={"storyLeftImage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     <img
//                                       alt="slider"
//                                       src={
//                                         createCategoriesComic.storyLeftImage
//                                           .length !== 0
//                                           ? createCategoriesComic.storyLeftImage
//                                           : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                       }
//                                       className="slider-image mt-3"
//                                     ></img>
//                                     {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                                     {createCategoriesComic.storyLeftImage
//                                       .length !== 0 ? (
//                                       <RxCrossCircled
//                                         className="delteicon"
//                                         onClick={() => {
//                                           setCreateCategoriesComic({
//                                             ...createCategoriesComic,
//                                             storyLeftImage: "",
//                                           });
//                                         }}
//                                       />
//                                     ) : (
//                                       ""
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* story button text and button link  */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Link
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     storyButtonText: {
//                                       ...createCategoriesComic.storyButtonText,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonStoryLink: e.target.value,
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder=" Button Url Where You want to Navigate"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       <Form.Label className="h5 font-weight-bold text-center mt-4">
//                         {" "}
//                         Story Page-:{" "}
//                       </Form.Label>
//                       {/* 1st row of story  */}
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Your Main Heading{" "}
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Sub Heading{" "}
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyMainHeading: {
//                                     ...createCategoriesComic.storyMainHeading,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Enter your main  haeding "
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storySubHeading: {
//                                     ...createCategoriesComic.storySubHeading,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter your sub heading "
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       {/* story description */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Story Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Commoneditor value="hindistory" />
//                               {/* <Button
//                                 onClick={() => {
//                                   handleStory("desHindi");
//                                 }}
//                                 className="mt-2"
//                               >
//                                 {" "}
//                                 Save editor content{" "}
//                               </Button> */}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* story right and left images */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Right Image
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Left Slider Images
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Media value={"storyRightImage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     <img
//                                       alt="slider"
//                                       src={
//                                         createCategoriesComic.storyRightImage
//                                           .length !== 0
//                                           ? createCategoriesComic.storyRightImage
//                                           : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                       }
//                                       className="slider-image mt-3"
//                                     ></img>
//                                     {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                                     {createCategoriesComic.storyRightImage
//                                       .length !== 0 ? (
//                                       <RxCrossCircled
//                                         className="delteicon"
//                                         onClick={() => {
//                                           setCreateCategoriesComic({
//                                             ...createCategoriesComic,
//                                             storyRightImage: "",
//                                           });
//                                         }}
//                                       />
//                                     ) : (
//                                       ""
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>

//                             <div className="col-md-6">
//                               <Media value={"storyLeftImage"} />
//                               <div className="mt-3">
//                                 <ul>
//                                   <li>
//                                     <img
//                                       alt="slider"
//                                       src={
//                                         createCategoriesComic.storyLeftImage
//                                           .length !== 0
//                                           ? createCategoriesComic.storyLeftImage
//                                           : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
//                                       }
//                                       className="slider-image mt-3"
//                                     ></img>
//                                     {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                                     {createCategoriesComic.storyLeftImage
//                                       .length !== 0 ? (
//                                       <RxCrossCircled
//                                         className="delteicon"
//                                         onClick={() => {
//                                           setCreateCategoriesComic({
//                                             ...createCategoriesComic,
//                                             storyLeftImage: "",
//                                           });
//                                         }}
//                                       />
//                                     ) : (
//                                       ""
//                                     )}
//                                   </li>
//                                 </ul>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* story button text and button link  */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Link
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     storyButtonText: {
//                                       ...createCategoriesComic.storyButtonText,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonStoryLink: e.target.value,
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder=" Button Url Where You want to Navigate"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//             {/*accordation for Testimonial Page */}
//             <Accordion className="mt-2">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Testimonials field</Accordion.Header>
//                 <Accordion.Body>
//                   <Form.Label className="h5 font-weight-bold text-center mt-4">
//                     Testimonials-:
//                   </Form.Label>
//                   <Multipleinput />
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button
//           onClick={(e) => {
//             handleSubmit(e);
//           }}
//         >
//           Create Comic
//         </Button>
//         <Button>Reset All </Button>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
export function ChooseMediaVerticallyCenteredModal(props) {
  const {
    createCategoriesComic,
    setCreateCategoriesComic,
    tvc,
    setTvc,
    blog,
    setBlog,
    brandMedia,
    setBrandMedia,
    brandStory,
    setBrandStory,
    brandAboutOil,
    setBrandAboutOil,
    brandPhilosophy,
    setBrandPhilosphy,
    brandProduct,
    setBrandProduct,
    brandAbout,
    setBrandAbout,
    header,
    setHeader,
    images,
    setImages,
    mediaImage,
    setMediaImage,
    brand,
    setBrand,
    updateBrand,
    setUpdateBrand,
  } = useContext(Auth);
  const [createImages] = useImportImageMutation();
  const { data, isLoading, isFetching } = useGetAllImagesQuery();

  const getAllMediaImages = useGetAllImagesQuery();
  const onhandlFinish = (e) => {
    props.onHide();
    e.preventDefault();
    if (props.value === "sliderweb") {
      setMediaImage({ ...mediaImage, sliderWebImage: images.mediaImages });
      setBrand({ ...brand, imageWeb: images.mediaImages });
      //global media
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "slidermobile") {
      //for showing
      setMediaImage({ ...mediaImage, sliderMobileImage: images.mediaImages });
      //for api integrate
      setBrand({ ...brand, imageMobile: images.mediaImages });
      //global media
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "updatesliderweb") {
      setUpdateBrand({ ...updateBrand, newimageweb: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "updateslidermobile") {
      setUpdateBrand({ ...updateBrand, newimagemobile: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (
      props.value === "headerenglish" ||
      props.value === "headerhindi"
    ) {
      setHeader({ ...header, iconUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (
      props.value === "brandsliderenglish" ||
      props.value === "brandsliderhindi"
    ) {
      setBrandAbout({ ...brandAbout, imageurl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (
      props.value === "brandproducthindi" ||
      props.value === "brandproductenglish"
    ) {
      setBrandProduct({ ...brandProduct, productImage: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "webphilosphy") {
      setBrandPhilosphy({
        ...brandPhilosophy,
        brandPhilosophyImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "mobophilosphy") {
      setBrandPhilosphy({
        ...brandPhilosophy,
        brandPhilosophyMobileImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (
      props.value === "productoilhindi" ||
      props.value === "productoilenglish" ||
      props.value === "addproduct"
    ) {
      setBrandAboutOil({ ...brandAboutOil, imageUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "brandstoryimageurl") {
      setBrandStory({ ...brandStory, imageUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "brandstorybackgroundimage") {
      setBrandStory({ ...brandStory, backgroundImageUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "brandmedia" || props.value === "addmedia") {
      setBrandMedia({ ...brandMedia, image: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (
      props.value === "blogupdatehindi" ||
      props.value === "blogupdateenglish" ||
      props.value === "addhindiblog" ||
      props.value === "addenglishblog"
    ) {
      setBlog({ ...blog, imageUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value === "addtvc") {
      setTvc({ ...tvc, backgroundImageUrl: images.mediaImages });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignsliderimage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        siderImages: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignslidermobileimage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        mobileSliderImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignaboutrightimage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        rightImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignmainImage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        mainImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignbackgroundimage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        backgroundImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "campaignaboutLeftimage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        leftSliderImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "storyRightImage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        storyRightImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else if (props.value == "storyLeftImage") {
      setCreateCategoriesComic({
        ...createCategoriesComic,
        storyLeftImage: images.mediaImages,
      });
      setImages({ ...images, mediaImages: [] });
    } else {
      alert("nothing is found");
    }
  };
  const handleChange = (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("image", files[i]);
    }
    createImages(formData)
      .unwrap()
      .then((data) => {
        getAllMediaImages.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="MediaSection">
          <div className="container ">
            <div className="row">
              <div className="col-md-12">
                <Card>
                  <CardHeader>
                    <div className="d-flex justify-content-between">
                      <Card.Title>Choose Images</Card.Title>
                      <Files
                        className="files-dropzone"
                        onChange={(files) => handleChange(files)}
                        // accepts={['image/png/g', '.pdf', 'audio/*']}
                        maxFileSize={10000000}
                        minFileSize={0}
                        clickable
                      >
                        <Button className="">Add Images</Button>
                      </Files>
                    </div>
                  </CardHeader>
                  <Card.Body md="3" className="w-100">
                    {isFetching && isLoading ? (
                      <Loader />
                    ) : (
                      <Imageshowing data={data} value={props.value} />
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            onhandlFinish(e);
          }}
          className="bg-success"
        >
          Selected
        </Button>
        <Button className="bg-danger" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export function ChooseEditorVerticallyCenteredModal(props) {

  const { globalEditor, setGlobalEditor, Swal, setCreateCategoriesComic, createCategoriesComic } = useContext(Auth);

  const onhandlFinish = () => {
    if (props.value === "englishabout") {
      setCreateCategoriesComic({ ...createCategoriesComic, aboutDescription: { ...createCategoriesComic.aboutDescription, forEnglish: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.value === "hindiabout") {
      setCreateCategoriesComic({ ...createCategoriesComic, aboutDescription: { ...createCategoriesComic.aboutDescription, forHindi: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.value === "englishstory") {
      setCreateCategoriesComic({ ...createCategoriesComic, storyDescription: { ...createCategoriesComic.storyDescription, forEnglish: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.value === "hindistory") {
      setCreateCategoriesComic({ ...createCategoriesComic, storyDescription: { ...createCategoriesComic.storyDescription, forHindi: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });


    }


  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Editortinyme value={props.value} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              onhandlFinish(e);
            }}
            className="bg-success"
          >
            Done
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export function ChooseUpdateEditorVerticallyCenteredModal(props) {
  const { globalEditor, setGlobalEditor, Swal, setCreateCategoriesComic, createCategoriesComic } = useContext(Auth);

  const onhandlFinish = () => {

    if (props.from === "aboutenglish") {

      setCreateCategoriesComic({ ...createCategoriesComic, aboutDescription: { ...createCategoriesComic.aboutDescription, forEnglish: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.from === "abouthindi") {
      setCreateCategoriesComic({ ...createCategoriesComic, aboutDescription: { ...createCategoriesComic.aboutDescription, forHindi: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.from === "storyenglish") {
      setCreateCategoriesComic({ ...createCategoriesComic, storyDescription: { ...createCategoriesComic.storyDescription, forEnglish: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    } else if (props.from === "storyhindi") {
      setCreateCategoriesComic({ ...createCategoriesComic, storyDescription: { ...createCategoriesComic.storyDescription, forHindi: globalEditor.editorHtml } });
      setGlobalEditor({ ...globalEditor, editorHtml: "" });
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <EditorForUpdate value={props.value} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              onhandlFinish(e);
            }}
            className="bg-success"
          >
            Done
          </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

//campaign modeule
