import React from 'react';
import Brandcontactmodel from '../../GlobalComponent/Model/Brandcontactmodelhindi';
import { Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useGetContactQuery } from './contactApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandcontactmodelhindi from '../../GlobalComponent/Model/Brandcontactmodelhindi';
import Brandcontactmodelenglish from '../../GlobalComponent/Model/Brandcontactmodelenglish';
function Brandcontact() {
  const getHindiContact = useGetContactQuery("hindi")
  const getEnglishContact = useGetContactQuery("english")
 

  return (
    <>
      <div className="container-fluid">
        <div>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Brand Contact Section For Hindi">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Contact Section For Hindi</h1>
                <Brandcontactmodel />
              </div> */}
              <div className='row '>
                <div className=' col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Main Heading</th>
                        <th>CIN Number</th>
                        <th>Address</th>
                        <th> Mobile Number</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getHindiContact?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{`${getHindiContact?.data?.contact?.mainHeading.slice(0, 10)}....`}</td>
                            <td>{`${getHindiContact?.data?.contact?.cinNumber.slice(0, 10)}....`}</td>
                            <td>{`${getHindiContact?.data?.contact?.address.slice(0,20)}....`}</td>
                            <td>{getHindiContact?.data?.contact?.contact}</td>
                            <td><Brandcontactmodelhindi previousData={getHindiContact?.data?.contact} value={"brandsliderhindi"} /></td>
                          </tr>
                      }
                      {/* <td><Brandmediahindimodel /></td> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Brand Contact Section For English">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Contact Section For English</h1>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Main Heading</th>
                        <th>CIN Number</th>
                        <th>Address</th>
                        <th> Mobile Number</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getEnglishContact?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{`${getEnglishContact?.data?.contact?.mainHeading.slice(0, 10)}....`}</td>
                            <td>{`${getEnglishContact?.data?.contact?.cinNumber.slice(0, 10)}....`}</td>
                            <td>{`${getEnglishContact?.data?.contact?.address.slice(0,20)}....`}</td>
                            <td>{getEnglishContact?.data?.contact?.contact}</td>
                            <td><Brandcontactmodelenglish previousData={getEnglishContact?.data?.contact} value={"brandsliderhindi"} /></td>
                          </tr>
                      }
                      {/* <td><Brandmediahindimodel /></td> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandcontact;