import React, { useState } from 'react';
import Authcontext, { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AboutoilhindiVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { BiEditAlt } from 'react-icons/bi';
function Brandaboutoilmodelhindi({ previousData, value }) {
    // const {updateHindiModelShow,setUpdateHindiModelShow} =useContext(Auth)
    const [updateHindiModelShow,setUpdateHindiModelShow] =useState(false)
    // const [addModelShow,setAddModelShow]=useState(false)
    return (
        <>
            <BiEditAlt className='h3' onClick={() =>setUpdateHindiModelShow(true)} />
            <AboutoilhindiVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={updateHindiModelShow}
                onHide={() => setUpdateHindiModelShow(false)}
            />
        </>
    );
}

export default Brandaboutoilmodelhindi;