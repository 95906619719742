import React, { useContext } from 'react';
import Slider from '../AdminSlider/Slider';
import Topbar from '../TopBar/topBar';
import Adminfooter from '../AdminFooter/AdminFooter';
import { Outlet } from 'react-router-dom';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import Loader from '../Loader/Loader';
function Layout() {
  const { loader } = useContext(Auth)

  return (
    <>
      {
        loader ? <Loader /> : <body id="page-top">
          <div id="wrapper">
            <Slider />
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Topbar />
                <Outlet />
              </div>
              <Adminfooter />
            </div>
          </div>
        </body>
      }


    </>
  );
}

export default Layout;