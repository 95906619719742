import React from "react";
import { Table } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { useDeleteTvcMutation, useGetTvcQuery } from "./tvcApiSlice";
import Loader from "../../GlobalComponent/Loader/Loader";
import Brandaddtvcmodel from "../../GlobalComponent/Model/Brandaddtvcmodel";
import { useContext } from "react";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";

function Brandtvc() {
  const { Swal } = useContext(Auth);
  const getTvc = useGetTvcQuery();
  const [delteTvc] = useDeleteTvcMutation();
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        delteTvc(id)
          .unwrap()
          .then((data) => {
            getTvc.refetch();
          })
          .catch((error) => {});
      }
    });
  };
  return (
    <>
      <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Brand TVC Section </h1>
        <Brandaddtvcmodel />
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Table responsive="sm" hover className="w-100 table">
            <thead className="bg-black text-white">
              <tr>
                <th>S.NO</th>
                <th>Video Url</th>
                <th>Background Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getTvc?.isLoading ? (
                <Loader />
              ) : (
                <>
                  {getTvc?.data?.videos?.map((item, i) => {
                    return (
                      <>
                        <tr>
                          <td>{i + 1}</td>
                          <td>{`${item.videoUrl.slice(0, 100)}....`}</td>
                          <td>
                            <img
                              style={{ width: "auto", height: "100px" }}
                              src={item.backgroudImage}
                            ></img>
                          </td>
                          <td>
                            <MdDeleteOutline
                              onClick={() => {
                                handleDelete(item._id);
                              }}
                              className="h3 ml-2 text-danger"
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      </div>
    </>
  );
}

export default Brandtvc;
