import { ComicRegistrationEndPoint } from "../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../app/api/apiSlice";

export const AgentApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        // getTvc: builder.query({
        //     query: () => ({
        //         url: tvcEndPoints,
        //         method: 'GET'
        //     })
        // }),
        // deleteTvc: builder.mutation({
        //     query: (id) => {
        //         return {
        //             url: `${deleteTvcEndPoints}/${id}`,
        //             method: 'DELETE', 
        //         }
        //     }
        // }),
        // updateTvc: builder.mutation({
        //     query: (raw) => {
        //         return {
        //             url: tvcEndPoints,
        //             method: 'PATCH',
        //             body: raw,
        //         }
        //     }
        // }),
        addComicRegs: builder.mutation({
            query: (raw) => {
                return {
                    url: ComicRegistrationEndPoint,
                    method: 'POST',
                    body: raw,
                }
            }
        })
    })
})
export const {useAddComicRegsMutation} =AgentApiSlice