import React, { useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { AboutOilAddVerticallyCenteredModal } from '../../Modelhelper/Modelhelper';
import { Button } from 'react-bootstrap';


function Brandaboutoiladdmodel({ previousData, value }) {
    const [addModelShow,setAddModelShow]=useState(false)
    return (
        <>
            {/* <BiEditAlt className='h3' onClick={() => {setAddModelShow(true)}} /> */}
            <Button  onClick={() => {setAddModelShow(true)}}>Add Product Oil</Button>
            <AboutOilAddVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={addModelShow}
                onHide={() => setAddModelShow(false)}
            />
        </>
    );
}

export default Brandaboutoiladdmodel;