import {  updateAboutBrandEndPoints ,aboutBrandEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const brandAboutApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrandAboutHindi: builder.query({
            query: () => ({
                url: `${aboutBrandEndPoints}/hindi`,
                method: 'GET'
            })
        }),
        getBrandAboutEnglish: builder.query({
            query: () => ({
                url: `${aboutBrandEndPoints}/english`,
                method: 'GET'
            })
        }),
        updateAbout: builder.mutation({
            query: (newHeader) => {
                return {
                    url: updateAboutBrandEndPoints,
                    method: 'PATCH',
                    body: newHeader,
                }
            }
        })
    })
})
export const {useGetBrandAboutEnglishQuery,useUpdateAboutMutation,useGetBrandAboutHindiQuery} =brandAboutApiSlice