import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { MdDeleteOutline } from 'react-icons/md';
import Brandblogmodel from '../../GlobalComponent/Model/Brandblogmodel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDeleteBlogMutation, useGetAllBlogQuery } from './blogApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandblogmodelenglish from '../../GlobalComponent/Model/Brandblogmodelenglish';
import Brandblogmodelhindi from '../../GlobalComponent/Model/Brandblogmodelhindi';
import { TbEdit } from 'react-icons/tb';
import Brandblogmodeladdenglish from '../../GlobalComponent/Model/Brandblogmodeladdenglish';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
function Brandblogs() {
  const getAllHindiBlogs = useGetAllBlogQuery({ language: 'hindi' })
  const getAllEnglishBlogs = useGetAllBlogQuery({ language: 'english' })
  const [deleteBlog] = useDeleteBlogMutation()
  const { Swal } = useContext(Auth)
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        deleteBlog(id).unwrap().then((data) => {
          getAllHindiBlogs.refetch()
          getAllEnglishBlogs.refetch()
        }).catch((error) => {
           Swal.fire({
             text: "Something went wrong |",
             icon: "error",
           });
        })
      }
    })
  }
  return (
    <>
      <div className="container-fluid">
        <div>

          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >

            <Tab eventKey="home" title="Home Blog Hindi Content">
            <div className="w-100 text-right mb-4">
                {/* <h1 className="h3 mb-0 text-gray-800">Brand Blog Section For Hindi</h1> */}
                <Brandblogmodel value={"addhindiblog"} />
              </div>
              <div className='row '>
                <div className=' col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.No</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Special Text</th>
                        <th>Blog Inner Content</th>
                        <th>Tags</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getAllHindiBlogs?.isLoading ? <Loader /> :
                          <>
                            {
                              getAllHindiBlogs?.data?.blog?.map((item, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{`${item?.title?.slice(0,50)}`}</td>
                                      <td>{item?.author}</td>
                                      <td>{`${item?.specialText?.slice(0,20)}...`}</td>
                                      <td>{`${item?.content?.slice(0,50)}...`}</td>
                                      <td>{`${item?.tags?.slice(0,10)}...`}</td>
                                      <td className='d-flex'>
                                        <Brandblogmodelhindi previousData={item} value={"blogupdatehindi"} />
                                        <MdDeleteOutline onClick={()=>{handleDelete(item._id)}}  className='h3 ml-2 text-danger' />
                                      </td>
                                    </tr>

                                  </>
                                )
                              })
                            }
                          </>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Home Blog English Content">
              <div className="w-100 text-right mb-4">
                {/* <h1 className="h3 mb-0 text-gray-800">Brand Blog Section For English</h1> */}
                <Brandblogmodeladdenglish value={"addenglishblog"} />
              </div>
              <div className='row'>
                <div className='col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                      <th>S.No</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Special Text</th>
                        <th>Blog Inner Content</th>
                        <th>Tags</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        getAllEnglishBlogs?.isLoading ? <Loader /> :
                          <>
                            {
                              getAllEnglishBlogs?.data?.blog?.map((item, i) => {

                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{`${item?.title?.slice(0,50)}...`}</td>
                                      <td>{item?.author}</td>
                                      <td>{`${item?.specialText?.slice(0,20)}...`}</td>
                                      <td>{`${item.content?.slice(0,50)}...`}</td>
                                      <td>{`${item?.tags?.slice(0,10)}...`}</td>

                                      <td className='d-flex'>
                                        <Brandblogmodelenglish previousData={item} value={"blogupdateenglish"} className='text-success'/>
                                        <MdDeleteOutline  onClick={()=>{handleDelete(item._id)}} className='h3 ml-2 text-danger' />
                                      </td>

                                    </tr>
                                  </>
                                )
                              })
                            }
                          </>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandblogs;