
import { comicEndPoints } from "../../contextapi/Endpoints/Endpoints"
import { apiSlice } from "../app/api/apiSlice"
export const campaignApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllComic: builder.query({
            query: () => ({
                url: comicEndPoints,
                method: 'GET'
            })
        }),
        createComic: builder.mutation({
            query: (raw) => {
                return {
                    url: `${comicEndPoints}`,
                    method: 'POST',
                    body: raw,
                }
            }
        }),
        deleteComic: builder.mutation({
            query: (id) => {
                return {
                    url: `${comicEndPoints}/${id}`,
                    method: 'DELETE',
                    // body:raw
                }
            }
        }),
        updateComic: builder.mutation({
            query: ({ raw, id }) => {
                return {
                    url: `${comicEndPoints}/${id}`,
                    method: 'PATCH',
                    body: raw,

                }
            }
        })
    })
})
export const { useGetAllComicQuery,useCreateComicMutation,useUpdateComicMutation,useDeleteComicMutation } = campaignApiSlice