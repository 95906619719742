import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { PhilosophyenglishVerticallyCenteredModal } from '../../Home/Brandphilosophy/Updatephilosophy';


function Brandphilosophyenglishmodel({ previousData, value }) {
    const { englishModelShow, setEnglishModelShow } = useContext(Auth)
    return (
        <>
             <BiEditAlt className='h3' onClick={() => setEnglishModelShow(true)} />
            <PhilosophyenglishVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={englishModelShow}
                onHide={() => setEnglishModelShow(false)}
            />
        </>
    );
}

export default Brandphilosophyenglishmodel;