import React from 'react';
import Brandproductmodel from '../../GlobalComponent/Model/Brandproductmodelhindi';
import { Table } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useGetBrandProductEnglishQuery, useGetBrandProductHindiQuery } from './brandProductApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandproductmodelhindi from '../../GlobalComponent/Model/Brandproductmodelhindi';
import Brandproductmodelenglish from '../../GlobalComponent/Model/Brandproductmodelenglish';
function Brandproduct() {
  const brandProductHindi = useGetBrandProductHindiQuery()
  const brandProductEnglish = useGetBrandProductEnglishQuery()
  return (
    <>
      <div className="container-fluid">

        <div>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Product Hindi Content">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Product Section For Hindi</h1>
              </div> */}
              <div className='row '>
                <div className=' col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>Description</th>
                        <th>Product Image</th>
                        <th>Buy from Norish Link</th>
                        <th>Buy from Amazon  Link</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        brandProductHindi?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{brandProductHindi?.data?.productPage?.heading}</td>
                            <td>{`${brandProductHindi?.data?.productPage?.description.slice(0,80)}.....`}</td>
                            <td><img style={{ width: "80px", height: "auto",color:'white' }} src={brandProductHindi?.data?.productPage?.imageUrl}></img></td>
                            <td>{`${brandProductHindi?.data?.productPage?.button[0]?.nourishlink.slice(0,20)}.....`}</td>
                            <td>{`${brandProductHindi?.data?.productPage?.button[1]?.amazonlink.slice(0,20)}.....`}</td>
                            <td><Brandproductmodelhindi previousData={brandProductHindi?.data?.productPage} value={"brandproducthindi"} /></td>
                          </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Product English Content ">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Product Section For English</h1>
              </div> */}
              <div className='row'>
                <div className='col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>Description</th>
                        <th>Product Image</th>
                        <th>Buy from Norish Link</th>
                        <th>Buy from Amazon  Link</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        brandProductHindi?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{brandProductEnglish?.data?.productPage?.heading.slice(0,30)}</td>
                            <td>{`${brandProductEnglish?.data?.productPage?.description.slice(0,80)}...`}</td>
                            <td><img style={{ width: "100px", height: "auto" }} src={brandProductEnglish?.data?.productPage?.imageUrl}></img></td>
                            <td>{brandProductEnglish?.data?.productPage?.button[0]?.nourishlink}</td>
                            <td>{brandProductEnglish?.data?.productPage?.button[1]?.amazonlink}</td>
                            <td><Brandproductmodelenglish previousData={brandProductEnglish?.data?.productPage} value={"brandproductenglish"} /></td>
                          </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandproduct;