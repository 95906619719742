import { mediaEndPoints } from "../../../contextapi/Endpoints/Endpoints"
import { apiSlice } from "../../app/api/apiSlice"
export const brandAboutOilSplice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllMedia: builder.query({
            query: () => ({
                url: mediaEndPoints,
                method: 'GET'
            })
        }),
        createMedia: builder.mutation({
            query: (raw) => {
                return {
                    url: `${mediaEndPoints}`,
                    method: 'POST',
                    body: raw,
                }
            }
        }),
        deleteMedia: builder.mutation({
            query: (id) => {
                return {
                    url: `${mediaEndPoints}/${id}`,
                    method: 'DELETE',
                    // body:raw
                }
            }
        }),
        updateMedia: builder.mutation({
            query: ({ raw, id }) => {
                return {
                    url: `${mediaEndPoints}/${id}`,
                    method: 'PATCH',
                    body: raw,

                }
            }
        })
    })
})
export const { useGetAllMediaQuery, useCreateMediaMutation, useDeleteMediaMutation, useUpdateMediaMutation } = brandAboutOilSplice