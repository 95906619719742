import React from 'react';
import { Table } from 'react-bootstrap';
import { MdFileDownloadDone, MdDeleteOutline } from 'react-icons/md';
import { useGetBrandAboutEnglishQuery, useGetBrandAboutHindiQuery } from './brandAboutApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Brandaboutmodelhindi from '../../GlobalComponent/Model/Brandaboutmodelhindi';
import Brandaboutmodelenglish from '../../GlobalComponent/Model/Brandaboutmodelenglish';
function Brandabout() {
  const brandAboutHindi = useGetBrandAboutHindiQuery()
  const brandAboutEnglish = useGetBrandAboutEnglishQuery()

  return (
    <>
       <div className="container-fluid">
       
       <div>
         <Tabs
           defaultActiveKey="home"
           id="uncontrolled-tab-example"
           className="mb-3"
         >
           <Tab eventKey="home" title="Home About Section Hindi Content">
             {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
               <h3 className="h3 mb-0 text-gray-800">Home About Section For Hindi</h3>
             </div> */}
             <div className='row '>
               <div className=' col-md-12'>
                 <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                     <tr>
                       <th>S.NO</th>
                       <th>Heading</th>
                       <th>Sub Heading</th>
                       <th>Description</th>
                       <th>Image</th>
                       <th>Action</th>
                     </tr>
                   </thead>
                   <tbody>
                     {
                       brandAboutHindi?.isLoading ? <Loader /> :
                         <tr>
                           <td>1</td>
                           <td>{brandAboutHindi?.data?.aboutContent?.heading}</td>
                           <td>{brandAboutHindi?.data?.aboutContent?.subHeading}</td>
                           <td>{`${brandAboutHindi?.data?.aboutContent?.description.slice(0,80)}....`}</td>
                           <td><img style={{ width: "80px", height: "auto" }} src={brandAboutEnglish?.data?.aboutContent?.imageUrl}></img></td>
                           <td><Brandaboutmodelhindi previousData={brandAboutHindi?.data?.aboutContent} value={"brandsliderhindi"} /></td>
                         </tr>
                         
                     }
                   </tbody>
                 </Table>
               </div>
             </div>
           </Tab>
           <Tab eventKey="profile" title="Home About Section English Content">
             {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
               <h1 className="h3 mb-0 text-gray-800">Home About Section For English</h1>
             </div> */}
             <div className='row'>
               <div className='col-md-12'>
               <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                     <tr>
                     <th>S.NO</th>
                       <th>Heading</th>
                       <th>Sub Heading</th>
                       <th>Description</th>
                       <th>Image</th>
                       <th>Action</th>
                     </tr>
                   </thead>
                   <tbody>
                     {
                       brandAboutEnglish?.isLoading ? <Loader /> :
                         <tr>
                           <td>1</td>
                           <td>{brandAboutEnglish?.data?.aboutContent?.heading}</td>
                           <td>{brandAboutEnglish?.data?.aboutContent?.subHeading}</td>
                           <td>{`${brandAboutEnglish?.data?.aboutContent?.description.slice(0,80)}....`}</td>
                           <td><img style={{ width: "80px", height: "auto" }} src={brandAboutEnglish?.data?.aboutContent?.imageUrl}></img></td>
                           <td><Brandaboutmodelenglish previousData={brandAboutEnglish?.data} value={"brandsliderenglish"} /></td>
                         </tr>
                     }
                   </tbody>
                 </Table>
               </div>
             </div>
           </Tab>
         </Tabs>
       </div>
   </div>
    </>
  );
}

export default Brandabout;