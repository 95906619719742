import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ContactHindiVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { TbEdit } from 'react-icons/tb';


function Brandcontactmodelhindi({previousData}) {
    const [modalShow, setModalShow] = useState(false)
    return (
        <>
            <TbEdit className='h3' onClick={() => setModalShow(true)} variant="primary"></TbEdit>

            <ContactHindiVerticallyCenteredModal
            previousData={previousData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandcontactmodelhindi;