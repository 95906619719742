import React from "react";
import { useContext,useState,useEffect } from "react";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import Editor from "../../GlobalComponent/Editor/Editor";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import { useGetBrandProductEnglishQuery,
  useGetBrandProductHindiQuery,
  useUpdateProductMutation } from "./brandProductApiSlice";
export function ProductHindiVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState([]);
  const [updateProduct] = useUpdateProductMutation();
  const productEnglish = useGetBrandProductEnglishQuery();
  const productHindi = useGetBrandProductHindiQuery();
  const { brandProduct, setBrandProduct, editorState, setEditorState } =
    useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      productname:
        brandProduct?.productName?.length !== 0
          ? brandProduct?.productName
          : props?.previousData?.productName,
      heading:
        brandProduct?.heading?.length !== 0
          ? brandProduct?.heading
          : props?.previousData?.heading,
      subheading:
        brandProduct?.subHeading?.length !== 0
          ? brandProduct?.subHeading
          : props?.previousData?.heading,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.description,
      imageurl:
        brandProduct?.productImage?.length !== 0
          ? brandProduct?.productImage
          : previousImage,
      button: [
        {
          nourishlink:
            brandProduct?.nourishStoreLink?.length !== 0
              ? brandProduct?.nourishStoreLink
              : props?.previousData?.button[0].nourishlink,
        },
        {
          amazonlink:
            brandProduct?.amazonStoreLink?.length !== 0
              ? brandProduct?.amazonStoreLink
              : props?.previousData?.button[1].amazonlink,
        },
      ],
    };
    updateProduct(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        productHindi.refetch();
        productEnglish.refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
 useEffect(() => {
    setEditorState({
      ...editorState,
      editorHtml: props?.previousData?.description,
    });
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl, props?.previousData?.description]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.productName}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        productName: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[0]?.nourishlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        nourishStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        heading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[1]?.amazonlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        amazonStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Amazon store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From SubHeading Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        subHeading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter Your Sub Heading Link "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Update Your Description{" "}
                  </Card.Title>
                  <Editor value={props?.previousData?.description} />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandProduct.productImage.length !== 0 ? (
                          <>
                            <img
                              src={brandProduct.productImage}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandProduct({
                                  ...brandProduct,
                                  productImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandProduct.productImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function ProductEnglishVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState("");
  const [updateProduct] = useUpdateProductMutation();
  const productEnglish = useGetBrandProductEnglishQuery();
  const productHindi = useGetBrandProductHindiQuery();
  const { brandProduct, setBrandProduct, editorState, setEditorState } =
    useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      productname:
        brandProduct?.productName?.length !== 0
          ? brandProduct?.productName
          : props?.previousData?.productName,
      heading:
        brandProduct?.heading?.length !== 0
          ? brandProduct?.heading
          : props?.previousData?.heading,
      subheading:
        brandProduct?.subHeading?.length !== 0
          ? brandProduct?.subHeading
          : props?.previousData?.heading,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.description,
      imageurl:
        brandProduct?.productImage?.length !== 0
          ? brandProduct?.productImage
          : previousImage,
      button: [
        {
          nourishlink:
            brandProduct?.nourishStoreLink?.length !== 0
              ? brandProduct?.nourishStoreLink
              : props?.previousData?.button[0].nourishlink,
        },
        {
          amazonlink:
            brandProduct?.amazonStoreLink?.length !== 0
              ? brandProduct?.amazonStoreLink
              : props?.previousData?.button[1].amazonlink,
        },
      ],
    };
    updateProduct(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
        productHindi.refetch();
        productEnglish.refetch();
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setEditorState({
      ...editorState,
      editorHtml: props?.previousData?.description,
    });
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl, props?.previousData?.description]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.productName}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        productName: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[0]?.nourishlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        nourishStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        heading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[1]?.amazonlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        amazonStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Amazon store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From SubHeading Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        subHeading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter Your Sub Heading Link "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Update Your Description{" "}
                  </Card.Title>
                  <Editor value={props?.previousData?.description} />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandProduct.productImage.length !== 0 ? (
                          <>
                            <img
                              src={brandProduct.productImage}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandProduct({
                                  ...brandProduct,
                                  productImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandProduct.productImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>

        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
