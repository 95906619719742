import React, { useContext, useState } from "react";
import { blockInvalidChar } from "../../Forms/blockInvalidChar";
import swal from "sweetalert";
import { AgentApiSlice } from "../AgentApiSlice";

function ComicWriterRegData({}) {
  const [contentData, setContentData] = useState("");
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  const validationPhone = (number) => {
    if (number.length === 10) {
      return true;
    } else {
      return false;
    }
  };
  const validateZip = (zip) => {
    if (zip.length === 6) {
      return true;
    } else {
      return false;
    }
  };

  const [regComicWriter, setregComicWriter] = useState({
    name: "",
    age: "",
    contact: "",
    email: "",
    standard: "",
    School: "",
    city: "",
  });
  const handleregComicWriter = (e, regComicWriter) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      name: regComicWriter.name,
      mobile: regComicWriter.contact,
      email: regComicWriter.email,
      age: regComicWriter.age,
      city: regComicWriter.city,
      standard: regComicWriter.standard,
      school: regComicWriter.school,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (
      regComicWriter.name != "" &&
      regComicWriter.contact != "" &&
      regComicWriter.email != "" &&
      regComicWriter.age != "" &&
      regComicWriter.city != "" &&
      regComicWriter.standard != "" &&
      regComicWriter.school != ""
    ) {
      const ValidateEmail = validateEmail(regComicWriter.email);
      const ValidatePhone = validationPhone(regComicWriter.contact);
      if (ValidateEmail) {
        if (ValidatePhone) {
          fetch(
            `${process.env.REACT_APP_BASE_URL}api/comicentry`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status && result.statusCode === 200) {
                swal({
                  title: result.message,
                  icon: "success",
                  dangerMode: true,
                });
              } else if (result.message === "Form submitted") {
                swal({
                  title: result.message,
                  icon: "success",
                  dangerMode: true,
                  text: "Company representatives can reach out to me via the provided email address and phone number for any important communication. ",
                  // + '<a class="btn" href="/#">Download The Format</a> ',
                });
              } else {
                swal({
                  title: result.message,
                  icon: "error",
                  dangerMode: true,
                });
              }
            })
            .catch((error) =>
              swal({
                title: "Something went wrong",
                icon: "error",
                dangerMode: true,
              })
            );
        } else {
          swal({
            title: "Phone number should be 10 digit",
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal({
          title: "Email is not valid",
          icon: "error",
          dangerMode: true,
        });
      }
    } else {
      swal({
        title: "Please Fill All Field",
        icon: "error",
        dangerMode: true,
      });
    }
  };
  return (
    <>
      <form id="registration_form" className="subscribe_form mt-4">
        <div id="error-message" className="notification-div"></div>
        <div className="success-message" />
        <div className="form-row row">
          <div className="form-group col-lg-6 col-md-6 col-12 mb-5">
            <input
              type="text"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  name: e.target.value,
                });
              }}
              className="form-control input-form"
              id="name"
              name="name"
              placeholder="Enter Name"
              required
            />
          </div>
          {/* <div className="form-group col-lg-2 col-md-6 col-12 mb-5">
            <div className="form-floating">
              <input
                type="text"
                onChange={(e) => {
                  setregComicWriter({ ...regComicWriter, name: e.target.value });
                }}
                className="form-control input-form"
                id="name"
                name="name"
                placeholder="Enter Name"
                required
              />
              <label className="lable-form label-name" htmlFor="name">
                Age*
              </label>
            </div>
          </div> */}
          <div className="form-group col-lg-3 col-md-6 col-12 mb-5">
            <select
              className="form-control"
              onChange={(e) => {
                setregComicWriter({ ...regComicWriter, age: e.target.value });
              }}
              required=""
              id="age"
              name="age"
            >
              <option className="label-name">Select Age</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
            </select>
          </div>
          <div className="form-group col-lg-3 col-md-6 col-12 mb-5">
            <input
              type="number"
              onKeyDown={blockInvalidChar}
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  contact: e.target.value,
                });
              }}
              className="form-control input-form"
              required
              minLength="10"
              maxLength="10"
              id="mobile_number"
              name="mobile_number"
              placeholder="Enter Mobile Number"
            />
          </div>
          <div className="form-group col-lg-6 col-md-6 col-12 mb-5">
            <input
              type="email"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  email: e.target.value,
                });
              }}
              className="form-control input-form"
              id="email"
              name="email"
              placeholder="Enter Email"
              required
            />
          </div>
          <div className="form-group col-lg-6 col-md-6 col-12 mb-5">
            <input
              type="text"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  school: e.target.value,
                });
              }}
              className="form-control input-form"
              id="school"
              name="school"
              placeholder="Enter School Name"
              required
            />
          </div>
          <div className="form-group col-lg-6 col-md-6 col-12 mb-5">
            <input
              type="text"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  standard: e.target.value,
                });
              }}
              className="form-control input-form"
              id="standard"
              name="standard"
              placeholder="Enter Class"
              required
            />
          </div>

          <div className="form-group col-lg-6 col-md-6 col-12 mb-5">
            <input
              type="text"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  city: e.target.value,
                });
              }}
              className="form-control input-form"
              id="city"
              name="city"
              placeholder="Enter City"
              required=""
            />
          </div>
        </div>
        <div className="col-md-12 text-center mb-3">
          <button
            onClick={(e) => {
              handleregComicWriter(e, regComicWriter);
            }}
            className="btn bg-warning text-black "
          >
            <b className="fs-4"> Submit</b>
          </button>
        </div>
      </form>
    </>
  );
}

export default ComicWriterRegData;
