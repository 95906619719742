import React from 'react';
import { Button } from 'react-bootstrap';
import { ChooseMediaVerticallyCenteredModal } from '../../Modelhelper/Modelhelper';

import { useState } from 'react';

function Media({value}) {
const [ modalShow, setModalShow]=useState(false)
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                + Media
            </Button>
           
            <ChooseMediaVerticallyCenteredModal
                value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}
export default Media;