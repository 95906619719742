import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import image from "../../images/2023-05-02.png";
import image from "../../images/login/Bailkolhu-login1.jpg";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import { useState } from "react";
import Loader from "../GlobalComponent/Loader/Loader";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { Card } from "react-bootstrap";
import logo from "../../images/LOGOs.png";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isloading }] = useLoginMutation();
  const { adminLogin } = useContext(Auth);
  const Swal = require("sweetalert2");
  const { loader, setLoader } = useContext(Auth);
  const [userAdmin, setUserAdmin] = useState({
    userEmail: "",
    password: "",
  });

  // const emailToRoles = {
  //   "nisha@leadsconnect.in": "admin",y
  //   "ashutosh.verma@leadsbrandconnect.com": "Admin",
  //   "k.sandeep@leadsbrandconnect.com": "editor",
  //   // Add more email-to-role mappings here
  // };
  // const [userRole, setUserRole] = useState(null);

  const handlelogin = async (event) => {
    setLoader(true);
    const email = userAdmin.userEmail;
    const password = userAdmin.password;
    event.preventDefault();
    if (!userAdmin.userEmail && !userAdmin.password) {
      toast.warn("Username & Password Required");
      return;
    } else if (!userAdmin.userEmail) {
      toast.warn("Username Required");
      return;
    } else if (!userAdmin.password) {
      toast.warn("Password Required");
      return;
    }

    try {
      const userData = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...userData }));

      setLoader(false);

      if (userData) {
        if (userData.role == "store_manager") {
          navigate("/order");
        } else if (
          userData.role == "admin" ||
          userData.role == "editor" ||
          userData.role == "Super_Admin"
        ) {
          navigate("/dashboard");
        }
      }

      // navigate("/dashboard");
      localStorage.setItem("userDetails", JSON.stringify(userData));
      localStorage.setItem("userToken", JSON.stringify(userData.accesstoken));
      localStorage.setItem(
        "userAccessToken",
        JSON.stringify(userData.refreshtoken)
      );
      localStorage.setItem("userRole", JSON.stringify(userData.role));
      localStorage.setItem(
        "medusatoken",
        JSON.stringify(userData.medusatoken.access_token)
      );
      localStorage.setItem(
        "nourishmedusatoken",
        JSON.stringify(userData.nourishmedusatoken.access_token)
      );

      // console.log(userData.role + "userData.role")
      // localStorage.setItem("userToken",JSON.stringify(userData))
      swal({
        title: "Login Successfully!",
        icon: "success",
        dangerMode: true,
      });
    } catch (err) {
      setLoader(false);
      if (!err?.status || err?.status === "FETCH_ERROR") {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        });
      } else if (err.status === 400) {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        });
      } else if (err.status === 401) {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        });
      } else {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        });
      }
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="loginbackgroundimage">
          <div className="container">
            <div className="LoginDiv">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6">
                        <img
                          src={image}
                          alt="Loginimage"
                          className="img-fluid h-100"
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="p-5">
                          <div className="text-center">
                            <img
                              src={logo}
                              alt="Loginimage"
                              className="img-fluid"
                            />
                            {/* <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1> */}
                          </div>
                          {/* <hr/> */}
                          <form className="user text-center mt-5">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control form-control-user"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                onChange={(e) =>
                                  setUserAdmin({
                                    ...userAdmin,
                                    userEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control form-control-user"
                                id="exampleInputPassword"
                                placeholder="Password"
                                onChange={(e) =>
                                  setUserAdmin({
                                    ...userAdmin,
                                    password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            {/* <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div> */}
                            <button
                              onClick={(e) => handlelogin(e)}
                              disabled={
                                userAdmin.userEmail != "" &&
                                userAdmin.password != ""
                                  ? false
                                  : true
                              }
                              className="w-50 mt-4 btn btn-primary btn-user btn-block"
                            >
                              Sign In
                            </button>
                            {/* <hr /> */}
                            {/* <a
                              onClick={() => {
                                navigate("/resetpass");
                              }}
                              className="mt-3 text-right btn-block"
                            >
                              {" "}
                              Forgot Password
                            </a> */}
                          </form>
                        </div>
                        <div className="">
                          {" "}
                          <a
                            onClick={() => {
                              navigate("/nslogin");
                            }}
                            className="mt-3 text-center text-black btn-block"
                          >
                            {" "}
                            Click Here To Login Nourish Dashboard
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
