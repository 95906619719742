import React, { useMemo } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useGetAllschoollibQuery } from "./formApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";

function SchooLibData() {
  const { data, isLoading, isFetching, refetch } = useGetAllschoollibQuery();

  // console.log(JSON.stringify(data?.comicUsers + "data"));
  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) {
      return "";
    }

    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); //Use array instead of data

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  const columns = [
    {
      name: "S No.",
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "School/Lib Name",
      wrap: true,
      selector: (row) => row.schoolLibName,
      sortable: false,
    },

    {
      name: "Contact Person Name",
      wrap: true,
      selector: (row) => row.contactPerson,
      sortable: false,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: false,
    },

    {
      name: "Address",
      wrap: true,
      selector: (row) => row.address,
      sortable: false,
    },
    {
      name: "Message",
      grow: 3,
      wrap: true,
      selector: (row) => row.message,
      sortable: false,
    },
  ];
  const queries = data?.entries || [];
  const Tabledata = queries.map((item, index) => ({
    i: index + 1,
    createdAt: format(new Date(item.createdAt), "MM/dd/yyyy HH:mm:ss"),
    schoolLibName: item.schoolLibName,
    contactPerson: item.contactPerson,
    email: item.email,
    mobile: item.mobile,
    address: item.address,
    message: item.message,
  }));

  function downloadCSV() {
    const csvContent = [
      "\uFEFF" +
        [
          "S No.",
          "Date",
          "School/Lib Name",
          "Contact Person Name",
          "Email",
          "Mobile",
          "Address",
          "Message",
        ].join(","),
      ...Tabledata.map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? `"${value}"` : value))
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "SchoolData.csv");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">School/Lib Form Data</h1>
          <button
            className="btn btn-sm btn-primary shadow-sm"
            onClick={downloadCSV}
          >
            <i className="fas fa-download fa-sm text-white-50"></i> Export Data
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            {isFetching && isLoading ? (
              <Loader />
            ) : (
              <DataTable
                className="TableNs"
                columns={columns}
                data={Tabledata}
                pagination
                defaultSortDesc={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SchooLibData;
