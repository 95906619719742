import React from "react";
import { useContext } from "react";
import {
  useCreateSliderMutation,
  useGetAllSliderQuery,
} from "../../../features/slider/sliderApiSlice";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
export function BrandSliderVerticallyCenteredModal(props) {
  const {
    mediaImage,
    setMediaImage,
    brand,
    setBrand,
  } = useContext(Auth);
  const [createPost] = useCreateSliderMutation();
  const getAllSlider = useGetAllSliderQuery();
  const handleSubmit = (e, props) => {
    e.preventDefault();
    if (
      brand.imageMobile.length === 0 ||
      brand.imageWeb.length === 0 ||
      brand.clickUrl === "" ||
      brand.imageAlt === ""
    ) {
      alert("Please field all field");
    } else {
      var raw = {
        clickurl: brand.clickUrl,
        alt: brand.imageAlt,
        imageweb: brand.imageWeb,
        imagemobile: brand.imageMobile,
      };
      createPost(raw)
        .unwrap()
        .then((data) => {
          props.onHide();
          getAllSlider.refetch();
          swal({
            title: data.message,
            icon: "success",
            dangerMode: true,
          });
          setMediaImage({
            ...mediaImage,
            sliderWebImage: "",
            sliderMobileImage: "",
          });
        })
        .catch((error) => {
          swal({
            title: "Something Went Wrong Please Try Again",
            icon: "success",
            dangerMode: true,
          });
        });
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title className="text-center  font-weight-bold theme-primary text-black p-2">
                    {" "}
                    Slider Section
                  </Card.Title>
                  <Card.Title className="mt-3 font-weight-bold">
                    Choose Image for slider
                  </Card.Title>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label className="">Enter Image Link</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label className="">Enter Image Alt</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          onChange={(e) =>
                            setBrand({ ...brand, clickUrl: e.target.value })
                          }
                          className="w-100 "
                          type="text"
                          placeholder="Enter Image Link"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          onChange={(e) =>
                            setBrand({ ...brand, imageAlt: e.target.value })
                          }
                          className="w-100 "
                          type="text"
                          placeholder="Enter Image Alt"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="mt-4 font-weight-bold">
                        Upload Web Sider Image
                      </h6>

                      <Media value={"sliderweb"} />
                    </div>
                    {/* <div className='col-md-2'></div> */}
                    <div className="col-md-6">
                      <h6 className="mt-4 font-weight-bold">
                        Upload Mobile Sider Image
                      </h6>

                      <Media value={"slidermobile"} />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-3">
                          <img
                            alt="slider"
                            src={
                              mediaImage.sliderWebImage.length !== 0
                                ? mediaImage.sliderWebImage
                                : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                            }
                            className="slider-image mt-3 img-fuild"
                          ></img>
                          {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                          {mediaImage.sliderWebImage.length !== 0 ? (
                            <RxCrossCircled
                              className="delteicon text-danger"
                              onClick={() => {
                                setMediaImage({
                                  ...mediaImage,
                                  sliderWebImage: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mt-3">
                          <img
                            alt="slider"
                            src={
                              mediaImage.sliderMobileImage.length !== 0
                                ? mediaImage.sliderMobileImage
                                : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                            }
                            className="slider-image mt-3 img-fuild"
                          ></img>
                          {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderMobileImage: mediaImage.sliderMobileImage.filter(function (val) { return val !== item })})}} /> */}
                          {mediaImage.sliderMobileImage.length !== 0 ? (
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setMediaImage({
                                  ...mediaImage,
                                  sliderMobileImage: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e, props);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
