import React, { useState, useEffect, useCallback } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import VoucherCreation from "./VoucherCreation";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import { RxDesktop } from "react-icons/rx";
import Accordion from "react-bootstrap/Accordion";
import {
  MdDeleteForever,
  MdDesktopAccessDisabled,
  MdOutlineEditCalendar,
} from "react-icons/md";
import VoucherEdit from "./VoucherEdit";
import { TbArrowGuide } from "react-icons/tb";

const VoucherDashborad = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const [VoucherData, setVoucherData] = useState();
  const [CustomerData, setCustomerData] = useState();
  // const [Dsicountid, setDsicountid] = useState();
  const navigate = useNavigate();

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts`,
        requestOptions
      );
      const result = await response.json();
      setVoucherData(result);
    } catch (error) {
      console.log("error", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
        // swal({
        //   title: err.data.message,
        //   icon: "error",
        //   dangerMode: true,
        // });
      } else {
        swal({
          title: "Someting went wrong1",
          icon: "error",
          dangerMode: true,
        });
      }
    }
  };

  // Get group list code
  const FetchCustomerGroup = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups?expand=customers`,
        requestOptions
      );
      const result = await response.json();
      setCustomerData(result.customer_groups);
    } catch (error) {
      console.log("error", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
      } else {
        swal({
          title: "Something went wrong",
          icon: "error",
          dangerMode: true,
        });
      }
    }
  }, [navigate, requestOptions]);

  useEffect(() => {
    fetchData();
    FetchCustomerGroup();
    // DsicountDisable();
  }, []);

  // Unpublish Dsicount code
  const DsicountDisable = async (id, status) => {
    const raw = JSON.stringify({
      is_disabled: status,
    });
    const requestPostOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts/${id}`,
        requestPostOptions
      );
      const result = await response.json();
      // setVoucherData(result);
      // console.log(result);
    } catch (error) {
      console.log("error", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
        // swal({
        //   title: err.data.message,
        //   icon: "error",
        //   dangerMode: true,
        // });
      } else {
        swal({
          title: "Someting went wrong1",
          icon: "error",
          dangerMode: true,
        });
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 30);
  };

  // Group delete from list code
  const handleGroup = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify({
            id: id,
            // object: "customer_group",
            // deleted: true
          }),
        }
      );

      if (response.ok) {
        await fetchData(); // Refresh the main table data
      } else {
        throw new Error("Error removing user");
      }
    } catch (error) {
      console.log("Error removing user:", error);
    }
  };

  const columns = [
    {
      name: "S. No.",
      selector: (row) => row.i,
      sortable: true,
      grow: 1,
    },
    {
      name: "Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },

    {
      name: "Code",
      selector: (row) => <b>{row?.code}</b>,
      sortable: false,
      wrap: true,
      grow: 1,
    },
    {
      name: "Status",
      selector: (row) =>
        row?.is_disabled ? (
          <b className="text-danger">Disabled</b>
        ) : (
          <b className="text-success">Active</b>
        ),
      sortable: false,
      grow: 1,
    },
    {
      name: "Action",
      selector: (row) => (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="p-2"
          ></Dropdown.Toggle>

          <Dropdown.Menu>
            {row.is_disabled ? (
              <Dropdown.Item
                onClick={(e) => DsicountDisable(row.id, false)}
                className="fw-bold d-flex align-items-center"
              >
                <RxDesktop className="fs-6 mr-2" />
                Enable
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(e) => DsicountDisable(row.id, true)}
                className="fw-bold d-flex align-items-center"
              >
                <MdDesktopAccessDisabled className="fs-6 mr-2" />
                Disabled
              </Dropdown.Item>
            )}
            <span
              className="fw-bold align-items-center px-3 d-block dropdown-item"
              style={{ cursor: "pointer" }}
            >
              <MdOutlineEditCalendar className="fs-6  mr-2" />

              <VoucherEdit VoucherID={row.id} GroupdId={CustomerData} />
            </span>
            <Dropdown.Item
              onClick={() => handleGroup(row.id)}
              className="fw-bold text-danger align-items-center"
            >
              <MdDeleteForever className="fs-6  mr-2" />
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      grow: 1,
    },
    // {
    //   name: "id",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   wrap: true,
    //   grow: 3,
    // },
   
    {
      name: "Amount",
      selector: (row) =>
        `${
          row.rule?.type === "fixed"
            ? parseFloat(row.rule?.value / 100).toFixed(2)
            : row.rule?.value
        }` +
        (row.rule?.type === "fixed"
          ? " INR"
          : row.rule?.type === "percentage"
          ? "%"
          : row.rule?.type === "free_shipping"
          ? " Shipping"
          : ""),

      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.rule?.description,
      sortable: true,
      wrap: true,
      grow: 2,
    },

    {
      name: "Start Date",
      selector: (row) => format(new Date(row.starts_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) =>
        row.ends_at !== null
          ? format(new Date(row.ends_at), "MM/dd/yyyy")
          : "No end Date",
      sortable: true,
    },
    {
      name: "Channel",
      selector: (row) => row.metadata?.channel,
      sortable: true,
    },
    {
      name: "Visible to",
      selector: (row) => row.metadata?.usetype,
      sortable: true,
    },
    {
      name: "Group ID",
      selector: (row) => row.metadata?.group_id,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: "Action",
      selector: (row) => (
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="dropdown-basic"
            className="p-2"
          ></Dropdown.Toggle>

          <Dropdown.Menu>
            {row.is_disabled ? (
              <Dropdown.Item
                onClick={(e) => DsicountDisable(row.id, false)}
                className="fw-bold d-flex align-items-center"
              >
                <RxDesktop className="fs-6 mr-2" />
                Enable
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(e) => DsicountDisable(row.id, true)}
                className="fw-bold d-flex align-items-center"
              >
                <MdDesktopAccessDisabled className="fs-6 mr-2" />
                Disabled
              </Dropdown.Item>
            )}
            <span
              className="fw-bold align-items-center px-3 d-block dropdown-item"
              style={{ cursor: "pointer" }}
            >
              <MdOutlineEditCalendar className="fs-6  mr-2" />

              <VoucherEdit VoucherID={row.id} GroupdId={CustomerData} />
            </span>
            <Dropdown.Item
              onClick={() => handleGroup(row.id)}
              className="fw-bold text-danger align-items-center"
            >
              <MdDeleteForever className="fs-6  mr-2" />
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const Tabledata = VoucherData?.discounts?.map((item, index) => ({
    i: index + 1,
    id: item?.id,
    created_at: item?.created_at,
    code: item?.code,
    is_disabled: item?.is_disabled,
    starts_at: item?.starts_at,
    ends_at: item?.ends_at,
    rule: item?.rule,
    metadata: item?.metadata,
  }));

  return (
    <div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div role="alert" class="fade alert alert-success show">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="p-0 m-0">
                  <div class="alert-heading h5">
                    <span class="admonitionIcon_Ibzs">
                      <TbArrowGuide />
                    </span>
                    Step by Step Guide to Create Discount Voucher and Customer
                    Group
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div class="admonitionContent_vXIg">
                    <b>Create Discount Step</b>
                    <p>
                      Click on the <code>Add Discount</code> button to create a
                      new discount. Make sure to fill all the required fields.
                      If you want to create a voucher for a specific customer,
                      first create a <code>Customer Group</code> by clicking the
                      button on the left side. Write the customer group name and
                      press the Enter button. After creating the customer group,
                      add the customers who can use the voucher to the user
                      list.
                    </p>

                    <b>Edit Step</b>
                    <p>
                      To edit an existing voucher, click on the{" "}
                      <code>Action</code> button, select <code>Edit</code>, and
                      make the necessary changes.
                    </p>

                    <b>Voucher Activate/Deactivate Step</b>
                    <p>
                      To activate or deactivate an existing voucher, click on
                      the <code>Action</code> button, and select{" "}
                      <code>Enable/Disable</code> as needed.
                    </p>

                    <b>Voucher Delete Step</b>
                    <p>
                      To delete an existing voucher, click on the{" "}
                      <code>Action</code> button and select <code>Delete</code>.
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <div className="col-md-12">
          <div className="pb-5 d-flex justify-content-between">
            <span>
              {" "}
              <a
                type="button"
                className="btn btn-primary"
                href="/nourish-customer-group"
              >
                Customer Group
              </a>
            </span>
            <VoucherCreation GroupdId={CustomerData} />
          </div>
          <DataTable
            className="TableNs"
            columns={columns}
            data={Tabledata}
            defaultSortAsc={true}
            // progressPending={pending}
            // paginationTotalRows={Tabledata?.length}
            persistTableHead
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default VoucherDashborad;
