import React from "react";
import { useContext, useEffect,useState } from "react";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import {
  useGetAllSliderQuery,
  useUpdateSliderMutation,
} from "../../../features/slider/sliderApiSlice";
export function UpdateSliderVerticallyCenteredModal(props) {
  const getSlider = useGetAllSliderQuery();
  const id = props?.previousData?._id;
  const { updateBrand, setUpdateBrand } = useContext(Auth);
  const [updateModel] = useUpdateSliderMutation();
  const [previousWebImage, setPreviousWebImage] = useState([]);
  const [previousMobileImage, setPreviousMobileImage] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      newclickurl:
        updateBrand?.newclickurl?.length !== 0
          ? updateBrand?.newclickurl
          : props?.previousData?.clickUrl,
      newalt:
        updateBrand?.newalt?.length !== 0
          ? updateBrand?.newalt
          : props?.previousData?.alt,
      newimageweb:
        updateBrand.newimageweb.length !== 0
          ? updateBrand?.newimageweb
          : previousWebImage,
      newimagemobile:
        updateBrand?.newimagemobile?.length !== 0
          ? updateBrand?.newimagemobile
          : previousMobileImage,
    };
    updateModel({ raw, id })
      .unwrap()
      .then((data) => {
        props.onHide();
        getSlider.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousWebImage(props?.previousData?.bannerUrl_web);
    setPreviousMobileImage(props?.previousData?.bannerUrl_mobile);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title className="text-center">
                    Update Your Slider{" "}
                  </Card.Title>
                  <Card.Title className="text-primary mt-3">
                    Choose Image for slider
                  </Card.Title>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Image Link</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Enter Image Alt</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.clickUrl}
                          onChange={(e) =>
                            setUpdateBrand({
                              ...updateBrand,
                              newclickurl: e.target.value,
                            })
                          }
                          className="w-100 "
                          type="text"
                          placeholder="Enter Image Link"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.alt}
                          onChange={(e) =>
                            setUpdateBrand({
                              ...updateBrand,
                              newalt: e.target.value,
                            })
                          }
                          className="w-100 "
                          type="text"
                          placeholder="Enter Image Alt"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex row">
                    <div className="col-md-6 mt-2">
                      {/* web files */}
                      <Media value={"updatesliderweb"} />
                    </div>

                    <div className="col-md-6 mt-2">
                      {/* for mobile files */}
                      <Media value={"updateslidermobile"} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {updateBrand.newimageweb.length !== 0 ? (
                          <>
                            <img
                              src={updateBrand.newimageweb}
                              className="slider-image mt-3"
                              alt="sliderimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setUpdateBrand({
                                  ...updateBrand,
                                  newimageweb: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {updateBrand.newimageweb.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousWebImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousWebImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousWebImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                    <div className=" d-flex m-2 col-md-1"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {updateBrand.newimagemobile.length !== 0 ? (
                          <>
                            <img
                              src={updateBrand.newimagemobile}
                              className="slider-image mt-3"
                              alt="sliderimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setUpdateBrand({
                                  ...updateBrand,
                                  newimagemobile: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {updateBrand.newimagemobile.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousMobileImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousMobileImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousMobileImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e, props);
          }}
        >
          Update slider{" "}
        </Button>
        <Button>Reset All</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
