import React from 'react';
import Footermodel from '../../GlobalComponent/Model/Footermodel';
import { Table } from 'react-bootstrap';
import './footer.css'
import { useGetFooterQuery } from './footerApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
function Footer() {
  const getFooter = useGetFooterQuery()
  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Footer Section</h1>

        </div>
        <div className='row'>
          <div className='col-md-12'>
            <Table responsive="sm" >
              <thead>
                <tr>
                  <th>S.NO</th>
                  <th>Facebook</th>
                  <th>Twitter</th>
                  <th>Instragram</th>
                  <th>Youtube</th>
                  <th>Linkedin</th>
                  <th>Wikipedia</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  getFooter?.isLoading ? <Loader /> :
                    <>
                      {
                        getFooter.data.footer.map((item, i) => {
                          return (
                            <>
                              <tr>
                                <td>{i + 1}</td>
                                <td>{`${item.facebook.slice(0, 20)}...`}</td>
                                <td>{`${item.twitter.slice(0, 20)}...`}</td>
                                <td>{`${item.instagram.slice(0, 20)}...`}</td>
                                <td>{`${item.youtube.slice(0, 20)}...`}</td>
                                <td>{`${item.linkedin.slice(0, 20)}...`}</td>
                                <td>{`${item.wiki.slice(0, 20)}...`}</td>

                                <td><Footermodel previousData={item} value={"blogupdatehindi"} />
                                </td>
                              </tr>
                            </>
                          )
                        })
                      }
                    </>
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;