import React, { useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { MdAdd } from "react-icons/md";
import { RxPlus } from "react-icons/rx";

const VoucherCreation = ({ GroupdId }) => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const [newStatus, setNewStatus] = useState("");

  // Model
  const [show, setShow] = useState(false);
  const [CustomerDetailShow, setCustomerDetailShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("percentage");
  const [percentage, setPercentage] = useState("");
  const [startsAt, setStartsAt] = useState("");
  const [endsAt, setEndsAt] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [channel, setChannel] = useState("both");
  const [usetype, setUseType] = useState("public");
  const [groupId, setGroupId] = useState("na");
  const [GroupIdCustomerData, setGroupIdCustomerData] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  // console.log("GroupIdCustomerData" + GroupIdCustomerData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const discountData = {
      code,
      rule: {
        type: "percentage",
        value: parseFloat(percentage),
        description,
        allocation: "total",
      },
      regions: ["reg_01HBJS9WBK5R58KD5NA233GFEW"],
      // regions: [
      //   { name: "India",}],
      starts_at: new Date(startsAt).toISOString(),
      ends_at: new Date(endsAt).toISOString(),
      usage_limit: parseInt(usageLimit),
      metadata: {
        channel: channel,
        usetype: usetype,
        group_id: groupId,
      },
      is_disabled: true
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify(discountData),
        }
      );

      if (!response.ok) {
        throw new Error("Error creating discount");
      }

      const data = await response.json();
      setSuccess("Discount created successfully!");

      setDescription("");
      setCode("");
      setType("percentage");
      setPercentage("");
      setStartsAt("");
      setEndsAt("");
      setUsageLimit("");
      setChannel("both");
      setUseType("public");
      setGroupId("na");


      setTimeout(() => {
        window.location.reload();
      }, 30);
    } catch (error) {
      setError(error.message);
    }
  
  };

  return (
    <div className="">
      <Button className="float-right" variant="primary" onClick={handleShow}>
        <MdAdd /> Add Discount
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Discount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <Row className="justify-content-md-center">
              <Col md={12}>
                {error && <div className="alert alert-danger">{error}</div>}
                {success && (
                  <div className="alert alert-success">{success}</div>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formType">
                    <Form.Label>Discount type</Form.Label>
                    <div className="d-flex justify-content-start align-item-start Vouchertype">
                      <Form.Check
                        type="radio"
                        className="border-0"
                        label="Percentage"
                        name="typeOptions"
                        id="typePercentage"
                        value="percentage"
                        checked={type === "percentage"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      {/* <Form.Check
                        className=""
                        type="radio"
                        label="Fixed"
                        name="typeOptions"
                        id="typeFixed"
                        value="fixed"
                        checked={type === "fixed"}
                        onChange={(e) => setType(e.target.value)}
                      /> */}
                    </div>
                  </Form.Group>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formCode">
                        <Form.Label>Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter discount code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formPercentage">
                        <Form.Label>Percentage</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter discount percentage"
                          value={percentage}
                          onChange={(e) => setPercentage(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="formDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter discount description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formStartsAt">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={startsAt}
                          onChange={(e) => setStartsAt(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formEndsAt">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          value={endsAt}
                          onChange={(e) => setEndsAt(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formUsageLimit">
                        <Form.Label>Usage Limit</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter usage limit"
                          value={usageLimit}
                          onChange={(e) => setUsageLimit(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formChannel">
                        <Form.Label>Channel</Form.Label>
                        <Form.Control
                          as="select"
                          value={channel}
                          onChange={(e) => setChannel(e.target.value)}
                          required
                        >
                          <option value="both">Both</option>
                          <option value="website">Website</option>
                          <option value="app">App</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <Form.Group controlId="formUseType">
                        <Form.Label>Use Type</Form.Label>
                        <Form.Control
                          as="select"
                          value={usetype}
                          onChange={(e) => setUseType(e.target.value)}
                          required
                        >
                          {/* <option value="public">Select</option> */}
                          <option value="public">Public</option>
                          <option value="restricted">Restricted</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group controlId="formGroupId">
                        <Form.Label className="d-flex justify-content-around">
                          Select Group{" "}
                          <a
                            type="button"
                            className="btn btn-primary ml-2 px-2 pb-1 pt-0 lh-1"
                            href="/nourish-customer-group"
                          >
                            <RxPlus /> group
                          </a>
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            as="select"
                            value={groupId}
                            onChange={(e) => setGroupId(e.target.value)}
                            required
                          >
                            {/* <option value="">Select</option> */}
                            <option value="na">NA</option>
                            {GroupdId?.map((item, index) => (
                              <option value={item.id}>
                                {item.name}
                                {item.customers.map(
                                  (itemss, index) => itemss.email
                                )}
                              </option>
                            ))}
                          </Form.Control>
                          {/* {groupId !== "na" ? (
                            <InputGroup.Text className="p-0 bg-black">
                              <a
                              onClick={(e) => setCustomerDetailShow(true)}
                                type="button"
                                className="btn text-white"
                                // href="/nourish-customer-group"
                              >
                                <BsEye /> View
                              </a>
                            </InputGroup.Text>
                          ) : null} */}
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    {/* <Col lg={6}>
                      <Form.Group controlId="formGroupId">
                        <Form.Label>Group ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter group ID"
                          value={groupId}
                          onChange={(e) => setGroupId(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col> */}
                    {CustomerDetailShow || groupId !== "na" ? (
                      <Col lg={12} >
                        <b className="mt-3 mb-2 d-block">Customer Group User Details List</b>
                        <table class="table ">
                          <thead>
                            <tr>
                              <th scope="col">User Name</th>
                              <th scope="col">Email</th>
                              <th scope="col">Phone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {JSON.stringify(GroupdId.filter((gid) =>groupId ===   gid.id))} */}

                            {/* .filter((note) => row.id === note.resource_id) */}
                            {GroupdId?.map((item, index) =>
                              item?.customers
                                ?.filter((gid) => groupId === item.id)
                                .map((itemss, index) => (
                                  <tr>
                                    <td>{itemss?.first_name} </td>
                                    <td>{itemss?.email} </td>
                                    <td>{itemss?.phone} </td>{" "}
                                  </tr>
                                ))
                            )}
                          </tbody>
                        </table>
                      </Col>
                    ) : null}
                  </Row>

                  <Button variant="primary" type="submit" className="mt-3">
                    Create Discount
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default VoucherCreation;
