import React from "react";
import { useGetAllJoinTheJourneyQuery } from "./formApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

function JointheJourney() {
  const { data, isLoading, isFetching, refetch } =
    useGetAllJoinTheJourneyQuery();
  // Download csv Code Start

  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) {
      return "";
    }

    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); // Use array instead of data

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Download csv Code End
  const columns = [
    {
      name: "S No.",
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
    },
    {
      name: "Address",
      wrap: true,
      selector: (row) => row.address,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "District",
      selector: (row) => row.district,
    },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
    },
  ];
  const queries = data?.users || [];
  const Tabledata = queries.map((item, index) => ({
    i: index + 1,

    createdAt: format(new Date(item.createdAt), "MM/dd/yyyy HH:mm:ss"),
    name: item.name,
    mobile: item.mobile,
    email: item.email,
    address: item.address,
    city: item.city,
    district: item.district,
    state: item.state,
    pincode: item.pincode,
  }));
  function downloadCSV() {
    const csvContent = [
      "\uFEFF" + ["S No.", "Date", "Name", "Email", "Phone", "Address", "City", "District", "State", "Pincode"].join(","),
    ...Tabledata.map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? `"${value}"` : value))
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "JointheJourney.csv");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Join The Journey</h1>
        <button
                  className="btn btn-sm btn-primary shadow-sm"
                  onClick={downloadCSV}
                >
                  <i className="fas fa-download fa-sm text-white-50"></i> Export Data
                </button>
      </div>
      <div className="row">
        <div className="col-md-12">
      
          {isFetching && isLoading ? (
            <Loader />
          ) : (
            <DataTable
                className="TableNs"
              columns={columns}
              data={Tabledata}
              pagination
              defaultSortAsc={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default JointheJourney;
