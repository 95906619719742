import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ComicWriterRegData from "./Form/ComicWriterRegData";
function AddSingleData() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="bg-white p-2">
        <a className="btn btn-danger ms-3" href="#">
          Download Sample File
        </a>
        <button className="btn btn-secondary ms-3" onClick={handleShow}>
          Add Single Data
        </button>
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Comic Writer Form</Modal.Title>
        </Modal.Header>
        <Modal.Body><ComicWriterRegData/></Modal.Body>
       
      </Modal>
    </>
  );
}

export default AddSingleData;
