import React, { useState, useEffect, useCallback, useMemo } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import VoucherCreation from "./VoucherCreation";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import { MdAdd, MdDeleteForever, MdOutlineEditCalendar } from "react-icons/md";
import { Button, Modal, Form } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import { TbTrash } from "react-icons/tb";

const CustomerGroupCreation = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const [expandedRows, setExpandedRows] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [userModalShow, setUserModalShow] = useState(false);
  const [CreateGroup, setCreateGroup] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [CustomerData, setCustomerData] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const navigate = useNavigate();

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  // Get group list code
  const fetchData = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups?expand=customers`,
        requestOptions
      );
      const result = await response.json();
      setCustomerData(result.customer_groups);
    } catch (error) {
      console.log("error", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
      } else {
        swal({
          title: "Something went wrong",
          icon: "error",
          dangerMode: true,
        });
      }
    }
  }, [navigate, requestOptions]);

  // Get Customer list code
  const fetchAllCustomers = useCallback(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customers?limit=100000000`,
        requestOptions
      );
      const result = await response.json();
      setAllCustomers(result.customers);
      setFilteredCustomers(result.customers);
    } catch (error) {
      console.log("Error fetching all customers:", error);
    }
  }, [requestOptions]);

  // Group delete from list code
  const handleGroup = async (Groupdid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups/${Groupdid}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify({
            id: Groupdid,
            // object: "customer_group",
            // deleted: true
          }),
        }
      );

      if (response.ok) {
        await fetchData(); // Refresh the main table data
      } else {
        throw new Error("Error removing user");
      }
    } catch (error) {
      console.log("Error removing user:", error);
    }
  };

  // Group create Function code Satrt
  const group = JSON.stringify({
    name: CreateGroup,
  });

  const requestGroupOptions = {
    method: "POST",
    headers: myHeaders,
    body: group,
    redirect: "follow",
  };

  const handleCreateGroup = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups`,
        requestGroupOptions
      );
      if (response.ok) {
        await fetchData();
      } else {
        throw new Error("Error adding users to group");
      }
    } catch (error) {
      console.log("Error adding users to group:", error);
    }
  };
  // Group create Function code End

  useEffect(() => {
    fetchData();
    fetchAllCustomers();
  }, []);

  // Group Filter code start

  useEffect(() => {
    setFilteredCustomers(
      allCustomers.filter(
        (customer) =>
          customer?.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          customer?.first_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          customer?.phone?.includes(searchTerm)
      )
    );
  }, [searchTerm, allCustomers]);

  // Group Filter code end

  const toggleExpandRow = (id, customers) => {
    // console.log("customers" + JSON.stringify(customers));
    setExpandedRows({ id, customers });
    setUserModalShow(true);
  };

  // Customer Group List
  const columns = [
    {
      name: "S. No.",
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Group ID",
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
      grow: 3,
    },
    {
      name: "Group Name",
      selector: (row) => <b>{row?.name}</b>,
      sortable: false,
      wrap: true,
    },
    {
      name: "Add New User to list",
      selector: (row) => (
        <>
         <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setSelectedGroup(row.id);
              // setSelectedCustomers(
              //   row.customers.map((customer) => customer.id)
              // );
              setModalShow(true);
            }}
          >
            <MdAdd /> Add User   
          </Button>
        </>
      ),
      sortable: false,
      wrap: true,
    },
    {
      name: "Group Users List",
      selector: (row) => (
        <>
          <Button
            size="sm"
            onClick={() => toggleExpandRow(row.id, row.customers)}
            onChange={() => setExpandedRows(row.id, row.customers)}
          >
            <BsEye /> Users List
          </Button>
        </>
      ),
      sortable: false,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <Button variant="danger" size="sm" onClick={() => handleGroup(row.id)}>
          <TbTrash /> Delete Group{" "}
        </Button>
      ),
    },
  ];

  const Tabledata = CustomerData?.map((item, index) => ({
    i: index + 1,
    id: item?.id,
    created_at: item?.created_at,
    name: item?.name,
    customers: item?.customers || [],
  }));

  // Customer List Table
  const Customercolumns = [
    {
      name: "Name",
      selector: (row) => row?.first_name,
      sortable: false,
      wrap: true,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: false,
      wrap: true,
      grow: 3,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone,
      sortable: false,
      wrap: true,
      grow: 2,
    },
  ];

  const Cstdata = filteredCustomers?.map((item) => ({
    id: item?.id,
    first_name: item?.first_name,
    email: item?.email,
    phone: item?.phone,
  }));

  // Group row Selected code

  const handleRowSelected = React.useCallback((state) => {
    const selectedIds = state.selectedRows.map((row) => ({ id: row.id }));
    setSelectedRows(selectedIds);
    // console.log(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      if (window.confirm(`Are you sure?`)) {
        setToggleCleared(!toggleCleared);
      }
    };

    return (
      <Button
        key="delete"
        onClick={handleDelete}
        style={{
          backgroundColor: "red",
        }}
        icon
      >
        Clear Selected
      </Button>
    );
  }, [toggleCleared]);

  // Customer add and remove from group list code
  const raw = JSON.stringify({
    customer_ids: selectedRows,
  });

  const requestPostOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const handleAddUsersToGroup = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups/${selectedGroup}/customers/batch`,
        requestPostOptions
      );
      if (response.ok) {
        await fetchData(); // Refresh the main table data
        // toggleExpandRow(expandedRows)
        setSelectedRows([]); // Clear the selected rows

        // Ensure the modal closes only after the data is refreshed
        setModalShow(false); // Close the "Add User" modal
        setUserModalShow(false); // Close the users list modal to refresh

        // Reopen the user list modal to reflect changes
        // setTimeout(() => setUserModalShow(true), 0);
      } else {
        throw new Error("Error adding users to group");
      }
    } catch (error) {
      console.log("Error adding users to group:", error);
    }
  };

  const handleRemoveUser = async (customerId, Groupdid) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/customer-groups/${Groupdid}/customers/batch`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify({ customer_ids: [{ id: customerId }] }),
        }
      );

      if (response.ok) {
        await fetchData(); // Refresh the main table data
        // toggleExpandRow(expandedRows)
        // Close the users list modal to refresh
        setUserModalShow(false);
        // Reopen the user list modal to reflect changes
        // setTimeout(() => setUserModalShow(true), 0);
      } else {
        throw new Error("Error removing user");
      }
    } catch (error) {
      console.log("Error removing user:", error);
    }
  };

  // Customer add and remove from group list code

  // Customer added list code

  const Selectedcolumns = [
    { name: "S. No.", grow: 1, selector: (row) => row.sn },
    { name: "Name", wrap: true, grow: 2, selector: (row) => row.first_name },
    { name: "Email", wrap: true, grow: 3, selector: (row) => row.email },
    { name: "Phone", wrap: true, grow: 2, selector: (row) => row.phone },
    {
      name: "Action",
      cell: (row) => (
        <Button
          variant="danger"
          size="sm"
          onClick={() => handleRemoveUser(row.id, expandedRows.id)}
        >
          Remove
        </Button>
      ),
    },
  ];

  const SelectedexpandedRows = expandedRows?.customers?.map((item, index) => ({
    sn: index + 1,
    id: item.id,
    first_name: item.first_name,
    email: item.email,
    phone: item.phone,
  }));

  // console.log("expandedRows" + JSON.stringify(expandedRows));
  return (
    <div>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="pb-5 col-lg-5 float-end">
            <Form className="row">
              <Form.Group controlId="search" className="col-lg-9">
                {/* <Form.Label>Search User</Form.Label> */}
                <Form.Control
                  type="text"
                  placeholder="Enter Group Name"
                  value={CreateGroup}
                  onChange={(e) => setCreateGroup(e.target.value)}
                />
              </Form.Group>

              <Button
                variant="primary"
                className="col-lg-3"
                onClick={handleCreateGroup}
                disabled={CreateGroup === "" ? true : false}
              >
                Create Group
              </Button>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={Tabledata}
            defaultSortAsc={false}
            pagination
          />
        </div>
      </div>

      {/* Add Users Modal */}
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add User to Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="search" className="mb-2">
              <Form.Label>Search User</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search by email"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>

            <DataTable
              title="Customer Data"
              columns={Customercolumns}
              data={Cstdata}
              selectableRows
              contextActions={contextActions}
              onSelectedRowsChange={handleRowSelected}
              clearSelectedRows={toggleCleared}
              pagination
            />
            <Button variant="primary" onClick={handleAddUsersToGroup}>
              Add Selected Users to Group
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Show Users Modal */}
      <Modal
        show={userModalShow}
        onHide={() => setUserModalShow(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Group Customer List</Modal.Title>
          {/* <Button
            className="ml-5"
            size="sm"
            onClick={() => {
              setSelectedGroup(expandedRows.id);
              // setSelectedCustomers(
              //   row.customers.map((customer) => customer.id)
              // );
              setModalShow(true);
            }}
          >
            <MdAdd /> Add Customer
          </Button> */}
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={Selectedcolumns}
            data={SelectedexpandedRows}
            defaultSortAsc={false}
            pagination
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUserModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerGroupCreation;
