import React, { useState } from 'react';

import { AboutoilenglishVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { BiEditAlt } from 'react-icons/bi';


function Brandaboutoilmodelenglish({ previousData, value }) {
    // const { updateEnglishModelShow, setUpdateEnglishModelShow } = useContext(Auth)
    const [updateEnglishModelShow, setUpdateEnglishModelShow]=useState(false)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => {setUpdateEnglishModelShow(true)}} />
            <AboutoilenglishVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={updateEnglishModelShow}
                onHide={() => setUpdateEnglishModelShow(false)}
            />
        </>
    );
}

export default Brandaboutoilmodelenglish;