import { blogEndPoints } from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";
export const brandProductApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllBlog: builder.query({
            query: (raw) => {
                const { language } = raw
                return {
                    url: `${blogEndPoints}`,
                    method: 'GET',
                    params:{language}
                }
            }
        }),
        createBlog: builder.mutation({
            query: (raw) => {
                return {
                    url: `${blogEndPoints}`,
                    method: 'POST',
                    body: raw,
                }
            }
        }),
        updateBlog: builder.mutation({
            query: (newHeader) => {
                return {
                    url: blogEndPoints,
                    method: 'POST',
                    body: newHeader,
                }
            }
        }),
        deleteBlog: builder.mutation({
            query: (id) => {
                return {
                    url: `${blogEndPoints}/${id}`,
                    method: 'DELETE',
                    // body:raw
                }
            }
        }),
        updateBlogPageContent: builder.mutation({
            query: (newHeader) => {
                return {
                    url: blogEndPoints,
                    method: 'PATCH',
                    body: newHeader,
                }
            }
        }),


    })
})
export const { useGetAllBlogQuery,useCreateBlogMutation,useUpdateBlogMutation,useDeleteBlogMutation,useUpdateBlogPageContentMutation } = brandProductApiSlice