import React from 'react';
import './header.css'
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useGetHeaderEnglishQuery, useGetHeaderHindiQuery } from './headerApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Headermodelhindi from '../../GlobalComponent/Model/Headermodelhindi';
import Headermodelenglish from '../../GlobalComponent/Model/Headermodelenglish';
function Header() {
  const hindiData = useGetHeaderHindiQuery()
  const englishData = useGetHeaderEnglishQuery()
  return (
    <>
      <div className="container-fluid">
       
          <div>
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              
            >
              <Tab eventKey="home" title="Header Section For Hindi">
                <div className="d-sm-flex align-items-center justify-content-between ">
                 
                </div>
                <div className='row '>
                  <div className=' col-md-12'>
                    <Table responsive="sm" hover>
                      <thead>
                        <tr>
                          <th>S.NO</th>
                          <th>Email Id</th>
                          <th>Phone Number</th>
                          <th>Icon Url</th>
                          <th>Button Text</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          hindiData?.isLoading ? <Loader /> :
                            <tr>
                              <td>1</td>
                              <td>{hindiData?.data?.header?.email}</td>
                              <td>{hindiData?.data?.header?.contact}</td>
                              <td><img style={{ width: "150px", height: "100px" }} src={hindiData?.data?.header?.iconUrl}></img></td>
                              <td>{hindiData?.data?.header?.buttonText}</td>
                              <td>{hindiData?.data?.header?.createdOn}</td>
                              <td><Headermodelhindi previousData={hindiData?.data} value={"headerhindi"} /></td>
                            </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Header Section For English">
                <div className="d-sm-flex align-items-center justify-content-between ">
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Table responsive="sm" hover>
                      <thead>
                        <tr>
                          <th>S.NO</th>
                          <th>Email Id</th>
                          <th>Phone Number</th>
                          <th>Icon Url</th>
                          <th>Button Text</th>
                          <th>Created On</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          englishData?.isLoading ? <Loader /> :
                            <tr>
                              <td>1</td>
                              <td>{englishData?.data?.header?.email}</td>
                              <td>{englishData?.data?.header?.contact}</td>
                              <td><img style={{ width: "150px", height: "100px" }} src={englishData?.data?.header?.iconUrl}></img></td>
                              <td>{englishData?.data?.header?.buttonText}</td>
                              <td>{englishData?.data?.header?.createdOn}</td>
                              <td><Headermodelenglish previousData={englishData?.data} value={"headerenglish"} /></td>
                            </tr>
                        }
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
      </div>


    </>
  );
}

export default Header;