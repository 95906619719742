import React from 'react';
import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { StoryEnglishVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { BiEditAlt } from 'react-icons/bi';
function Brandstorymodelenglish({previousData,value}) {
    const { englishModelShow, setEnglishModelShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setEnglishModelShow(true)} />
            <StoryEnglishVerticallyCenteredModal
            previousData={previousData}
            value={value}
                show={englishModelShow}
                onHide={() => setEnglishModelShow(false)}
            />
        </>
    );
}

export default Brandstorymodelenglish;