import React, { useState, useEffect, useMemo } from "react";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdSearch } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { useGetAllOrderQuery } from "./orderApiSlice";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import Loader from "../GlobalComponent/Loader/Loader";
import OrderEditStatusChanger from "./orderStatusChange";
import OrderInvoice from "./orderInvoice";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="search"
      placeholder="Search by Name, ID, Email"
      aria-label="Search Input"
      defaultValue={filterText}
      onChange={onFilter}
      className="w-25 form-control float-left"
      autoComplete="off"
    />
    {/* <button type="button" className="btn bg-black text-white mr-5" onClick={onClear} disabled>
     <MdSearch/>
    </button> */}
  </>
);

const PendingOrder = () => {
  const medusatoken = JSON.parse(localStorage.getItem("medusatoken"));
  // console.log("medusatoken key ---" + medusatoken)
  const [OrderData, setOrderData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [pending, setPending] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const filterProperties = ["email", "name", "id", "title", "phone", "city"];
  const resetComponent = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterText("");
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const DateFilterForm = ({ onDateChange }) => {
    const handleDateChange = () => {
      onDateChange(startDate, endDate);
    };
    const handleClear = () => {
      resetComponent();
    };

    return (
      <>
        <button
          type="button"
          className="btn bg-warning text-black ml-1"
          onClick={handleClear}
        >
          Reset Filter
        </button>
        {/* <button
          type="button"
          className="btn bg-black text-white ml-1"
          onClick={handleDateChange}
        >
          Apply
        </button> */}
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          className=" form-control"
        />

        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className=" form-control"
        />
      </>
    );
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${medusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}admin/orders?limit=100000000`,
          requestOptions
        );
        const result = await response.json();
        setOrderData(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  const filteredItems = OrderData?.orders?.filter((item) =>
    filterProperties.some(
      (property) =>
        new Date(item.created_at) > (startDate || new Date(0)) &&
        new Date(item.created_at) < (endDate || new Date()) &&
        item[property] &&
        item[property].toLowerCase().includes(filterText.toLowerCase())
    )
  );

  // console.log(filteredItems + "filteredItems");
  // console.log("Note----------" + JSON.stringify(OrderData));
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="SubFilterheader w-100 d-inline border p-3">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          className=""
        />
        <div className="dateSection d-flex flex-row-reverse w-75">
          <DateFilterForm onDateChange={handleDateChange} />{" "}
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  // Post Create Note
  const [noteValue, setNoteValue] = useState("");
  const [rowNoteValues, setRowNoteValues] = useState({});
  const [rowNotes, setRowNotes] = useState({});

  const setRowNoteValue = (orderId, value) => {
    setRowNoteValues((prevRowNoteValues) => ({
      ...prevRowNoteValues,
      [orderId]: value,
    }));
  };

  const createNote = async (orderId) => {
    const raw = JSON.stringify({
      resource_id: `${orderId}`,
      resource_type: "order",
      value: rowNoteValues[orderId],
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${medusatoken}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptionsNote = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}admin/notes`,
        requestOptionsNote
      );
      const result = await response.json();
      // setRowNotes((prevRowNotes) => ({
      //   ...prevRowNotes,
      //   [orderId]: result,
      // }));
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  // Get Create Note Response

  const getNotes = async () => {
    const requestOptionsRowNote = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}admin/notes`,
        requestOptionsRowNote
      );
      const result = await response.json();

      setRowNotes(result);
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  useEffect(() => {
    if (medusatoken) {
      getNotes();
    }
  }, [medusatoken]);

  // Data Table Order Section
  const columns = [
    {
      name: "Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
      grow: 3,
    },
    {
      name: "Name",
      grow: 2,
      selector: (row) => row.customer.first_name + " " + row.customer.last_name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      grow: 2,
    },
    {
      name: "Phone",
      // grow: 2,
      selector: (row) => row.shipping_address.phone,
    },
    {
      name: "Address",
      selector: (row) =>
        row.shipping_address.address_1 + "," + row.shipping_address.postal_code,
    },
    {
      name: "City",
      selector: (row) => row.shipping_address.city,
    },

    {
      name: "Product Name",
      grow: 3,
      selector: (row) =>
        row.items
          .map((name) =>
            name.title === "Bailkolhu Comic"
              ? name.title + " " + "Free"
              : name.title
          )
          .join(" , "),
    },
    {
      name: "Product Variation",
      selector: (row) => row.items.map((name) => name.variant.metadata.weight),
    },
    {
      name: "Qty",
      grow: 2,
      selector: (row) =>
        row.items
          .map((name) =>
            name.title === "Bailkolhu Comic"
              ? name.quantity + " " + "Comic "
              : name.quantity + " " + "Product"
          )
          .join(" , "),
    },
    {
      name: "Price",
      selector: (row) =>
        //  row.items[0].title !== "Bailkolhu Comic" ? (
        `₹${parseFloat(row?.payments[0]?.amount / 100).toFixed(2)}`,
      // ) : (
      //   <b className="text-danger">Free</b>
      // ),
    },
    {
      name: "Payment Status",
      sortable: true,
      selector: (row) => (
        <b className="text-success">
          {
            // (row.payment_status == "requires_action" ?
            row?.payment_status
            // : "demo")
          }
        </b>
      ),
    },
    {
      name: "Order Fulfillment Status",
      selector: (row) => row.status,
    },
    {
      name: "Invoice",
      selector: (row) => (
        <OrderInvoice medusatoken={medusatoken} orderId={row.id} />
      ),
    },
    // {
    //   name: "Action",
    //   grow: 2,
    //   selector: (row) =>
    //     row?.payments[0]?.data.status !== "awaiting" &&
    //     row?.payments[0]?.data.status !== "requires_action" &&
    //     row?.status !== "completed" ? (
    //       <>
    //         <OrderEditStatusChanger
    //           medusatoken={medusatoken}
    //           orderId={row.id}
    //         />
    //       </>
    //     ) : (
    //       <button
    //         className="btn text-wrap"
    //         disabled
    //         style={{ fontSize: "12px" }}
    //       >
    //         {row?.status !== "completed" ? "Payment Awating" : " Completed"}
    //       </button>
    //     ),
    // },
    {
      name: "Add Note",
      grow: 3,
      selector: (row) => (
        <div className="w-100 d-flex my-2">
          <textarea
            className="form-control"
            style={{ fontSize: "12px" }}
            placeholder="Enter note value"
            value={row.noteValue}
            onChange={(e) => setRowNoteValue(row.id, e.target.value)}
          />
          <button
            className="btn btn-primary mx-2"
            style={{ fontSize: "12px" }}
            onClick={() => createNote(row.id, noteValue)}
          >
            Create Note
          </button>
        </div>
      ),
    },
    {
      name: "Remark",
      grow: 3,
      selector: (row) => (
        <>
          <div className="w-100">
            {rowNotes.notes
              .filter((note) => row.id === note.resource_id)
              .map((note, index) => (
                <div
                  key={note.id}
                  className=" d-flex justify-content-between text-center  p-1 "
                >
                  <small
                    className="border-bottom mr-2"
                    style={{ width: "120px" }}
                  >
                    Note {index + 1} :{" "}
                  </small>
                  <b className="text-wrap text-start w-100 ">{note?.value}</b>
                  <span className="text-wrap text-end ">
                    {format(new Date(note?.created_at), "MM/dd/yyyy hh:mm:ss")}
                  </span>
                </div>
              ))}
          </div>
        </>
      ),
    },
  ];

  const downloadReport = () => {
    const csvContent = prepareCSVData(filteredItems);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, "order_report.csv");
    } else {
      link.href = URL.createObjectURL(blob);
      link.download = "order_report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const prepareCSVData = (data) => {
    // Convert your data into the required format for CSV
    const headers = [
      "Date",
      "Order ID",
      "Name",
      "Email",
      "Phone",
      "Product Name",
      "Product Weight",
      "Product Qty",
      "Price",
      "Payment Status",
      "Order Fulfillment Status",
      "Address",
    ];

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((item) => {
      const row = [
        format(new Date(item.created_at), "MM/dd/yyyy HH:mm:ss"),
        item.id,
        `${item.customer.first_name} ${item.customer.last_name}`,
        item.email,
        item.shipping_address.phone,
        ` Bail Kolhu Mustard Oil with Free Comic`,
        `${item.items
          .filter((name) => name.title === "Bail Kolhu Mustard Oil")
          .map((name) => name.variant.metadata.weight)
          .join(" , ")}`,
        // `${item.items.map((name) => name.variant.metadata.weight)}`,
        // item?.variant?.metadata !== undefined
        //   ? item.variant.metadata.weight
        //   : "mdnmfsn ",
        `${item.items
          .filter((name) => name.title === "Bail Kolhu Mustard Oil")
          .map((name) => name.quantity)
          .join(" , ")}`,
        `${parseFloat(item?.payments[0]?.amount / 100).toFixed(2)}`,
        `paid`,
        item.status,
        `${
          item.shipping_address.address_1 +
          item.shipping_address.city +
          item.shipping_address.postal_code
        }`,
      ];
      csvRows.push(row.join(","));
    });

    return csvRows.join("\n");
  };

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Order Data</h1>
        <button
          className="btn btn-sm btn-primary shadow-sm"
          onClick={downloadReport}
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Download
          Report
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            columns={columns}
            data={filteredItems}
            defaultSortAsc={true}
            progressPending={pending}
            // paginationTotalRows={filteredItems?.length}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrderId && (
            <OrderEditStatusChanger
              medusatoken={medusatoken}
              orderId={selectedOrderId}
              onClose={() => setShowModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PendingOrder;
