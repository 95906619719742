import React, { useState, useEffect, useMemo } from "react";
import swal from "sweetalert";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

function Pageredirect() {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [DirectedData, setDirectedData] = useState({});
  const [filterText, setFilterText] = useState("");
  const filterProperties = ["from", "to"];

  const [pageredirect, setredirect] = useState({
    from: "",
    to: "",
    statuscode: 301,
  });
  const [error, setError] = useState(null);

  // const isValidUrl = (url) => {
  //   try {
  //     new URL(url);
  //     return true;
  //   } catch (_) {
  //     return false;
  //   }
  // };
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  var GetrequestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const ViewData = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}api/admin/redirects`,
      GetrequestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDirectedData(result);

        // console.log(DirectedData + "DirectedData");
      })
      .catch((error) => {
        setError("Something went wrong.");
      });
  };

  useEffect(() => {
    ViewData();
  }, []);

  const DeleteRediretLink = (e, orderId) => {
    var DeleterequestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}api/admin/redirect/${orderId}`,
      DeleterequestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status && result.statusCode === 200) {
          swal({
            title: result.message,
            icon: "success",
            dangerMode: false,
          });
          ViewData();
        } else {
          swal({
            title: result.message,
            icon: "error",
            dangerMode: false,
          });
        }
      })
      .catch((error) => {
        setError("Something went wrong.");
      });
  };

  const handleredirect = (e, pageredirect) => {
    e.preventDefault();

    // if (!isValidUrl(pageredirect.from) ||!isValidUrl(pageredirect.to)) {
    //   setError("Please enter valid URLs.");
    //   return;
    // }

    var raw = JSON.stringify({
      from: pageredirect.from,
      to: pageredirect.to,
      statuscode: 301,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };
    console.log(raw + "raw");
    fetch(`${process.env.REACT_APP_BASE_URL}api/admin/redirect`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status && result.statusCode === 200) {
          swal({
            title: result.message,
            icon: "success",
            dangerMode: false,
            showConfirmButton: false,
            timer: 1500,
          });
          ViewData();
        } else {
          swal({
            title: result.message,
            icon: "error",
            dangerMode: true,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        setError("Something went wrong.");
      });
  };

  const columns = [
    // {
    //   name: "S. No.",
    //   selector: (row) => row.index,
    //   sortable: true,
    // },
    {
      name: "Create Date",
      selector: (row) => format(new Date(row.createdAt), "MM/dd/yyyy"),
      sortable: true,
    },

    {
      name: "From",
      selector: (row) => row.from,
      sortable: true,
      grow: 2,
    },
    {
      name: "To",
      sortable: true,
      selector: (row) => row.to,
    },
    {
      name: "StatusCode",
      selector: (row) => row.statusCode,
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      sortable: true,
    },
  ];

  const Tabledata = DirectedData?.redirects?.map((item, index) => ({
    i: index + 1,
    createdAt: item.createdAt,
    from: item.from,
    to: item.to,
    statusCode: item.statusCode,
    Action: (
      <button
        className="btn text-wrap text-danger"
        orderId={item._id}
        onClick={(e) => {
          DeleteRediretLink(e, item._id);
        }}
        style={{ fontSize: "12px" }}
      >
        Remove
      </button>
    ),
  }));

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };
    // Delete List
  }, [filterText]);

  return (
    <div className="container px-4 row ">
      <div className="col-lg-12 p-4 shadow bg-white ">
        <h2>Bail Kolhu Redirection Form</h2>
       
        <div className="formSection">
          <small style={{ textSize: "9" + "px" }}>
            *All fields are required. Please fill out all the details before
            submitting the form. In below format without{" "}
            <code>https://www.bailkolhu.com/en</code>
            <br></br>example: 'From field(old url){" "}
            <code>/Campaign/hi/rasodemeinmardhai</code> To Field(new url where
            you want to redirect) <code>/campaign/rasodemeinmardhai</code>'
          </small>
          <form className="mt-3">
            <input
              id=""
              type="text"
              name="from"
              placeholder="Enter From URL"
              aria-label="Search Input"
              onChange={(e) => {
                setredirect({
                  ...pageredirect,
                  from: e.target.value,
                });
              }}
              className="w-25 form-control float-left"
              autoComplete="off"
              required
            />
            <input
              id=""
              type="text"
              name="to"
              placeholder="Enter Redirect To URL"
              aria-label="Search Input"
              onChange={(e) => {
                setredirect({
                  ...pageredirect,
                  to: e.target.value,
                });
              }}
              className="w-25 form-control float-left mx-3"
              autoComplete="off"
              required
            />
            {/* {error && <p>{error}</p>} */}
            <button
              onClick={(e) => {
                handleredirect(e, pageredirect);
              }}
              className="btn bg-warning text-black "
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="col-lg-12 p-4 shadow bg-white mt-5">
        <h2>Bail Kolhu Redirectioned List</h2>
        <DataTable
          columns={columns}
          data={Tabledata}
          defaultSortAsc={true}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: "Rows per page:",
            rangeSeparatorText: "of",
            selectAllRowsItem: true,
            selectAllRowsItemText: "All",
          }}
        />
      </div>
    </div>
  );
}

export default Pageredirect;
