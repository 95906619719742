import React from "react";
import { useContext, useState, useEffect } from "react";
import swal from "sweetalert";
import { Accordion, Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import { useGetAllComicQuery,useUpdateComicMutation, } from "./campaignApiSlice";
import { getSlug } from "../GlobalComponent/global";
import UpdateEditor from "../GlobalComponent/Editor/Globaleditor/UpdateEditor";
import Updatemultipleinput from "../GlobalComponent/Multipleinput.js/Updatemultipleinput";
import Media from "../GlobalComponent/Media/Media";
export function CampaignVerticallyCenteredModal(props) {
  const { createCategoriesComic, setCreateCategoriesComic, updateTestimonial } =
    useContext(Auth);
  const [previousImage, setPreviousImage] = useState({
    sliderPreviousImage: props.previousData.sliderImage,
    sliderWebPreviousImage: props.previousData.mobileSliderImage,
    aboutRightImage: props.previousData.aboutPage.rightImage,
    aboutLeftImage: props.previousData.aboutPage.leftSliderImage,
    storyRightImage: props.previousData.storyPage.rightImage,
    storyLeftImage: props.previousData.storyPage.leftImage,
    mainImage: props.previousData.mainImage,
    backgroundImage: props.previousData.backgroundImage,
  });
  const [updateComic] = useUpdateComicMutation();
  const getAllComic = useGetAllComicQuery();
  const id = props?.previousData?._id;
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      //name:createCategoriesComic?.comicName?.length !== 0 ? createCategoriesComic?.comicName: props?.previousData?.name,
      name: {
        hindi:
          createCategoriesComic?.comicName?.forHindi?.length !== 0
            ? createCategoriesComic?.comicName?.forHindi
            : props?.previousData?.name?.hindi,
        english:
          createCategoriesComic.comicName.forEnglish.length !== 0
            ? createCategoriesComic?.comicName?.forEnglish
            : props?.previousData?.name?.english,
      },
      slug: getSlug(
        createCategoriesComic.comicName.forEnglish.length !== 0
          ? createCategoriesComic?.comicName?.forEnglish
          : props?.previousData?.name?.english
      ),
      sliderimage:
        createCategoriesComic?.siderImages?.length !== 0
          ? createCategoriesComic?.siderImages
          : previousImage?.sliderPreviousImage,

      mobilesliderimage:
        createCategoriesComic?.mobileSliderImage?.length !== 0
          ? createCategoriesComic?.mobileSliderImage
          : previousImage?.sliderWebPreviousImage,
      mainimage:
        createCategoriesComic?.mainImage?.length !== 0
          ? createCategoriesComic?.mainImage
          : previousImage?.mainImage,
      backgroundimage:
        createCategoriesComic.backgroundImage.length !== 0
          ? createCategoriesComic.backgroundImage
          : previousImage?.backgroundImage,

      aboutpage: {
        mainHeading:
          createCategoriesComic?.aboutMainHeading?.forHindi?.length !== 0
            ? createCategoriesComic?.aboutMainHeading?.forHindi
            : props?.previousData?.aboutPage?.mainHeading,
        subHeading:
          createCategoriesComic?.aboutsubHeading?.forHindi?.length !== 0
            ? createCategoriesComic?.aboutsubHeading?.forHindi
            : props?.previousData?.aboutPage?.subHeading,
        description:
          createCategoriesComic?.aboutDescription?.forHindi?.length !== 0
            ? createCategoriesComic?.aboutDescription?.forHindi
            : props?.previousData?.aboutPage?.description,
        rightImage:
          createCategoriesComic?.rightImage?.length !== 0
            ? createCategoriesComic?.rightImage
            : previousImage.aboutRightImage,
        leftSliderImage: [
          ...previousImage.aboutLeftImage,
          ...createCategoriesComic.leftSliderImage,
        ],
        buttonText:
          createCategoriesComic?.buttonText?.forHindi?.length !== 0
            ? createCategoriesComic?.buttonText?.forHindi
            : props?.previousData?.aboutPage?.buttonText,
        buttonLink:
          createCategoriesComic?.buttonLink?.length !== 0
            ? createCategoriesComic?.buttonLink
            : props?.previousData?.aboutPage?.buttonLink,
      },

      aboutpageenglish: {
        mainHeading:
          createCategoriesComic?.aboutMainHeading?.forEnglish?.length !== 0
            ? createCategoriesComic?.aboutMainHeading?.forEnglish
            : props?.previousData?.aboutPageEnglish?.mainHeading,

        subHeading:
          createCategoriesComic?.aboutsubHeading?.forEnglish?.length !== 0
            ? createCategoriesComic?.aboutsubHeading?.forEnglish
            : props?.previousData?.aboutPageEnglish?.subHeading,
        description:
          createCategoriesComic?.aboutDescription?.forEnglish?.length !== 0
            ? createCategoriesComic?.aboutDescription?.forEnglish
            : props?.previousData?.aboutPageEnglish?.description,
        buttonText:
          createCategoriesComic?.buttonText?.forEnglish?.length !== 0
            ? createCategoriesComic?.buttonText?.forEnglish
            : props?.previousData?.aboutPageEnglish?.buttonText,
      },
      storypageenglish: {
        mainPage:
          createCategoriesComic?.storyMainHeading?.forEnglish?.length !== 0
            ? createCategoriesComic?.storyMainHeading.forEnglish
            : props?.previousData?.storyPageEnglish?.mainPage,
        subHeading:
          createCategoriesComic?.storySubHeading?.forEnglish?.length !== 0
            ? createCategoriesComic?.storySubHeading?.forEnglish
            : props?.previousData?.storyPageEnglish?.subHeading,
        description:
          createCategoriesComic?.storyDescription?.forEnglish?.length !== 0
            ? createCategoriesComic?.storyDescription?.forEnglish
            : props?.previousData?.storyPageEnglish?.description,
        buttonText:
          createCategoriesComic?.storyButtonText?.forEnglish?.length !== 0
            ? createCategoriesComic?.storyButtonText?.forEnglish
            : props?.previousData?.storyPageEnglish?.buttonText,
      },
      storypage: {
        mainPage:
          createCategoriesComic?.storyMainHeading?.forHindi?.length !== 0
            ? createCategoriesComic?.storyMainHeading?.forHindi
            : props?.previousData?.storyPage?.mainPage,
        subHeading:
          createCategoriesComic?.storySubHeading?.forHindi?.length !== 0
            ? createCategoriesComic?.storySubHeading?.forHindi
            : props?.previousData?.storyPage?.subHeading,
        description:
          createCategoriesComic?.storyDescription?.forHindi?.length !== 0
            ? createCategoriesComic?.storyDescription?.forHindi
            : props?.previousData?.storyPage?.description,
        rightImage:
          createCategoriesComic?.storyRightImage?.length !== 0
            ? createCategoriesComic?.storyRightImage
            : previousImage.storyRightImage,
        leftImage:
          createCategoriesComic?.storyLeftImage?.length !== 0
            ? createCategoriesComic.storyLeftImage
            : previousImage.storyLeftImage,
        buttonText:
          createCategoriesComic?.storyButtonText?.forHindi?.length !== 0
            ? createCategoriesComic?.storyButtonText?.forHindi
            : props?.previousData?.storyPage?.buttonText,
        buttonLink:
          createCategoriesComic?.buttonStoryLink?.length !== 0
            ? createCategoriesComic?.buttonStoryLink
            : props?.previousData?.storyPage?.buttonLink,
      },

      testimonials: {
        mainHeading:
          createCategoriesComic?.campaignHeading?.length !== 0
            ? createCategoriesComic?.campaignHeading
            : props?.previousData?.testimonials?.mainHeading,
        listing: updateTestimonial.listing,
      },
    };

    updateComic({ raw, id })
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllComic.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setCreateCategoriesComic({
          ...createCategoriesComic,
          siderImages: "",
          rightImage: "",
          leftSliderImage: "",
          storyRightImage: "",
          storyLeftImage: "",
          mainImage: "",
          backgroundImage: "",
          comicSlug: "",
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage({
      ...previousImage,
      sliderPreviousImage: props.previousData.sliderImage,
    });
  }, [props.previousData]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            {/* comic name */}
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Comic name</Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For english">
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Comic Name
                          </Form.Label>
                        </div>
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Slug of Your Comic
                          </Form.Label>
                        </div>
                      </div>

                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Control
                            defaultValue={props?.previousData?.name?.english}
                            onChange={(e) =>
                              setCreateCategoriesComic({
                                ...createCategoriesComic,
                                comicName: {
                                  ...createCategoriesComic.comicName,
                                  forEnglish: e.target.value,
                                },
                              })
                            }
                            className="w-100 "
                            type="text"
                            placeholder="Comic Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <Form.Control
                            disabled
                            defaultValue={props?.previousData?.slug}
                            // onChange={(e) => setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: e.target.value })}
                            className="w-100"
                            type="text"
                            placeholder="Enter Slug"
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Comic Name
                          </Form.Label>
                        </div>
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Slug of Your Comic
                          </Form.Label>
                        </div>
                      </div>

                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Control
                            defaultValue={props?.previousData?.name?.hindi}
                            onChange={(e) =>
                              setCreateCategoriesComic({
                                ...createCategoriesComic,
                                comicName: {
                                  ...createCategoriesComic.comicName,
                                  forHindi: e.target.value,
                                },
                              })
                            }
                            className="w-100 "
                            type="text"
                            placeholder="Comic Name"
                          />
                        </div>
                        <div className="col-md-6">
                          <Form.Control
                            disabled
                            defaultValue={props?.previousData?.slug}
                            // onChange={(e) => setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: e.target.value })}
                            className="w-100"
                            type="text"
                            placeholder="Enter Slug"
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* main image and background iamge */}
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Main and background image</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex row">
                    <div className="col-md-6">
                      <Form.Label className="font-weight-bold">
                        Main Image
                      </Form.Label>
                    </div>
                    <div className="col-md-6">
                      <Form.Label className="font-weight-bold">
                        Background Image
                      </Form.Label>
                    </div>
                  </div>

                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Media value={"campaignmainImage"} />
                        <div className="mt-3">
                          <ul>
                            <li>
                              <img
                                alt="slider"
                                src={
                                  createCategoriesComic.mainImage.length !== 0
                                    ? createCategoriesComic.mainImage
                                    : previousImage.mainImage
                                }
                                className="slider-image mt-3"
                              ></img>
                              {createCategoriesComic.mainImage.length !== 0 ? (
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setCreateCategoriesComic({
                                      ...createCategoriesComic,
                                      mainImage: "",
                                    });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Media value={"campaignbackgroundimage"} />
                        <div className="mt-3">
                          <ul>
                            <li>
                              <img
                                alt="slider"
                                src={
                                  createCategoriesComic.backgroundImage
                                    .length !== 0
                                    ? createCategoriesComic.backgroundImage
                                    : previousImage.backgroundImage
                                }
                                className="slider-image mt-3"
                              ></img>
                              {createCategoriesComic.backgroundImage.length !==
                              0 ? (
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setCreateCategoriesComic({
                                      ...createCategoriesComic,
                                      backgroundImage: "",
                                    });
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* for Slider Menu */}

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Slider Image</Accordion.Header>
                <Accordion.Body>
                  <Form.Label className="h5 font-weight-bold text-center mt-4">
                    Slider Menu-:
                  </Form.Label>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Update Your Web Sliders Images &nbsp;
                        </Form.Label>
                        <Media value={"campaignsliderimage"} />
                      </div>

                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Update Your Mobile Sliders Images
                        </Form.Label>
                        <Media value={"campaignslidermobileimage"} />
                      </div>
                    </div>
                  </div>

                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <div className="mt-3">
                          <ul>
                            <li>
                              {createCategoriesComic.siderImages.length !==
                              0 ? (
                                <>
                                  {createCategoriesComic.siderImages.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          {/* <RxCrossCircled
                                    className="delteicon"
                                    onClick={() => {
                                      setCreateCategoriesComic({
                                        ...createCategoriesComic,
                                        siderImages:
                                          createCategoriesComic.siderImages.filter(
                                            function (val) {
                                              return val !== item;
                                            }
                                          ),
                                      });
                                    }}
                                  /> */}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {createCategoriesComic?.siderImages?.length !==
                              0 ? (
                                ""
                              ) : (
                                <>
                                  {previousImage?.sliderPreviousImage?.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          {/* <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setPreviousImage({
                                    ...previousImage,
                                    sliderPreviousImage:
                                      previousImage.sliderPreviousImage.filter(
                                        function (val) {
                                          return val !== item;
                                        }
                                      ),
                                  });
                                }}
                              /> */}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mt-3">
                          <ul>
                            <li>
                              {createCategoriesComic.mobileSliderImage
                                .length !== 0 ? (
                                <>
                                  {createCategoriesComic.mobileSliderImage.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          {/* <RxCrossCircled
                                      className="delteicon"
                                      onClick={() => {
                                        setCreateCategoriesComic({
                                          ...createCategoriesComic,
                                          mobileSliderImage:
                                            createCategoriesComic.mobileSliderImage.filter(
                                              function (val) {
                                                return val !== item;
                                              }
                                            ),
                                        });
                                      }}
                                    /> */}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {/* if new image will uploaded then the previous image will be gone */}
                              {createCategoriesComic.mobileSliderImage.length !==
                              0 ? (
                                ""
                              ) : (
                                <>
                                  {previousImage?.sliderWebPreviousImage?.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          {/* <RxCrossCircled
                                      className="delteicon"
                                      onClick={() => {
                                        setPreviousImage({
                                          ...previousImage,
                                          sliderWebPreviousImage:
                                            previousImage.sliderWebPreviousImage.filter(
                                              function (val) {
                                                return val !== item;
                                              }
                                            ),
                                        });
                                      }}
                                    /> */}
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* for About Page */}
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Content</Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For english">
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Main Heading
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Sub Heading
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPageEnglish
                                    ?.mainHeading
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutMainHeading: {
                                      ...createCategoriesComic.aboutMainHeading,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Main Heading"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPageEnglish
                                    ?.subHeading
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutsubHeading: {
                                      ...createCategoriesComic.aboutsubHeading,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder="Sub Heading"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPageEnglish
                                    ?.buttonText
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonText: {
                                      ...createCategoriesComic.buttonText,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* description row */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <UpdateEditor
                                value={
                                  props?.previousData?.aboutPageEnglish
                                    ?.description
                                }
                                from="aboutenglish"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Main Heading
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Sub Heading
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPage?.mainHeading
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutMainHeading: {
                                      ...createCategoriesComic.aboutMainHeading,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Main Heading"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPage?.subHeading
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutsubHeading: {
                                      ...createCategoriesComic.aboutsubHeading,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder="Sub Heading"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* button text */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.aboutPage?.buttonText
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonText: {
                                      ...createCategoriesComic.buttonText,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* description row */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <UpdateEditor
                                value={
                                  props?.previousData?.aboutPage?.description
                                }
                                from="abouthindi"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                  {/* right and left slider */}
                  <div>
                    <div>
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Left Slider Images
                          </Form.Label>
                        </div>

                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Button Link
                          </Form.Label>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <Form.Label >Enter Your Email</Form.Label> */}
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Media value={"campaignaboutLeftimage"} />
                          <div className="mt-3">
                            <ul>
                              <li>
                                {createCategoriesComic.leftSliderImage
                                  .length !== 0 ? (
                                  <>
                                    {createCategoriesComic.leftSliderImage.map(
                                      (item) => {
                                        return (
                                          <>
                                            <img
                                              alt="slider"
                                              src={item}
                                              className="slider-image mt-3"
                                            ></img>
                                            <RxCrossCircled
                                              className="delteicon"
                                              onClick={() => {
                                                setCreateCategoriesComic({
                                                  ...createCategoriesComic,
                                                  leftSliderImage:
                                                    createCategoriesComic.leftSliderImage.filter(
                                                      function (val) {
                                                        return val !== item;
                                                      }
                                                    ),
                                                });
                                              }}
                                            />
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {previousImage?.aboutLeftImage?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        alt="slider"
                                        src={item}
                                        className="slider-image mt-3"
                                      ></img>
                                      <RxCrossCircled
                                        className="delteicon"
                                        onClick={() => {
                                          setPreviousImage({
                                            ...previousImage,
                                            aboutLeftImage:
                                              previousImage.aboutLeftImage.filter(
                                                function (val) {
                                                  return val !== item;
                                                }
                                              ),
                                          });
                                        }}
                                      />
                                    </>
                                  );
                                })}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Form.Control
                            defaultValue={
                              props?.previousData?.aboutPage?.buttonLink
                            }
                            onChange={(e) =>
                              setCreateCategoriesComic({
                                ...createCategoriesComic,
                                buttonLink: e.target.value,
                              })
                            }
                            className="w-100"
                            type="text"
                            placeholder=" Button Url Where You want to Navigate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Story page  */}

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Story content</Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For english">
                      {/* 1st row of story  */}
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Your Main Heading{" "}
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Sub Heading{" "}
                            </Form.Label>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              defaultValue={
                                props?.previousData?.storyPageEnglish?.mainPage
                              }
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storyMainHeading: {
                                    ...createCategoriesComic.storyMainHeading,
                                    forEnglish: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Enter your main  haeding "
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              defaultValue={
                                props?.previousData?.storyPageEnglish
                                  ?.subHeading
                              }
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storySubHeading: {
                                    ...createCategoriesComic.storySubHeading,
                                    forEnglish: e.target.value,
                                  },
                                })
                              }
                              className="w-100"
                              type="text"
                              placeholder="Enter your sub heading "
                            />
                          </div>
                        </div>
                      </div>
                      {/* button text */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.storyPageEnglish
                                    ?.buttonText
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    storyButtonText: {
                                      ...createCategoriesComic.storyButtonText,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* story description */}

                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Story Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <UpdateEditor
                                value={
                                  props?.previousData?.storyPageEnglish
                                    ?.description
                                }
                                from="storyenglish"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      {/* 1st row of story  */}
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Your Main Heading{" "}
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Sub Heading{" "}
                            </Form.Label>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              defaultValue={
                                props?.previousData?.storyPage?.mainPage
                              }
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storyMainHeading: {
                                    ...createCategoriesComic.storyMainHeading,
                                    forHindi: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Enter your main  haeding "
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              defaultValue={
                                props?.previousData?.storyPage?.subHeading
                              }
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storySubHeading: {
                                    ...createCategoriesComic.storySubHeading,
                                    forHindi: e.target.value,
                                  },
                                })
                              }
                              className="w-100"
                              type="text"
                              placeholder="Enter your sub heading "
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                defaultValue={
                                  props?.previousData?.storyPage?.buttonText
                                }
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    storyButtonText: {
                                      ...createCategoriesComic.storyButtonText,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* story description */}

                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Story Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <UpdateEditor
                                value={
                                  props?.previousData?.storyPage?.description
                                }
                                from="storyhindi"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                  <div>
                    <div>
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Background Image
                          </Form.Label>
                        </div>
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Left side Images
                          </Form.Label>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <Form.Label >Enter Your Email</Form.Label> */}
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Media value={"storyRightImage"} />
                          <div className="mt-3">
                            <ul>
                              <li>
                                <img
                                  alt="slider"
                                  src={
                                    createCategoriesComic.storyRightImage
                                      .length !== 0
                                      ? createCategoriesComic.storyRightImage
                                      : previousImage.storyRightImage === ""
                                      ? "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                      : previousImage.storyRightImage
                                  }
                                  className="slider-image mt-3"
                                ></img>
                                {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                {createCategoriesComic.storyRightImage
                                  .length !== 0 ? (
                                  <RxCrossCircled
                                    className="delteicon"
                                    onClick={() => {
                                      setCreateCategoriesComic({
                                        ...createCategoriesComic,
                                        storyRightImage: "",
                                      });
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* {
                            previousImage.storyRightImage.length !== 0 ? <>
                              <img alt='slider' src={previousImage.storyRightImage} className='slider-image mt-3'></img>
                              <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage({ ...previousImage, storyRightImage: "" }) }} />
                            </> : ""
                          } */}
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <Media value={"storyLeftImage"} />
                          <div className="mt-3">
                            <ul>
                              <li>
                                <img
                                  alt="slider"
                                  src={
                                    createCategoriesComic.storyLeftImage
                                      .length !== 0
                                      ? createCategoriesComic.storyLeftImage
                                      : previousImage.storyLeftImage
                                  }
                                  className="slider-image mt-3"
                                ></img>
                                {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                {createCategoriesComic.storyLeftImage.length !==
                                0 ? (
                                  <RxCrossCircled
                                    className="delteicon"
                                    onClick={() => {
                                      setCreateCategoriesComic({
                                        ...createCategoriesComic,
                                        storyLeftImage: "",
                                      });
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {/* { 
                            previousImage.storyLeftImage.length !== 0  ? <>
                              <img alt='slider' src={previousImage.storyLeftImage} className='slider-image mt-3'></img>
                              <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage({ ...previousImage, storyLeftImage: "" }) }} />
                            </> : ""
                          } */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Label className="font-weight-bold">
                            Enter Your Button Link
                          </Form.Label>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <Form.Label >Enter Your Email</Form.Label> */}
                      <div className="d-flex row">
                        <div className="col-md-6">
                          <Form.Control
                            defaultValue={
                              props?.previousData?.storyPage?.buttonLink
                            }
                            onChange={(e) =>
                              setCreateCategoriesComic({
                                ...createCategoriesComic,
                                buttonStoryLink: e.target.value,
                              })
                            }
                            className="w-100"
                            type="text"
                            placeholder=" Button Url Where You want to Navigate"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* Testimonials page */}
            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Testimonials</Accordion.Header>
                <Accordion.Body>
                  <Form.Label className="h5 font-weight-bold text-center mt-4">
                    Testimonials-:
                  </Form.Label>
                  <Updatemultipleinput
                    value={props?.previousData?.testimonials}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update Comic
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
