import React from "react";
import { Table } from "react-bootstrap";
import { MdFileDownloadDone, MdDeleteOutline } from "react-icons/md";
import Campaignaboutmodel from "../../GlobalComponent/Model/Brandcampaignmodel";

function Brandcampaignthumbnail() {
  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Brand Campaign Section</h1>
          <Campaignaboutmodel />
        </div>
        <div className="row">
          <div className="col-md-12">
            <Table responsive="sm" hover className="w-100 table">
              <thead className="bg-black text-white">
                <tr>
                  <th>S.NO</th>
                  <th>Campaign Name</th>
                  <th>Slug</th>
                  <th>Background Image</th>
                  <th>Small Campaign Image</th>
                  <th>Created By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>
                    <MdFileDownloadDone className="h3" />
                    <MdDeleteOutline className="h3 ml-2" />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>
                    <MdFileDownloadDone className="h3" />
                    <MdDeleteOutline className="h3 ml-2" />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>
                    <MdFileDownloadDone className="h3" />
                    <MdDeleteOutline className="h3 ml-2" />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brandcampaignthumbnail;
