import {  updatePhilosophyEndPoints ,philosophyEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const brandPhilosophyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrandPhilosophyHindi: builder.query({
            query: () => ({
                url: `${philosophyEndPoints}/hindi`,
                method: 'GET'
            })
        }),
        getBrandPhilosophyEnglish: builder.query({
            query: () => ({
                url: `${philosophyEndPoints}/english`,
                method: 'GET'
            })
        }),
        updatePhilosophy: builder.mutation({
            query: (newHeader) => {
                return {
                    url: updatePhilosophyEndPoints,
                    method: 'POST',
                    body: newHeader,
                }
            }
        })
    })
})
export const {useGetBrandPhilosophyEnglishQuery,useGetBrandPhilosophyHindiQuery,useUpdatePhilosophyMutation} =brandPhilosophyApiSlice