  //old setup 
  import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer  from '../../features/auth/authSlice'

export const store = configureStore({
    reducer: {
     [apiSlice.reducerPath]:apiSlice.reducer,
     auth:authReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
    devTools:true
  })
            //new setup
// import { configureStore } from "@reduxjs/toolkit";
// import { apiSlice } from "./api/apiSlice";
// import authReducer  from '../../features/auth/authSlice'
// import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore } from 'redux-persist';
// import { getDefaultMiddleware } from '@reduxjs/toolkit';

// const persistConfig = {
//   key: 'root',
//   storage,
// }
// const persistedReducer = persistReducer(persistConfig, authReducer)

// export const store = configureStore({
//     reducer: {
//       persistedReducer,
//      auth:authReducer
//     },
//     middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware().concat(apiSlice.middleware),
//     devTools:true
//   })

//   export const persistor = persistStore(store)

