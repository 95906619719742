import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';

import { BiEditAlt } from 'react-icons/bi';
import { ProductEnglishVerticallyCenteredModal } from '../../Home/Bailkholuproduct/Updateproduct';

function Brandproductmodelenglish({ previousData, value }) {
    const { englishModelShow, setEnglishModelShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setEnglishModelShow(true)} />
            <ProductEnglishVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={englishModelShow}
                onHide={() => setEnglishModelShow(false)}
            />
        </>
    );
}

export default Brandproductmodelenglish;