import React from 'react';
import Brandaboutoilmodel from '../../GlobalComponent/Model/Brandaboutoilmodelhindi';
import { Table } from 'react-bootstrap';
import { MdFileDownloadDone, MdDeleteOutline, MdOutlineFolderDelete, MdDeleteSweep, MdDelete } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDeleteAboutOilMutation, useGetAllAboutOilEnglishQuery, useGetAllAboutOilHindiQuery } from './brandAboutOilSplice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandaboutmodelhindi from '../../GlobalComponent/Model/Brandaboutmodelhindi';
import Brandaboutoilmodelhindi from '../../GlobalComponent/Model/Brandaboutoilmodelhindi';
import Brandaboutoilmodelenglish from '../../GlobalComponent/Model/Brandaboutoilmodelenglish';
import Brandaboutoiladdmodel from '../../GlobalComponent/Model/Brandaboutoiladdmodel';
import { useContext } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
function Brandaboutoil() {
  const { Swal } = useContext(Auth)
  const aboutOilHindi = useGetAllAboutOilHindiQuery()
  const aboutOilEnglish = useGetAllAboutOilEnglishQuery()
  const [delteProductOil] = useDeleteAboutOilMutation()
  const handleDelete = (id,value) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        const raw = {
          "cardid": id
        }
        delteProductOil({raw,lang:value}).unwrap().then((data) => {
          aboutOilHindi.refetch()
          aboutOilEnglish.refetch()
        }).catch((error) => {
          Swal.fire({
            
            text: "Something went wrong |",
            icon: "error",
            
          });
        })
      }
    })
  }
  return (
    <>
      <div className="container-fluid">
      <div className="w-100 text-right">
          <Brandaboutoiladdmodel />
          </div>
        <div>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="behind making the legend Hindi">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Oil Section For Hindi</h1>
              </div> */}
              <div className='row '>
                <div className=' col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th >Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        aboutOilHindi?.isLoading ? <Loader /> :
                          <>
                            {
                              aboutOilHindi?.data?.about?.cards?.map((item, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{item?.heading}</td>
                                      <td>{item?.description}</td>
                                      <td><img style={{ width: "150px", height: "100px" }} src={item?.imageUrl}></img></td>
                                      <td><Brandaboutoilmodelhindi previousData={item} value={"productoilhindi"} /></td>
                                      <td><MdDelete onClick={() => { handleDelete(item._id,'hindi') }} className='h4' /></td>
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="behind making the legend">
              {/* <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Oil Section For English</h1>
              </div> */}
              <div className='row'>
                <div className='col-md-12'>
                <Table responsive="sm" hover className='w-100 table'>
                   <thead className='bg-black text-white'>
                      <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>Description</th>
                        <th>Image</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        aboutOilEnglish?.isLoading ? <Loader /> :
                          <>
                            {
                              aboutOilEnglish?.data?.about?.cards?.map((item, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td>{i + 1}</td>
                                      <td>{item?.heading}</td>
                                      <td>{item?.description}</td>
                                      <td><img style={{ width: "150px", height: "100px" }} src={item?.imageUrl}></img></td>
                                      <td><Brandaboutoilmodelenglish previousData={item} value={"productoilenglish"} /></td>
                                      <td><MdDelete onClick={() => { handleDelete(item._id,'english') }} className='h4' /></td>
                                    </tr>
                                  </>
                                )
                              })
                            }
                          </>

                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandaboutoil;