import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import {  CampaignVerticallyCenteredModal } from '../../Modelhelper/Modelhelper';
function Campaignaboutmodel() {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Add New Campaign
            </Button>
            <CampaignVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Campaignaboutmodel;