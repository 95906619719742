import React, { useContext, useEffect, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { Auth } from '../../../../contextapi/Authcontext/Authcontext';
const parse = require('html-react-parser');
Quill.register('modules/imageResize', ImageResize);

function Campabouteditor({value}) {
    console.log(value,"++++++++++++++++++++")
 const {createCategoriesComic, setCreateCategoriesComic}=useContext(Auth)
    const onhandleChange = (html) => {
        setCreateCategoriesComic({...createCategoriesComic, aboutDescription: html })
    }
    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
            ],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };
    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video'
    ];
    return (
        <>
            <ReactQuill
                style={{ height: 'auto' }}
                theme='snow'
                defaultValue={value}
                onChange={(html) => { onhandleChange(html) }}
                // value={createCategoriesComic.aboutDescription}
                modules={modules}
                formats={formats}
            />
        </>
    );
}

export default Campabouteditor;


// const { editorState, setEditorState } = useContext(Auth)
//     console.log(editorState.editorHtml)

//     const modules = {
//         toolbar: [
//             [{ header: '1' }, { header: '2' }, { font: [] }],
//             [{ size: [] }],
//             ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//             [
//                 { list: 'ordered' },
//                 { list: 'bullet' },
//                 { indent: '-1' },
//                 { indent: '+1' }
//             ],
//             ['link', 'image', 'video'],
//             ['clean']
//         ],
//         clipboard: {
//             // toggle to add extra line breaks when pasting HTML:
//             matchVisual: false
//         },
//         imageResize: {
//             parchment: Quill.import('parchment'),
//             modules: ['Resize', 'DisplaySize']
//         }
//     };
//     const formats = [
//         'header',
//         'font',
//         'size',
//         'bold',
//         'italic',
//         'underline',
//         'strike',
//         'blockquote',
//         'list',
//         'bullet',
//         'indent',
//         'link',
//         'image',
//         'video'
//     ];
//     return (
//         <>
//             <ReactQuill
//                 style={{ height: 'auto' }}
//                 theme='snow'
//                 // defaultValue={deafult}
//                 onChange={(html) => { setEditorState({ editorHtml: html }) }}
//                 value={editorState.editorHtml}
//                 modules={modules}
//                 formats={formats}
//             />
//         </>
//     );