import {  footerEndPoints,updateFooterEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const footerApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFooter: builder.query({
            query: () => ({
                url: footerEndPoints,
                method: 'GET'
            })
        }),
        
        updateFooter: builder.mutation({
            query: ({raw,id}) => {
                return {
                    url: `${updateFooterEndPoints}/${id}` ,
                    method: 'PATCH',
                    body: raw,
                }
            }
        })
    })
})
export const {useGetFooterQuery,useUpdateFooterMutation} =footerApiSlice