import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import {BlogAddEnglishVerticallyCenteredModal } from '../../Modelhelper/Modelhelper';
function Brandblogmodeladdenglish({value}) {
    const [ modalShow, setModalShow]=useState(false)
    const {editorState, setEditorState}=useContext(Auth)
    return (
        <>
            <Button variant="primary" onClick={() => {setModalShow(true) 
                setEditorState({...editorState,editorHtml:""})}}>
                Add English Blog
            </Button>
            <BlogAddEnglishVerticallyCenteredModal
            value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}
export default Brandblogmodeladdenglish;