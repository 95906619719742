import { getfreecomic,getcontactform,jointhejourney, comicuser, regDataUploadEndPoint, schoollibDataEndPoint , AgentListEndPoint} from "../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../app/api/apiSlice";
export const mediaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllContactList: builder.query({
            query: () => ({
                url: getcontactform,
                method: 'GET'
            })
        }),
        getAllJoinTheJourney: builder.query({
            query: () => ({
                url: jointhejourney,
                method: 'GET'
            })
        }),
        getAllFreeComic: builder.query({
            query: () => ({
                url: getfreecomic,
                method: 'GET'
            })
        }),
        getAllComicUser: builder.query({
            query: () => ({
                url: comicuser,
                method: 'GET'
            })
        }),
        getAllregDataUpload: builder.query({
            query: () => ({
                url: regDataUploadEndPoint,
                method: 'GET'
            })
        }),
     getAllschoollib: builder.query({
            query: () => ({
                url: schoollibDataEndPoint,
                method: 'GET'
            })
        }),
     getAllAgentList: builder.query({
            query: () => ({
                url: AgentListEndPoint,
                method: 'GET'
            })
        }),
   
        // getAllFreeComic: builder.query({
        //     query: () => ({
        //         url: mediaEndPoint,
        //         method: 'GET'
        //     })
        // })

    })
})
export const { useGetAllContactListQuery, useGetAllFreeComicQuery, useGetAllJoinTheJourneyQuery, useGetAllComicUserQuery, useGetAllregDataUploadQuery, useGetAllschoollibQuery, useGetAllAgentListQuery} = mediaApiSlice