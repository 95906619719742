import React from 'react';
import Campaignmodel from '../GlobalComponent/Model/Campaignmodel';
import { Table } from 'react-bootstrap';
import { useDeleteComicMutation, useGetAllComicQuery } from './campaignApiSlice';
import { MdDelete } from 'react-icons/md';
import BrandUpdateCampaignmodel from '../GlobalComponent/Model/BrandUpdateCampaignmodel';
import Loader from '../GlobalComponent/Loader/Loader';
import { useContext } from 'react';
import { Auth } from '../../contextapi/Authcontext/Authcontext';
function Campaign() {
  const { Swal } = useContext(Auth)
  const getAllComic = useGetAllComicQuery() 
  const [deleteComic] = useDeleteComicMutation()
  const deleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        deleteComic(id).unwrap().then((data) => {
          getAllComic.refetch()
        }).catch((error) => {
        alert(error)
        })
      }
    })
  }
  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Brand Campaign Section</h1>
          <Campaignmodel />
        </div>
        <div className="row">
          <div className="col-md-12">
            <Table responsive="sm">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Comic Name</th>
                  <th>Slug</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getAllComic.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {getAllComic?.data?.campaigns.map((item, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.name.hindi}</td>
                            <td>{item.slug}</td>
                            <td>
                              <BrandUpdateCampaignmodel
                                previousData={item}
                                className="h3 "
                              />
                              <MdDelete
                                onClick={() => {
                                  deleteHandler(item._id, item);
                                }}
                                className="h4"
                              />
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                )}
                {getAllComic?.data?.campaigns.length === 0 ? <p>No Campaign Found</p>:""}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Campaign;