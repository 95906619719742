import React from 'react';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { AddCampaignCenteredModal } from '../../Campaign/Addcampaign';

function Campaignmodel() {
    const [modalShow, setModalShow]=useState(false)
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Add New Comic
            </Button>
            <AddCampaignCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Campaignmodel;