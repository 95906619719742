import {   tvcEndPoints ,addTvcEndPoints,deleteTvcEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const brandTvcApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTvc: builder.query({
            query: () => ({
                url: tvcEndPoints,
                method: 'GET'
            })
        }),
        deleteTvc: builder.mutation({
            query: (id) => {
                return {
                    url: `${deleteTvcEndPoints}/${id}`,
                    method: 'DELETE', 
                }
            }
        }),
        updateTvc: builder.mutation({
            query: (raw) => {
                return {
                    url: tvcEndPoints,
                    method: 'PATCH',
                    body: raw,
                }
            }
        }),
        addTvc: builder.mutation({
            query: (raw) => {
                return {
                    url: addTvcEndPoints,
                    method: 'POST',
                    body: raw,
                }
            }
        })
    })
})
export const {useGetTvcQuery,useAddTvcMutation ,useUpdateTvcMutation,useDeleteTvcMutation} =brandTvcApiSlice