import {   getStoryEndPoints,updateStoryEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const brandStoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrandStoryHindi: builder.query({
            query: () => ({
                url: `${getStoryEndPoints}/hindi`,
                method: 'GET'
            })
        }),
        getBrandStoryEnglish: builder.query({
            query: () => ({
                url: `${getStoryEndPoints}/english`,
                method: 'GET'
            })
        }),
        updateStory: builder.mutation({
            query: ({raw,lang}) => {
                return {
                    url: `${updateStoryEndPoints}/${lang}`,
                    method: 'PATCH',
                    body: raw,
                }
            }
        })
    })
})
export const {useGetBrandStoryEnglishQuery,useGetBrandStoryHindiQuery,useUpdateStoryMutation} =brandStoryApiSlice