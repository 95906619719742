import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../../images/resetpasswordimage.png'
import { Auth } from '../../contextapi/Authcontext/Authcontext';
import { useResetpasswordMutation } from '../../features/auth/authApiSlice';
import swal from 'sweetalert';
function Changepassword() {
  const {loader,setLoader}=useContext(Auth)
  const navigate = useNavigate()
  const Swal = require('sweetalert2')
  const [changePassword, requestInfo] = useResetpasswordMutation()
  const [state, setState] = useState({
    oldpass: "",
    newpass: ""
  })
  const handleChangePassword = (e) => {
    e.preventDefault()
    if (state.oldpass.length !== 0 && state.newpass.length !== 0) {
      changePassword({
        "oldpassword": state.oldpass,
        "password": state.newpass
      }).unwrap().then((data) => {
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        })
        setState({...state,oldpass:"",newpass:""})
      }).catch((error) => {
        swal({
          title: error.message,
          icon: "success",
          dangerMode: true,
        })
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please Field Your All Details',
      })
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-lg-6"  >
          <img src={image} alt="Girl in a jacket" className='img-fluid' />
        </div>
        <div className="col-lg-6">
          <div className="p-5">
            <div className="text-center">
              <p className="h4 text-gray-900 mb-2">
                Change Your Password
              </p>

            </div>
            <form className="user">
              <div className="form-group">
                <p >
                  Old Password
                </p>
                <input
                  type="email"
                  className="form-control form-control-user"
                  id="exampleInputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Old Password..."
                  onChange={(e) => setState({ ...state, oldpass: e.target.value })}

                />

                <p className='mt-2' >
                  New Password
                </p>
                <input
                  type="email"
                  className="form-control form-control-user"
                  id="exampleInputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your New Password..."
                  onChange={(e) => setState({ ...state, newpass: e.target.value })}
                />
              </div>
              <button
                onClick={(e) => { handleChangePassword(e) }}
                className="btn btn-primary btn-user btn-block"
              >
                Reset Password
              </button>
            </form>
            <hr />
            <div className="text-center">

            </div>
            {/* <div className="text-center">
              <a href='/' onClick={() => { navigate('/') }} className="small">
                Already have an account? Login!
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>

  );
}

export default Changepassword;