import {  updateProductBrandEndPoints ,productBrandEndPoints} from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";

export const brandProductApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrandProductHindi: builder.query({
            query: () => ({
                url: `${productBrandEndPoints}/hindi`,
                method: 'GET'
            })
        }),
        getBrandProductEnglish: builder.query({
            query: () => ({
                url: `${productBrandEndPoints}/english`,
                method: 'GET'
            })
        }),
        updateProduct: builder.mutation({
            query: (newHeader) => {
                return {
                    url: updateProductBrandEndPoints,
                    method: 'POST',
                    body: newHeader,
                }
            }
        })
    })
})
export const {useGetBrandProductEnglishQuery,useGetBrandProductHindiQuery,useUpdateProductMutation} =brandProductApiSlice