import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
function Servererror() {
    const navigate =useNavigate()
    return (
        <div className="container-fluid">
            <div className="text-center">
                <div className="error mx-auto" data-text="500">500</div>
                <p className="lead text-gray-800 "> Internal Server Error </p>
                <Button onClick={()=>{navigate('/')}}>&larr; Back to Login</Button>
            </div>

        </div>
    );
}

export default Servererror;