import { apiSlice } from "../../component/app/api/apiSlice"
import { deleteBannerEndPoint,getBannerEndPoint } from "../../contextapi/Endpoints/Endpoints"

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createSlider: builder.mutation({
            query: (newPost) => {
                return {
                    url: 'admin/addbanner',
                    method: 'POST',
                    body: newPost,
                }
            }
        }),
        getAllSlider: builder.query({
            query: () => ({
                url: getBannerEndPoint,
                method: 'GET'
            })
        }),
        getSliderById: builder.query({
            query: (id) => {
                return {
                    url: `posts/${id}`,
                    method: 'GET'
                }
            }
        }),
        deleteSlider: builder.mutation({
            query: (id) => {
                return {
                    url: `${deleteBannerEndPoint}/${id}`,
                    method: 'DELETE',
                }
            }
        }),
        updateSlider: builder.mutation({
            query: ({raw, id}) => {
                return {
                    url: `admin/updatebanner/${id}`,
                    method: 'PATCH',
                    body: raw,
                    
                }
            }
        })

    })
})
export const { useCreateSliderMutation,useDeleteSliderMutation,useGetAllSliderQuery,useGetSliderByIdQuery,useUpdateSliderMutation } = authApiSlice