import React from "react";
import { Button } from "react-bootstrap";

import { useState } from "react";
import { ChooseUpdateEditorVerticallyCenteredModal } from "../../../Modelhelper/Modelhelper";

function UpdateEditor({ value,from }) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        +Update your content
      </Button>

      <ChooseUpdateEditorVerticallyCenteredModal
        value={value}
        from ={from}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default UpdateEditor;
