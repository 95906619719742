import React, { useState, useEffect, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import OrderEditStatusChanger from "./orderStatusChange";
import OrderInvoice from "./orderInvoice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="search"
      placeholder="Search by ID, Name"
      aria-label="Search Input"
      defaultValue={filterText}
      onChange={onFilter}
      className="w-25 form-control float-left"
      autoComplete="off"
    />
    {/* <button type="button" className="btn bg-black text-white mr-5" onClick={onClear} disabled>
     <MdSearch/>
    </button> */}
  </>
);

const NourishOrder = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  // console.log("nourishmedusatoken key ---" + nourishmedusatoken)
  const [OrderData, setOrderData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [pending, setPending] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [limit, setLimit] = useState(10); // Rows per page
  const filterProperties = ["email", "name", "id", "title", "phone", "city"];
  const resetComponent = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterText("");
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const DateFilterForm = ({ onDateChange }) => {
    const handleDateChange = () => {
      onDateChange(startDate, endDate);
    };
    const handleClear = () => {
      resetComponent();
    };

    return (
      <>
        <button
          type="button"
          className="btn bg-warning text-black ml-1"
          onClick={handleClear}
        >
          Reset Filter
        </button>
        {/* <button
          type="button"
          className="btn bg-black text-white ml-1"
          onClick={handleDateChange}
        >
          Apply
        </button> */}
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          className=" form-control"
        />

        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className=" form-control"
        />
      </>
    );
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_Nourish_URL
        }admin/orders?limit=${limit}&offset=${(page - 1) * limit}`,
        requestOptions
      );
      const result = await response.json();
      setTotalRows(result.count);
      setOrderData(result);
    } catch (error) {
      console.log("error", error);
      if (!error?.status || error?.status === "FETCH_ERROR") {
        navigate("/nslogin");
        swal({
          title: "Session Expired Please Login Again",
          icon: "error",
          dangerMode: true,
        });
      } else if (error.status === 401) {
        navigate("/nslogin");
        // swal({
        //   title: err.data.message,
        //   icon: "error",
        //   dangerMode: true,
        // });
      } else {
        swal({
          title: "Someting went wrong1",
          icon: "error",
          dangerMode: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchData(page, limit);
  }, [page, limit]);

  const filteredItems = OrderData?.orders?.filter((item) =>
    filterProperties.some(
      (property) =>
        new Date(item.created_at) > (startDate || new Date(0)) &&
        new Date(item.created_at) < (endDate || new Date()) &&
        new Date(item.created_at) > new Date("2024-07-16 01:29:37") &&
        item[property] &&
        item[property].toLowerCase().includes(filterText.toLowerCase())
    )
  );

  // console.log(filteredItems + "filteredItems");
  // console.log("Note----------" + JSON.stringify(OrderData));
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="SubFilterheader w-100 d-inline border p-3">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          className=""
        />
        <div className="dateSection d-flex flex-row-reverse w-75">
          <DateFilterForm onDateChange={handleDateChange} />{" "}
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  // Post Create Note
  const [noteValue, setNoteValue] = useState("");
  const [rowNoteValues, setRowNoteValues] = useState({});
  const [rowNotes, setRowNotes] = useState({});

  const setRowNoteValue = (orderId, value) => {
    setRowNoteValues((prevRowNoteValues) => ({
      ...prevRowNoteValues,
      [orderId]: value,
    }));
  };

  const createNote = async (orderId) => {
    const raw = JSON.stringify({
      resource_id: `${orderId}`,
      resource_type: "order",
      value: rowNoteValues[orderId],
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptionsNote = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/notes`,
        requestOptionsNote
      );
      const result = await response.json();
      // setRowNotes((prevRowNotes) => ({
      //   ...prevRowNotes,
      //   [orderId]: result,
      // }));
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  // Get Create Note Response

  const getNotes = async () => {
    const requestOptionsRowNote = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/notes?limit=100000000`,
        requestOptionsRowNote
      );
      const result = await response.json();

      setRowNotes(result);
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  useEffect(() => {
    if (nourishmedusatoken) {
      getNotes();
    }
  }, [nourishmedusatoken]);

  // Get Product fulfilment Status
  const [NSFulStatus, setNSFulStatus] = useState({});
  const getNsFullfilment = async () => {
    const requestOptionsRowNsFullfilment = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/orders?fields=order_status`,
        requestOptionsRowNsFullfilment
      );
      const result = await response.json();

      setNSFulStatus(result);
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  useEffect(() => {
    getNsFullfilment();
  }, []);

  // Data Table Order Section
  const columns = [
    {
      name: "Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row.id,
      sortable: true,
      grow: 3,
      wrap: true,
    },
    {
      name: "Channel",
      selector: (row) => (row.customer?.metadata?.gender ? "App" : "Website"),
      sortable: true,
      grow: 1,
    },
    {
      name: "Name",
      grow: 2,
      selector: (row) => row.customer.first_name + " " + row.customer.last_name,
      sortable: false,
      wrap: true,
    },
    // {
    //   name: "Email",
    //   selector: (row) => row.email,
    //   sortable: false,
    //   grow: 2,
    // },
    {
      name: "Phone",
      // grow: 2,
      selector: (row) => row.customer.phone,
    },
    // {
    //   name: "Address",
    //   selector: (row) =>
    //     row.shipping_address.address_1 + "," + row.shipping_address.postal_code,
    // },
    {
      name: "City",
      selector: (row) => row.shipping_address.city,
      wrap: true,
      grow: 2,
    },
    {
      name: "Coupon",
      selector: (row) => row?.discounts[0]?.code,
      sortable: false,
      grow: 2,
    },

    {
      name: "Product Name",
      grow: 3,
      wrap: true,
      selector: (row) =>
        row.items
          .map(
            (name) =>
              name.quantity +
              " x " +
              name.title +
              " - " +
              name?.variant?.metadata?.item_weight
          )
          .join(" , "),
    },

    {
      name: "Price",
      selector: (row) =>
        `₹${parseFloat(row?.payments[0]?.amount / 100).toFixed(2)}`,
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <b className="text-success">
          {
            // (row.payments[0].provider_id = "system"
            //   ? "Paid"
            //   : 
              row?.payments[0]?.data.status
            // )
          }
        </b>
      ),
    },
    {
      name: "Invoice",
      selector: (row) => (
        <OrderInvoice
          nourishmedusatoken={nourishmedusatoken}
          orderId={row.id}
        />
      ),
    },
    {
      name: "Fulfillment Status",
      grow: 2,
      selector: (row) => (
        <>
          <div className="">
            {NSFulStatus?.orders
              ?.filter((statusFl) => row?.id === statusFl?.id)
              .map((statusFl, index) =>
                statusFl?.order_status === null ? (
                  <b className="text-danger">Order Confirm</b>
                ) : (
                  <b
                    className={`${
                      statusFl?.order_status === "Dispatched"
                        ? "text-secondary"
                        : statusFl?.order_status === "Out for Delivery"
                        ? "text-info"
                        : statusFl?.order_status === "Delivered"
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    {statusFl?.order_status}
                  </b>
                )
              )}
          </div>
        </>
      ),
    },

    {
      name: "Order Shippment Status",
      grow: 3,
      selector: (row) =>
        row?.payments[0]?.data.status !== "awaiting" &&
        row?.payments[0]?.data.status !== "requires_action" &&
        row?.order_status !== "Delivered" ? (
          <>
            <OrderEditStatusChanger
              nourishmedusatoken={nourishmedusatoken}
              orderId={row?.id}
              orderStatus={row?.order_status}
            />
          </>
        ) : (
          <button
            className="btn text-wrap"
            disabled
            style={{ fontSize: "12px" }}
          >
            {row?.order_status !== "Delivered"
              ? "Payment Awating"
              : " Order Delivered"}
          </button>
        ),
    },
    {
      name: "Add Note",
      grow: 4,
      selector: (row) => (
        <div className="w-100 d-flex my-2">
          <textarea
            className="form-control"
            style={{ fontSize: "12px" }}
            placeholder="Enter note"
            value={row.noteValue}
            onChange={(e) => setRowNoteValue(row.id, e.target.value)}
          />
          <button
            className="btn btn-primary mx-2"
            style={{ fontSize: "12px" }}
            onClick={() => createNote(row.id, noteValue)}
          >
            Note
          </button>
        </div>
      ),
    },
    {
      name: "Remark",
      grow: 3,
      selector: (row) => (
        <>
          <div className="w-100">
            {rowNotes.notes
              .filter((note) => row.id === note.resource_id)
              .map((note, index) => (
                <div className=" mb-3">
                  <div
                    key={note.id}
                    className=" d-flex justify-content-between text-center  p-1 "
                  >
                    <small className="" style={{ width: "120px" }}>
                      Note {index + 1} :{" "}
                    </small>
                    <b className="text-wrap text-start w-100 ">{note?.value}</b>
                    <span className="text-wrap text-end d-block ">
                      {format(
                        new Date(note?.created_at),
                        "MM/dd/yyyy hh:mm:ss"
                      )}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </>
      ),
    },
  ];

  const downloadReport = () => {
    const csvContent = prepareCSVData(filteredItems);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, "order_report.csv");
    } else {
      link.href = URL.createObjectURL(blob);
      link.download = "order_report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const prepareCSVData = (data) => {
    const headers = [
      "Date",
      "Order ID",
      "Name",
      "Email",
      "Phone",
      "Pincode",
      "City",
      "Price",
      "Payment Status",
      "Order Fulfillment Status",
      "Product Name",
      "Product Weight",
      "Product Qty",
    ];

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach(
      (order) => {
        const commonDetails = [
          format(new Date(order.created_at), "MM/dd/yyyy HH:mm:ss"),
          order.id,
          `${order.customer.first_name} ${order.customer.last_name}`,
          order.email,
          order.shipping_address.phone,
          order.shipping_address.postal_code,
          order.shipping_address.city,
          `${(order.payments[0]?.amount / 100).toFixed(2)}`,
          order.payments[0]?.data?.status || "Unknown",
          order.status,
        ];

        order.items.forEach((item, index) => {
          const productDetails = [
            item.title,
            item?.variant?.metadata?.item_weight || "N/A",
            item.quantity,
          ];

          const columns =
            index === 0
              ? [...commonDetails, ...productDetails]
              : [
                  ...new Array(commonDetails.length).fill(""),
                  ...productDetails,
                ];

          csvRows.push(columns.join(","));
        });
      }

      // data.forEach((order) => {
      //      const commonDetails = [
      //     format(new Date(order.created_at), "MM/dd/yyyy HH:mm:ss"),
      //     order.id,
      //     `${order.customer.first_name} ${order.customer.last_name}`,
      //     order.email,
      //     order.shipping_address.phone,
      //     `${order.shipping_address.postal_code}`,
      //     (order.payments[0]?.amount / 100).toFixed(2),
      //     order.payments[0]?.data?.status || "Unknown",
      //     order.status,
      //   ];

      //   let productDetailsString = "";

      //   order.items.forEach((item) => {
      //     const productDetails = [
      //       item.title,
      //       item?.variant?.metadata?.item_weight || "N/A",
      //       item.quantity,
      //     ];
      //     productDetailsString += productDetails.join(",") + ",";
      //   });
      //   productDetailsString = productDetailsString.slice(0, -1);

      //   const columns = [...commonDetails, productDetailsString];

      //   csvRows.push(columns.join(","));
      // }
    );

    return csvRows.join("\n");
  };

  // Example usage
  const data = [
    // Your orders data here
  ];

  // console.log(prepareCSVData(data));

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1); // Reset to first page when changing rows per page
  };
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Order Data</h1>
        <button
          className="btn btn-sm btn-primary shadow-sm"
          onClick={downloadReport}
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Download
          Report
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            className="TableNs"
            columns={columns}
            data={filteredItems}
            progressPending={pending}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={limit}
            onChangeRowsPerPage={handleRowsPerPageChange}
            onChangePage={handlePageChange}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationComponentOptions={paginationOptions}
            highlightOnHover
          />
        </div>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>View Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedOrderId && (
            <OrderEditStatusChanger
              nourishmedusatoken={nourishmedusatoken}
              orderId={selectedOrderId}
              onClose={() => setShowModal(false)}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NourishOrder;
