import React from 'react';

import { TbEdit } from 'react-icons/tb';
import { useState } from 'react';
import { FooterVerticallyCenteredModal } from '../../Common/Footer/Updatefooter';

function Footermodel({previousData,value}) {
    const [modalShow, setModalShow] = useState(false)
    return (
        <>
            <TbEdit className='h3' onClick={() => setModalShow(true)} variant="primary"></TbEdit>
            <FooterVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Footermodel;