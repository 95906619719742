import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { MediaAddVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';

function Brandmediaaddmodel({ value }) {
    const { englishModelShow, setEnglishModelShow } = useContext(Auth)
    return (
        <>
            <Button variant="primary" onClick={() => setEnglishModelShow(true)}>
                Add Media 
            </Button>
            <MediaAddVerticallyCenteredModal
                value={value}
                show={englishModelShow}
                onHide={() => setEnglishModelShow(false)}
            />
        </>
    );
}

export default Brandmediaaddmodel;