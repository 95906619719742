import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext'
import { useContext } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import './model.css'
import { HeaderEnglishVerticallyCenteredModal } from '../../Common/Header.js/updateheader';

function Headermodelenglish({previousData,value}) {
    const { englishModelShow,setEnglishModelShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3'  onClick={() => setEnglishModelShow(true)}/>
            <HeaderEnglishVerticallyCenteredModal
                value={value}
                previousData={previousData}
                show={englishModelShow}
                onHide={() => setEnglishModelShow(false)}
            />
        </>
    );
}

export default Headermodelenglish;