import { getAllAboutOil, deleteAboutOil, updateAboutOil, addAboutOil } from "../../../contextapi/Endpoints/Endpoints"
import { apiSlice } from "../../app/api/apiSlice"
export const brandAboutOilSplice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllAboutOilHindi: builder.query({
            query: () => ({
                url: `${getAllAboutOil}/hindi`,
                method: 'GET'
            })
        }),
        getAllAboutOilEnglish: builder.query({
            query: () => ({
                url: `${getAllAboutOil}/english`,
                method: 'GET'
            })
        }),
        createAboutOil: builder.mutation({
            query: (newPost) => {
                return {
                    url: addAboutOil,
                    method: 'POST',
                    body: newPost,
                }
            }
        }),
        deleteAboutOil: builder.mutation({
            query: ({ raw, lang }) => {
                return {
                    url: `${deleteAboutOil}/${lang}`,
                    method: 'DELETE',
                    body:raw
                }
            }
        }),
        updateAboutOil: builder.mutation({
            query: ({ raw, lang }) => {
                return {
                    url: `${updateAboutOil}/${lang}`,
                    method: 'PATCH',
                    body: raw,

                }
            }
        })
    })
})
export const { useGetAllAboutOilHindiQuery, useGetAllAboutOilEnglishQuery,  useCreateAboutOilMutation, useDeleteAboutOilMutation, useUpdateAboutOilMutation } = brandAboutOilSplice