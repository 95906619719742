import React from "react";
import { useContext } from "react";
import swal from "sweetalert";
import { Accordion, Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import Media from "../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import { useCreateComicMutation } from "../Campaign/campaignApiSlice";
import { useGetAllComicQuery } from "../Campaign/campaignApiSlice";
import Multipleinput from "../GlobalComponent/Multipleinput.js/Multipleinput";
import { getSlug } from "../GlobalComponent/global";
import Commoneditor from "../GlobalComponent/Editor/Globaleditor/Editor";
export function AddCampaignCenteredModal(props) {
  const {
    createCategoriesComic,
    setCreateCategoriesComic,
    inputFields,
  } = useContext(Auth);
  const [addComic] = useCreateComicMutation();
  const getComic = useGetAllComicQuery();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      // language:createCategoriesComic.lang === ""  ? "hindi"  : createCategoriesComic.lang,
      name: {
        hindi: createCategoriesComic.comicName.forHindi,
        english: createCategoriesComic.comicName.forEnglish,
      },
      slug: getSlug(createCategoriesComic.comicName.forEnglish),
      mainimage: createCategoriesComic.mainImage,
      backgroundimage: createCategoriesComic.backgroundImage,
      sliderimage:
        createCategoriesComic.siderImages !== ""
          ? createCategoriesComic.siderImages
          : [],
      mobilesliderimage: createCategoriesComic.mobileSliderImage,
      aboutpage: {
        mainHeading: createCategoriesComic.aboutMainHeading.forHindi,
        subHeading: createCategoriesComic.aboutsubHeading.forHindi,
        description: createCategoriesComic.aboutDescription.forHindi,
        rightImage: createCategoriesComic.rightImage,
        leftSliderImage: createCategoriesComic.leftSliderImage,
        buttonText: createCategoriesComic.buttonText.forHindi,
        buttonLink: createCategoriesComic.buttonLink,
      },
      aboutpageenglish: {
        mainHeading: createCategoriesComic.aboutMainHeading.forEnglish,
        subHeading: createCategoriesComic.aboutsubHeading.forEnglish,
        description: createCategoriesComic.aboutDescription.forEnglish,
        buttonText: createCategoriesComic.buttonText.forEnglish,
      },
      storypage: {
        mainPage: createCategoriesComic.storyMainHeading.forHindi,
        subHeading: createCategoriesComic.storySubHeading.forHindi,
        description: createCategoriesComic.storyDescription.forHindi,
        rightImage: createCategoriesComic.storyRightImage,
        leftImage: createCategoriesComic.storyLeftImage,
        buttonText: createCategoriesComic.storyButtonText.forHindi,
        buttonLink: createCategoriesComic.buttonStoryLink,
      },
      storypageenglish: {
        mainPage: createCategoriesComic.storyMainHeading.forEnglish,
        subHeading: createCategoriesComic.storySubHeading.forEnglish,
        description: createCategoriesComic.storyDescription.forEnglish,
        buttonText: createCategoriesComic.storyButtonText.forEnglish,
      },
      testimonials: {
        mainHeading: createCategoriesComic.campaignHeading,
        listing: inputFields,
        subHeading: createCategoriesComic.campaignHeading,
      },
    };
    addComic(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: "" });
        setCreateCategoriesComic({
          ...createCategoriesComic,
          siderImages: "",
          rightImage: "",
          leftSliderImage: "",
          storyRightImage: "",
          storyLeftImage: "",
          mainImage: "",
          backgroundImage: "",
        });
        getComic.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            {/* comic name fields */}
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Comic name field</Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For English">
                      <div>
                        {/* <Form.Label >Enter Your Email</Form.Label> */}
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter your comic name in english
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter slug of your comic
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* <Form.Label >Enter Your Email</Form.Label> */}
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  comicName: {
                                    ...createCategoriesComic.comicName,
                                    forEnglish: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Comic Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              disabled
                              defaultValue={"Slug Will Be Auto Generate"}
                              className="w-100"
                              type="text"
                              placeholder="Enter Slug"
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter your comic name in hindi
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter slug of your comic
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  comicName: {
                                    ...createCategoriesComic.comicName,
                                    forHindi: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Comic Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              disabled
                              defaultValue={"Slug Will Be Auto Generate"}
                              className="w-100"
                              type="text"
                              placeholder="Enter Slug"
                            />
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/* accordation for main image and background image */}
            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Upload Main Image and Background Image
                </Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex row">
                    <div className="col-md-6">
                      {" "}
                      <Form.Label className="font-weight-bold">
                        {" "}
                        Enter Your Main Image{" "}
                      </Form.Label>{" "}
                    </div>
                    <div className="col-md-6">
                      {" "}
                      <Form.Label className="font-weight-bold">
                        {" "}
                        Enter Your Background Image{" "}
                      </Form.Label>{" "}
                    </div>
                    <div className="col-md-6">
                      <Media value={"campaignmainImage"} />
                      <div className="mt-3">
                        <ul>
                          <li>
                            <img
                              alt="slider"
                              src={
                                createCategoriesComic.mainImage.length !== 0
                                  ? createCategoriesComic.mainImage
                                  : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                              }
                              className="slider-image mt-3"
                            ></img>
                            {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                            {createCategoriesComic.mainImage.length !== 0 ? (
                              <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    mainImage: "",
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Media value={"campaignbackgroundimage"} />
                      <div className="mt-3">
                        <ul>
                          <li>
                            <img
                              alt="slider"
                              src={
                                createCategoriesComic.backgroundImage.length !==
                                0
                                  ? createCategoriesComic.backgroundImage
                                  : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                              }
                              className="slider-image mt-3"
                            ></img>
                            {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                            {createCategoriesComic.backgroundImage.length !==
                            0 ? (
                              <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    backgroundImage: "",
                                  });
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/*accordation for Slider Menu */}
            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Slider Menu field</Accordion.Header>
                <Accordion.Body>
                  <Form.Label className="h5 font-weight-bold text-center mt-4">
                    Slider Menu-:
                  </Form.Label>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Upload Your Sliders Web Images &nbsp;
                        </Form.Label>
                        <Media value={"campaignsliderimage"} />
                      </div>
                      <div className="col-md-6">
                        <Form.Label className="font-weight-bold">
                          Upload Your Sliders Mobile Images
                        </Form.Label>
                        <Media value={"campaignslidermobileimage"} />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <div className="mt-3">
                          <ul>
                            <li>
                              {createCategoriesComic.siderImages.length !==
                              0 ? (
                                <>
                                  {createCategoriesComic.siderImages.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          <RxCrossCircled
                                            className="delteicon"
                                            onClick={() => {
                                              setCreateCategoriesComic({
                                                ...createCategoriesComic,
                                                siderImages:
                                                  createCategoriesComic.siderImages.filter(
                                                    function (val) {
                                                      return val !== item;
                                                    }
                                                  ),
                                              });
                                            }}
                                          />
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <img
                                  alt="slider"
                                  src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                  className="slider-image mt-3"
                                ></img>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* mobile image */}
                      <div className="col-md-6">
                        <div className="mt-3">
                          <ul>
                            <li>
                              {createCategoriesComic.mobileSliderImage
                                .length !== 0 ? (
                                <>
                                  {createCategoriesComic.mobileSliderImage.map(
                                    (item) => {
                                      return (
                                        <>
                                          <img
                                            alt="slider"
                                            src={item}
                                            className="slider-image mt-3"
                                          ></img>
                                          <RxCrossCircled
                                            className="delteicon"
                                            onClick={() => {
                                              setCreateCategoriesComic({
                                                ...createCategoriesComic,
                                                mobileSliderImage:
                                                  createCategoriesComic.mobileSliderImage.filter(
                                                    function (val) {
                                                      return val !== item;
                                                    }
                                                  ),
                                              });
                                            }}
                                          />
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <img
                                  alt="slider"
                                  src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                  className="slider-image mt-3"
                                ></img>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/*accordation for About Page */}
            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>About Page</Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For english">
                      <Form.Label className="h5 font-weight-bold text-center mt-4">
                        {" "}
                        About Menu-:{" "}
                      </Form.Label>
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Main Heading
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Sub Heading
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutMainHeading: {
                                      ...createCategoriesComic.aboutMainHeading,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Main Heading "
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutsubHeading: {
                                      ...createCategoriesComic.aboutsubHeading,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder="Sub Heading"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* description row */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Commoneditor value="englishabout" />
                              {/* <Button
                                onClick={() => {
                                  handleAdd("desEnglish");
                                }}
                                className="mt-2"
                              >
                                {" "}
                                Save editor content english{" "}
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* right and left slider */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Left Slider Images
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex row">
                            {/* <div className="col-md-6">
                    <Media value={"campaignaboutrightimage"} />
                    <div className="mt-3">
                      <ul>
                        <li>
                          <img
                            alt="slider"
                            src={
                              createCategoriesComic.rightImage.length !== 0
                                ? createCategoriesComic.rightImage
                                : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                            }
                            className="slider-image mt-3"
                          ></img>
                          {createCategoriesComic.rightImage.length !== 0 ? (
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  rightImage: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  </div> */}

                            <div className="col-md-6">
                              <Media value={"campaignaboutLeftimage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    {createCategoriesComic.leftSliderImage
                                      .length !== 0 ? (
                                      <>
                                        {createCategoriesComic.leftSliderImage.map(
                                          (item) => {
                                            return (
                                              <>
                                                <img
                                                  alt="slider"
                                                  src={item}
                                                  className="slider-image mt-3"
                                                ></img>
                                                <RxCrossCircled
                                                  className="delteicon"
                                                  onClick={() => {
                                                    setCreateCategoriesComic({
                                                      ...createCategoriesComic,
                                                      leftSliderImage:
                                                        createCategoriesComic.leftSliderImage.filter(
                                                          function (val) {
                                                            return val !== item;
                                                          }
                                                        ),
                                                    });
                                                  }}
                                                />
                                              </>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        alt="slider"
                                        src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                        className="slider-image mt-3"
                                      ></img>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* button text and button link */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Link
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonText: {
                                      ...createCategoriesComic.buttonText,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonLink: e.target.value,
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder=" Button Url Where You want to Navigate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      <Form.Label className="h5 font-weight-bold text-center mt-4">
                        {" "}
                        About Menu-:{" "}
                      </Form.Label>
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Main Heading
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Sub Heading
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutMainHeading: {
                                      ...createCategoriesComic.aboutMainHeading,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Main Heading "
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    aboutsubHeading: {
                                      ...createCategoriesComic.aboutsubHeading,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder="Sub Heading"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* description row */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Commoneditor value="hindiabout" />
                              {/* <Button
                                onClick={() => {
                                  handleAdd("desHindi");
                                }}
                                className="mt-2"
                              >
                                {" "}
                                Save editor content{" "}
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* right and left slider */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            {/* <div className="col-md-6">
                    <Form.Label className="font-weight-bold">
                      Enter Your Right Image
                    </Form.Label>
                  </div> */}
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Left Slider Images
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            {/* <div className="col-md-6">
                    <Media value={"campaignaboutrightimage"} />
                    <div className="mt-3">
                      <ul>
                        <li>
                          <img
                            alt="slider"
                            src={
                              createCategoriesComic.rightImage.length !== 0
                                ? createCategoriesComic.rightImage
                                : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                            }
                            className="slider-image mt-3"
                          ></img>
                          {createCategoriesComic.rightImage.length !== 0 ? (
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  rightImage: "",
                                });
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </li>
                      </ul>
                    </div>
                  </div> */}

                            <div className="col-md-6">
                              <Media value={"campaignaboutLeftimage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    {createCategoriesComic.leftSliderImage
                                      .length !== 0 ? (
                                      <>
                                        {createCategoriesComic.leftSliderImage.map(
                                          (item) => {
                                            return (
                                              <>
                                                <img
                                                  alt="slider"
                                                  src={item}
                                                  className="slider-image mt-3"
                                                ></img>
                                                <RxCrossCircled
                                                  className="delteicon"
                                                  onClick={() => {
                                                    setCreateCategoriesComic({
                                                      ...createCategoriesComic,
                                                      leftSliderImage:
                                                        createCategoriesComic.leftSliderImage.filter(
                                                          function (val) {
                                                            return val !== item;
                                                          }
                                                        ),
                                                    });
                                                  }}
                                                />
                                              </>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : (
                                      <img
                                        alt="slider"
                                        src="https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                        className="slider-image mt-3"
                                      ></img>
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* button text and button link */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Link
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonText: {
                                      ...createCategoriesComic.buttonText,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonLink: e.target.value,
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder=" Button Url Where You want to Navigate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/*accordation for Story Page */}
            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Story Field </Accordion.Header>
                <Accordion.Body>
                  <Tabs
                    defaultActiveKey="english"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="english" title="For english">
                      <Form.Label className="h5 font-weight-bold text-center mt-4">
                        {" "}
                        Story Page-:{" "}
                      </Form.Label>
                      {/* 1st row of story  */}
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Your Main Heading{" "}
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Sub Heading{" "}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storyMainHeading: {
                                    ...createCategoriesComic.storyMainHeading,
                                    forEnglish: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Enter your main heading "
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storySubHeading: {
                                    ...createCategoriesComic.storySubHeading,
                                    forEnglish: e.target.value,
                                  },
                                })
                              }
                              className="w-100"
                              type="text"
                              placeholder="Enter your sub heading "
                            />
                          </div>
                        </div>
                      </div>
                      {/* story description */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Story Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Commoneditor value="englishstory" />
                              {/* <Button
                                onClick={() => {
                                  handleStory("desEnglish");
                                }}
                                className="mt-2"
                              >
                                {" "}
                                Save editor content{" "}
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* story right and left images */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Right Image
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Left Slider Images
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Media value={"storyRightImage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    <img
                                      alt="slider"
                                      src={
                                        createCategoriesComic.storyRightImage
                                          .length !== 0
                                          ? createCategoriesComic.storyRightImage
                                          : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                      }
                                      className="slider-image mt-3"
                                    ></img>
                                    {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                    {createCategoriesComic.storyRightImage
                                      .length !== 0 ? (
                                      <RxCrossCircled
                                        className="delteicon"
                                        onClick={() => {
                                          setCreateCategoriesComic({
                                            ...createCategoriesComic,
                                            storyRightImage: "",
                                          });
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Media value={"storyLeftImage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    <img
                                      alt="slider"
                                      src={
                                        createCategoriesComic.storyLeftImage
                                          .length !== 0
                                          ? createCategoriesComic.storyLeftImage
                                          : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                      }
                                      className="slider-image mt-3"
                                    ></img>
                                    {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                    {createCategoriesComic.storyLeftImage
                                      .length !== 0 ? (
                                      <RxCrossCircled
                                        className="delteicon"
                                        onClick={() => {
                                          setCreateCategoriesComic({
                                            ...createCategoriesComic,
                                            storyLeftImage: "",
                                          });
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* story button text and button link  */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Link
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    storyButtonText: {
                                      ...createCategoriesComic.storyButtonText,
                                      forEnglish: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonStoryLink: e.target.value,
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder=" Button Url Where You want to Navigate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="hindi" title="For hindi">
                      <Form.Label className="h5 font-weight-bold text-center mt-4">
                        {" "}
                        Story Page-:{" "}
                      </Form.Label>
                      {/* 1st row of story  */}
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Your Main Heading{" "}
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Label className="font-weight-bold">
                              Enter Sub Heading{" "}
                            </Form.Label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex row">
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storyMainHeading: {
                                    ...createCategoriesComic.storyMainHeading,
                                    forHindi: e.target.value,
                                  },
                                })
                              }
                              className="w-100 "
                              type="text"
                              placeholder="Enter your main  haeding "
                            />
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              onChange={(e) =>
                                setCreateCategoriesComic({
                                  ...createCategoriesComic,
                                  storySubHeading: {
                                    ...createCategoriesComic.storySubHeading,
                                    forHindi: e.target.value,
                                  },
                                })
                              }
                              className="w-100"
                              type="text"
                              placeholder="Enter your sub heading "
                            />
                          </div>
                        </div>
                      </div>
                      {/* story description */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Form.Label className="font-weight-bold">
                                Enter Your Story Description
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-12">
                              <Commoneditor value="hindistory" />
                              {/* <Button
                                onClick={() => {
                                  handleStory("desHindi");
                                }}
                                className="mt-2"
                              >
                                {" "}
                                Save editor content{" "}
                              </Button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* story right and left images */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Right Image
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Left Slider Images
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Media value={"storyRightImage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    <img
                                      alt="slider"
                                      src={
                                        createCategoriesComic.storyRightImage
                                          .length !== 0
                                          ? createCategoriesComic.storyRightImage
                                          : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                      }
                                      className="slider-image mt-3"
                                    ></img>
                                    {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                    {createCategoriesComic.storyRightImage
                                      .length !== 0 ? (
                                      <RxCrossCircled
                                        className="delteicon"
                                        onClick={() => {
                                          setCreateCategoriesComic({
                                            ...createCategoriesComic,
                                            storyRightImage: "",
                                          });
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <Media value={"storyLeftImage"} />
                              <div className="mt-3">
                                <ul>
                                  <li>
                                    <img
                                      alt="slider"
                                      src={
                                        createCategoriesComic.storyLeftImage
                                          .length !== 0
                                          ? createCategoriesComic.storyLeftImage
                                          : "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
                                      }
                                      className="slider-image mt-3"
                                    ></img>
                                    {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
                                    {createCategoriesComic.storyLeftImage
                                      .length !== 0 ? (
                                      <RxCrossCircled
                                        className="delteicon"
                                        onClick={() => {
                                          setCreateCategoriesComic({
                                            ...createCategoriesComic,
                                            storyLeftImage: "",
                                          });
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* story button text and button link  */}
                      <div>
                        <div>
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Text
                              </Form.Label>
                            </div>
                            <div className="col-md-6">
                              <Form.Label className="font-weight-bold">
                                Enter Your Button Link
                              </Form.Label>
                            </div>
                          </div>
                        </div>
                        <div>
                          {/* <Form.Label >Enter Your Email</Form.Label> */}
                          <div className="d-flex row">
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    storyButtonText: {
                                      ...createCategoriesComic.storyButtonText,
                                      forHindi: e.target.value,
                                    },
                                  })
                                }
                                className="w-100 "
                                type="text"
                                placeholder="Button Text"
                              />
                            </div>
                            <div className="col-md-6">
                              <Form.Control
                                onChange={(e) =>
                                  setCreateCategoriesComic({
                                    ...createCategoriesComic,
                                    buttonStoryLink: e.target.value,
                                  })
                                }
                                className="w-100"
                                type="text"
                                placeholder=" Button Url Where You want to Navigate"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {/*accordation for Testimonial Page */}
            <Accordion className="mt-2">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Testimonials field</Accordion.Header>
                <Accordion.Body>
                  <Form.Label className="h5 font-weight-bold text-center mt-4">
                    Testimonials-:
                  </Form.Label>
                  <Multipleinput />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Create Comic
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
