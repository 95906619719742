import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import { RxCrossCircled } from "react-icons/rx";
import { Accordion, Button, Tab, Tabs } from "react-bootstrap";
import {
  useGetAllSliderQuery,
  useUpdateSliderMutation,
} from "../../features/slider/sliderApiSlice";
import Media from "../GlobalComponent/Media/Media";
import { useContext } from "react";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import {
  useUpdateHeaderMutation,
  useGetHeaderEnglishQuery,
  useGetHeaderHindiQuery,
} from "../Common/Header.js/headerApiSlice";
import Editor from "../GlobalComponent/Editor/Editor";
import {
  useGetBrandAboutEnglishQuery,
  useGetBrandAboutHindiQuery,
  useUpdateAboutMutation,
} from "../Home/Brandabout/brandAboutApiSlice";
import {
  useGetBrandProductEnglishQuery,
  useGetBrandProductHindiQuery,
  useUpdateProductMutation,
} from "../Home/Bailkholuproduct/brandProductApiSlice";
import {
  useGetBrandPhilosophyEnglishQuery,
  useGetBrandPhilosophyHindiQuery,
  useUpdatePhilosophyMutation,
} from "../Home/Brandphilosophy/brandPhilosophyApiSlice";
import {
  useGetAllAboutOilEnglishQuery,
  useGetAllAboutOilHindiQuery,
  useUpdateAboutOilMutation,
} from "../Home/Brandaboutoil.js/brandAboutOilSplice";
import {
  useGetBrandStoryEnglishQuery,
  useGetBrandStoryHindiQuery,
  useUpdateStoryMutation,
} from "../Home/Brandstory/brandStoryApiSlice";
import {
  useCreateMediaMutation,
  useGetAllMediaQuery,
  useUpdateMediaMutation,
} from "../Home/Brandmedia/brandMediaApiSlice";
import {
  useUpdateFooterMutation,
  useGetFooterQuery,
} from "../Common/Footer/footerApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import {
  useCreateBlogMutation,
  useGetAllBlogQuery,
  useUpdateBlogPageContentMutation,
} from "../Home/Brandblogs/blogApiSlice";
import {
  useGetContactQuery,
  useUpdateContactMutation,
} from "../Home/Brandcontact/contactApiSlice";
import {
  useGetAllComicQuery,
  useUpdateComicMutation,
} from "../Campaign/campaignApiSlice";
import Multipleinput from "../GlobalComponent/Multipleinput.js/Multipleinput";
import Campstoryeditor from "../GlobalComponent/Editor/Campstoryeditor/Campstoryeditor";
import Campabouteditor from "../GlobalComponent/Editor/Campabouteditor/Campabouteditor";
import Updatemultipleinput from "../GlobalComponent/Multipleinput.js/Updatemultipleinput";
import { getSlug } from "../GlobalComponent/global";
import swal from "sweetalert";
import CardHeader from "react-bootstrap/esm/CardHeader";
import Commoneditor from "../GlobalComponent/Editor/Globaleditor/Editor";
import UpdateEditor from "../GlobalComponent/Editor/Globaleditor/UpdateEditor";


export function BrandAboutHindiVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState("");
  const { brandAbout, setBrandAbout, editorState } = useContext(Auth);
  const [updateAbout] = useUpdateAboutMutation();
  const { refetch } = useGetBrandAboutHindiQuery();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      heading:
        brandAbout?.heading?.length !== 0
          ? brandAbout?.heading
          : props?.previousData?.heading,
      imageurl:
        brandAbout?.imageurl?.length !== 0
          ? brandAbout?.imageurl
          : previousImage,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.description,
      subheading:
        brandAbout?.subHeading.length !== 0
          ? brandAbout.subHeading
          : props?.previousData?.subHeading,
    };
    updateAbout(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Card>
              <CardHeader><b>Hindi Content</b></CardHeader>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                 
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Update Your Heading Name</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Update Your Sub Heading Name</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={props?.previousData?.heading}
                          onChange={(e) => {
                            setBrandAbout({
                              ...brandAbout,
                              heading: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter Your Heading Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={props?.previousData?.subHeading}
                          onChange={(e) => {
                            setBrandAbout({
                              ...brandAbout,
                              subHeading: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter Your Sub Heading Name"
                        />
                      </div>
                    </div>
                  </div>
                  <h6 className="mt-4 font-weight-bold">
                    Update Your New Description{" "}
                </h6>
                  <Editor value={props?.previousData?.description} />
                  <h6 className="mt-4 font-weight-bold">
                    Upload Your About Image{" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandAbout.imageurl.length !== 0 ? (
                          <>
                            <img
                              src={brandAbout.imageurl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandAbout({ ...brandAbout, imageurl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandAbout.imageurl.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function BrandAboutEnglishVerticallyCenteredModal(props) {
  const [updateAbout] = useUpdateAboutMutation();
  const { refetch } = useGetBrandAboutEnglishQuery();
  const [previousImage, setPreviousImage] = useState("");
  const { brandAbout, setBrandAbout, editorState } = useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      heading:
        brandAbout?.heading?.length !== 0
          ? brandAbout?.heading
          : props?.previousData?.aboutContent?.heading,
      imageurl:
        brandAbout?.imageurl?.length !== 0
          ? brandAbout?.imageurl
          : previousImage,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.aboutContent?.description,
      subheading:
        brandAbout?.subHeading.length !== 0
          ? brandAbout.subHeading
          : props?.previousData?.subHeading,
    };
    updateAbout(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  useEffect(() => {
    setPreviousImage(props?.previousData?.aboutContent?.imageUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Card>
                <CardHeader><b>English Content</b></CardHeader>
                <Card.Body md="3 ">
              
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Update Your Heading Name</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Update Your Sub Heading Name</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={
                            props?.previousData?.aboutContent?.heading
                          }
                          onChange={(e) => {
                            setBrandAbout({
                              ...brandAbout,
                              heading: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter Your Heading Name"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={
                            props?.previousData?.aboutContent?.subHeading
                          }
                          onChange={(e) => {
                            setBrandAbout({
                              ...brandAbout,
                              subHeading: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Enter Your Heading Name"
                        />
                      </div>
                    </div>
                  </div>

                  <h6 className="mt-4 font-weight-bold">
                    Update Your New Description{" "}
                 </h6>
                  <Editor
                    value={props?.previousData?.aboutContent?.description}
                  />
                 <h6 className="mt-4 font-weight-bold">
                    Upload Your About Image{" "}
                  </h6>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandAbout.imageurl.length !== 0 ? (
                          <>
                            <img
                              src={brandAbout.imageurl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandAbout({ ...brandAbout, imageurl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandAbout.imageurl.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for brand product
export function ProductHindiVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState([]);
  const [updateProduct] = useUpdateProductMutation();
  const productEnglish = useGetBrandProductEnglishQuery();
  const productHindi = useGetBrandProductHindiQuery();
  const { brandProduct, setBrandProduct, editorState, setEditorState } =
    useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      productname:
        brandProduct?.productName?.length !== 0
          ? brandProduct?.productName
          : props?.previousData?.productName,
      heading:
        brandProduct?.heading?.length !== 0
          ? brandProduct?.heading
          : props?.previousData?.heading,
      subheading:
        brandProduct?.subHeading?.length !== 0
          ? brandProduct?.subHeading
          : props?.previousData?.heading,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.description,
      imageurl:
        brandProduct?.productImage?.length !== 0
          ? brandProduct?.productImage
          : previousImage,
      button: [
        {
          nourishlink:
            brandProduct?.nourishStoreLink?.length !== 0
              ? brandProduct?.nourishStoreLink
              : props?.previousData?.button[0].nourishlink,
        },
        {
          amazonlink:
            brandProduct?.amazonStoreLink?.length !== 0
              ? brandProduct?.amazonStoreLink
              : props?.previousData?.button[1].amazonlink,
        },
      ],
    };
    updateProduct(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        productHindi.refetch();
        productEnglish.refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  useEffect(() => {
    setEditorState({
      ...editorState,
      editorHtml: props?.previousData?.description,
    });
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl, props?.previousData?.description]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.productName}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        productName: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[0]?.nourishlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        nourishStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        heading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[1]?.amazonlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        amazonStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Amazon store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From SubHeading Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        subHeading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter Your Sub Heading Link "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Update Your Description{" "}
                  </Card.Title>
                  <Editor value={props?.previousData?.description} />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandProduct.productImage.length !== 0 ? (
                          <>
                            <img
                              src={brandProduct.productImage}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandProduct({
                                  ...brandProduct,
                                  productImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandProduct.productImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function ProductEnglishVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState("");
  const [updateProduct] = useUpdateProductMutation();
  const productEnglish = useGetBrandProductEnglishQuery();
  const productHindi = useGetBrandProductHindiQuery();
  const { brandProduct, setBrandProduct, editorState, setEditorState } =
    useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      productname:
        brandProduct?.productName?.length !== 0
          ? brandProduct?.productName
          : props?.previousData?.productName,
      heading:
        brandProduct?.heading?.length !== 0
          ? brandProduct?.heading
          : props?.previousData?.heading,
      subheading:
        brandProduct?.subHeading?.length !== 0
          ? brandProduct?.subHeading
          : props?.previousData?.heading,
      description:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.description,
      imageurl:
        brandProduct?.productImage?.length !== 0
          ? brandProduct?.productImage
          : previousImage,
      button: [
        {
          nourishlink:
            brandProduct?.nourishStoreLink?.length !== 0
              ? brandProduct?.nourishStoreLink
              : props?.previousData?.button[0].nourishlink,
        },
        {
          amazonlink:
            brandProduct?.amazonStoreLink?.length !== 0
              ? brandProduct?.amazonStoreLink
              : props?.previousData?.button[1].amazonlink,
        },
      ],
    };
    updateProduct(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
        productHindi.refetch();
        productEnglish.refetch();
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setEditorState({
      ...editorState,
      editorHtml: props?.previousData?.description,
    });
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl, props?.previousData?.description]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Product Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Nourish Store Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.productName}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        productName: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[0]?.nourishlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        nourishStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Nourish store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading Name
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From Amazon Store Link
                  </Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        heading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Product Name"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.button[1]?.amazonlink}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        amazonStoreLink: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder=" Amazon store Link "
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Buy From SubHeading Link
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) => {
                      setBrandProduct({
                        ...brandProduct,
                        subHeading: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="Enter Your Sub Heading Link "
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                {/* style={{height:"800px"}} */}
                <Card.Body md="3 ">
                  <Card.Title className="text-center ">
                    Update Your Description{" "}
                  </Card.Title>
                  <Editor value={props?.previousData?.description} />
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Product Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandProduct.productImage.length !== 0 ? (
                          <>
                            <img
                              src={brandProduct.productImage}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandProduct({
                                  ...brandProduct,
                                  productImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandProduct.productImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>

        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for brand Philosophy

//for product oil (Crud Operations)
export function AboutoilenglishVerticallyCenteredModal(props) {
  const [updateAboutOil] = useUpdateAboutOilMutation();
  const { refetch } = useGetAllAboutOilEnglishQuery();
  const { brandAboutOil, setBrandAboutOil } = useContext(Auth);
  const [previousImage, setPreviousImage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      cardid: `${props.previousData._id}`,
      newheading:
        brandAboutOil?.heading?.length !== 0
          ? brandAboutOil?.heading
          : props?.previousData?.heading,
      newimageurl:
        brandAboutOil?.imageUrl?.length !== 0
          ? brandAboutOil?.imageUrl
          : previousImage,
      newdescription:
        brandAboutOil?.description?.length !== 0
          ? brandAboutOil?.description
          : props?.previousData?.description,
    };
    updateAboutOil({ raw, lang: "english" })
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBrandAboutOil({ ...brandAboutOil, imageUrl: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">Heading</Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        heading: e.target.value,
                      });
                    }}
                    className="w-100"
                    type="text"
                    placeholder="Enter your Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Description{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    defaultValue={props?.previousData?.description}
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        description: e.target.value,
                      });
                    }}
                    className="w-100 "
                    type="text"
                    placeholder="Enter Your Description"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your About Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandAboutOil.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={brandAboutOil.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandAboutOil({
                                  ...brandAboutOil,
                                  imageUrl: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandAboutOil?.imageUrl?.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function AboutoilhindiVerticallyCenteredModal(props) {
  const [updateAboutOil] = useUpdateAboutOilMutation();
  const { refetch } = useGetAllAboutOilHindiQuery();
  const { brandAboutOil, setBrandAboutOil } = useContext(Auth);
  const [previousImage, setPreviousImage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      cardid: `${props.previousData._id}`,
      newheading:
        brandAboutOil?.heading?.length !== 0
          ? brandAboutOil?.heading
          : props?.previousData?.heading,
      newimageurl:
        brandAboutOil?.imageUrl?.length !== 0
          ? brandAboutOil?.imageUrl
          : previousImage,
      newdescription:
        brandAboutOil?.description?.length !== 0
          ? brandAboutOil?.description
          : props?.previousData?.description,
    };
    updateAboutOil({ raw, lang: "hindi" })
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBrandAboutOil({ ...brandAboutOil, imageUrl: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    {" "}
                    Choose Language
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">Heading</Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-6">
                  <Form.Control
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        heading: e.target.value,
                      });
                    }}
                    className="w-100"
                    type="text"
                    placeholder="Enter your Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Description{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    defaultValue={props?.previousData?.description}
                    onChange={(e) => {
                      setBrandAboutOil({
                        ...brandAboutOil,
                        description: e.target.value,
                      });
                    }}
                    className="w-100"
                    type="text"
                    placeholder="Enter Your Description"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your About Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandAboutOil.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={brandAboutOil.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandAboutOil({
                                  ...brandAboutOil,
                                  imageUrl: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandAboutOil?.imageUrl?.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={(e) => handleSubmit(e)}>Update</Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for brand Media
export function MediaHindiVerticallyCenteredModal(props) {
  const { brandMedia, setBrandMedia, loader, setLoader } = useContext(Auth);
  const [previousImage, setPreviousImage] = useState("");
  const getAllMedia = useGetAllMediaQuery();
  const [updateMedia] = useUpdateMediaMutation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      newheading:
        brandMedia?.heading?.length !== 0
          ? brandMedia?.heading
          : props?.previousData?.heading,
      newtitle:
        brandMedia?.title?.length !== 0
          ? brandMedia?.title
          : props?.previousData?.title,
      newdescription:
        brandMedia?.description?.length !== 0
          ? brandMedia?.description
          : props?.previousData?.description,
      newurl:
        brandMedia?.sourceUrl?.length !== 0
          ? brandMedia?.sourceUrl
          : props?.previousData?.url,
      newsource:
        brandMedia?.source?.length !== 0
          ? brandMedia?.source
          : props?.previousData?.source,
      newimage:
        brandMedia?.image?.length !== 0 ? brandMedia?.image : previousImage,
    };
    updateMedia({ raw, id: props?.previousData?._id })
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllMedia.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBrandMedia({ ...brandMedia, image: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.image);
  }, [props]);
  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div>
                  {/* <Form.Label >Enter Your Email</Form.Label> */}
                  <div className=" row">
                    <div className="col-md-4">
                      <Form.Label className="font-weight-bold">
                        Enter Your Title
                      </Form.Label>
                    </div>
                    <div className="col-md-4">
                      <Form.Label className="font-weight-bold">
                        Enter Your Heading
                      </Form.Label>
                    </div>
                    <div className="col-md-4">
                      <Form.Label className="font-weight-bold">
                        Enter Your Description
                      </Form.Label>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <Form.Label >Enter Your Email</Form.Label> */}
                  <div className="d-flex row">
                    <div className="col-md-4">
                      <Form.Control
                        className="w-100"
                        type="text"
                        defaultValue={props?.previousData?.title}
                        onChange={(e) => {
                          setBrandMedia({
                            ...brandMedia,
                            title: e.target.value,
                          });
                        }}
                        placeholder="Enter Your Title"
                      />
                    </div>
                    <div className="col-md-4">
                      <Form.Control
                        className="w-100"
                        type="text"
                        defaultValue={props?.previousData?.heading}
                        onChange={(e) => {
                          setBrandMedia({
                            ...brandMedia,
                            heading: e.target.value,
                          });
                        }}
                        placeholder="Enter Your Heading"
                      />
                    </div>
                    <div className="col-md-4">
                      <Form.Control
                        className="w-100"
                        defaultValue={props?.previousData?.description}
                        onChange={(e) => {
                          setBrandMedia({
                            ...brandMedia,
                            description: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="Enter Your Description"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {/* <Form.Label >Enter Your Email</Form.Label> */}
                  <div className="d-flex row">
                    <div className="col-md-4">
                      <Form.Label className="font-weight-bold">
                        Source URL
                      </Form.Label>
                    </div>
                    <div className="col-md-4">
                      <Form.Label className="font-weight-bold">
                        Source{" "}
                      </Form.Label>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <Form.Label >Enter Your Email</Form.Label> */}
                  <div className="d-flex row">
                    <div className="col-md-4">
                      <Form.Control
                        className="w-100"
                        defaultValue={props?.previousData?.url}
                        onChange={(e) => {
                          setBrandMedia({
                            ...brandMedia,
                            sourceUrl: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="Enter Your Source Url"
                      />
                    </div>
                    <div className="col-md-4">
                      <Form.Control
                        className="w-100"
                        defaultValue={props?.previousData?.source}
                        onChange={(e) => {
                          setBrandMedia({
                            ...brandMedia,
                            source: e.target.value,
                          });
                        }}
                        type="text"
                        placeholder="Enter Your Source"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <Card style={{ marginTop: "20px" }}>
                    <Card.Body md="3 ">
                      <Card.Title
                        className="text-center"
                        style={{ marginTop: "20px" }}
                      >
                        Upload Your Media Image{" "}
                      </Card.Title>
                      <div className="row">
                        <div className="col-md-5">
                          <Media value={props?.value} />
                        </div>
                        <div className="col-md-2"></div>
                        <div className=" d-flex m-2 col-md-5">
                          <div className="nav-link" href="#">
                            {/* {
                    brandMedia.image.length !== 0 ?
                      <>
                        <img src={brandMedia.image} className='slider-image mt-3'></img>
                        <RxCrossCircled className='delteicon' onClick={() => { setBrandMedia({ ...brandMedia, sourceUrl: "" }) }} />
                      </> : ""
                  }
                </div>
                {
                  brandMedia.sourceUrl?.length !== 0 ? "" : <>
                    {
                      previousImage?.length != 0 ? <div>
                        <div className="nav-link" href="#">
                          <img alt="slider" src={previousImage} className='slider-image mt-3'></img>
                          <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage([]) }} />
                        </div>
                      </div> : ""
                    }
                  </>
                } */}

                            {brandMedia.image.length !== 0 ? (
                              <>
                                <img
                                  src={brandMedia.image}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setBrandMedia({ ...brandMedia, image: "" });
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {brandMedia?.image?.length !== 0 ? (
                            ""
                          ) : (
                            <>
                              {previousImage?.length != 0 ? (
                                <div>
                                  <div className="nav-link" href="#">
                                    <img
                                      alt="slider"
                                      src={previousImage}
                                      className="slider-image mt-3"
                                    ></img>
                                    <RxCrossCircled
                                      className="delteicon"
                                      onClick={() => {
                                        setPreviousImage([]);
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Update
            </Button>
            <Button>Reset All </Button>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
export function MediaAddVerticallyCenteredModal(props) {
  const { brandMedia, setBrandMedia } = useContext(Auth);
  const getAllMedia = useGetAllMediaQuery();
  const [addmedia] = useCreateMediaMutation();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      heading: brandMedia?.heading,
      title: brandMedia?.title,
      description: brandMedia?.description,
      url: brandMedia?.sourceUrl,
      source: brandMedia?.source,
      image: brandMedia?.image,
    };
    addmedia(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllMedia.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBrandMedia({ ...brandMedia, image: "" });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className=" row">
                  <div className="col-md-4">
                    <Form.Label className="font-weight-bold">
                      Enter Your Title
                    </Form.Label>
                  </div>
                  <div className="col-md-4">
                    <Form.Label className="font-weight-bold">
                      Enter Your Heading
                    </Form.Label>
                  </div>
                  <div className="col-md-4">
                    <Form.Label className="font-weight-bold">
                      Enter Your Description
                    </Form.Label>
                  </div>
                </div>
              </div>
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className="d-flex row">
                  <div className="col-md-4">
                    <Form.Control
                      className="w-100"
                      type="text"
                      onChange={(e) => {
                        setBrandMedia({ ...brandMedia, title: e.target.value });
                      }}
                      placeholder="Enter Your Title"
                    />
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      className="w-100"
                      type="text"
                      onChange={(e) => {
                        setBrandMedia({
                          ...brandMedia,
                          heading: e.target.value,
                        });
                      }}
                      placeholder="Enter Your Heading"
                    />
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      className="w-100"
                      onChange={(e) => {
                        setBrandMedia({
                          ...brandMedia,
                          description: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Enter Your Description"
                    />
                  </div>
                </div>
              </div>
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className="d-flex row">
                  <div className="col-md-4">
                    <Form.Label className="font-weight-bold">
                      Source URL
                    </Form.Label>
                  </div>
                  <div className="col-md-4">
                    <Form.Label className="font-weight-bold">
                      Source{" "}
                    </Form.Label>
                  </div>
                </div>
              </div>
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className="d-flex row">
                  <div className="col-md-4">
                    <Form.Control
                      className="w-100"
                      onChange={(e) => {
                        setBrandMedia({
                          ...brandMedia,
                          sourceUrl: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Enter Your Source Url"
                    />
                  </div>
                  <div className="col-md-4">
                    <Form.Control
                      className="w-100"
                      onChange={(e) => {
                        setBrandMedia({
                          ...brandMedia,
                          source: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder="Enter Your Source"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Card style={{ marginTop: "20px" }}>
                  <Card.Body md="3 ">
                    <Card.Title
                      className="text-center"
                      style={{ marginTop: "20px" }}
                    >
                      Upload Your Media Image{" "}
                    </Card.Title>
                    <div className="row">
                      <div className="col-md-5">
                        <Media value={props?.value} />
                      </div>
                      <div className="col-md-2"></div>
                      <div className=" d-flex m-2 col-md-5">
                        <div className="nav-link" href="#">
                          {brandMedia.image.length !== 0 ? (
                            <>
                              <img
                                src={brandMedia.image}
                                className="slider-image mt-3"
                              ></img>
                              <RxCrossCircled
                                className="delteicon"
                                onClick={() => {
                                  setBrandMedia({ ...brandMedia, image: "" });
                                }}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Update
          </Button>
          <Button>Reset All </Button>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
//for brand Story
export function StoryHindiVerticallyCenteredModal(props) {
  const { brandStory, setBrandStory } = useContext(Auth);
  const [updateStory] = useUpdateStoryMutation();
  const getStoryHindi = useGetBrandStoryHindiQuery();
  const getEnglish = useGetBrandStoryEnglishQuery();

  const [previousImageUrl, setPreviousImageUrl] = useState([]);
  const [previousBackgroundImage, setPreviousBackgroundImage] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      newimageurl:
        brandStory?.imageUrl?.length !== 0
          ? brandStory?.imageUrl
          : previousImageUrl,
      newheading:
        brandStory?.heading?.length !== 0
          ? brandStory?.heading
          : props?.previousData?.heading,
      newsubheading:
        brandStory?.subHeading?.length !== 0
          ? brandStory?.subHeading
          : props?.previousData?.subheading,
      newbuttontext:
        brandStory?.buttonText?.length !== 0
          ? brandStory?.buttonText
          : props?.previousData?.buttonText,
      newbackgroundimage:
        brandStory?.backgroundImageUrl?.length !== 0
          ? brandStory?.backgroundImageUrl
          : previousBackgroundImage,
    };
    updateStory({ raw, lang: "hindi" })
      .unwrap()
      .then((data) => {
        props.onHide();
        getStoryHindi.refetch();
        getEnglish.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImageUrl(props?.previousData?.imageUrl);
    setPreviousBackgroundImage(props?.previousData?.backgroundImage);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Sub Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) =>
                      setBrandStory({ ...brandStory, heading: e.target.value })
                    }
                    type="text"
                    placeholder="Enter Heading"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subheading}
                    onChange={(e) =>
                      setBrandStory({
                        ...brandStory,
                        subHeading: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Enter Sub Heading"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div className="col-md-6">
                <Form.Label className="font-weight-bold">
                  New Text Button
                </Form.Label>
              </div>
            </div>
            <div>
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className="d-flex row">
                  <div className="col-md-6">
                    <Form.Control
                      className="w-100"
                      defaultValue={props?.previousData?.buttonText}
                      onChange={(e) =>
                        setBrandStory({
                          ...brandStory,
                          buttonText: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Enter New Text Heading"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div className="col-md-6 mt-2">
                {/* Image url files */}
                <Form.Label className="font-weight-bold">Image Url</Form.Label>
                <Media value={"brandstoryimageurl"} />
              </div>

              <div className="col-md-6 mt-2">
                {/* for Background Url files */}
                <Form.Label className="font-weight-bold">
                  Background Image Url
                </Form.Label>

                <Media value={"brandstorybackgroundimage"} />
              </div>
            </div>

            <div className="row">
              <div className="d-flex m-2 col-md-5">
                <div className="nav-link" href="#">
                  {brandStory.imageUrl.length !== 0 ? (
                    <>
                      <img
                        src={brandStory.imageUrl}
                        className="slider-image mt-3"
                      ></img>
                      <RxCrossCircled
                        className="delteicon"
                        onClick={() => {
                          setBrandStory({ ...brandStory, imageUrl: "" });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {brandStory.imageUrl.length !== 0 ? (
                  ""
                ) : (
                  <>
                    {previousImageUrl?.length != 0 ? (
                      <div>
                        <div className="nav-link" href="#">
                          <img
                            alt="slider"
                            src={previousImageUrl}
                            className="slider-image mt-3"
                          ></img>
                          <RxCrossCircled
                            className="delteicon"
                            onClick={() => {
                              setPreviousImageUrl([]);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className=" d-flex m-2 col-md-1"></div>
              <div className="d-flex m-2 col-md-5">
                <div className="nav-link" href="#">
                  {brandStory.backgroundImageUrl.length !== 0 ? (
                    <>
                      <img
                        src={brandStory.backgroundImageUrl}
                        className="slider-image mt-3"
                      ></img>
                      <RxCrossCircled
                        className="delteicon"
                        onClick={() => {
                          setBrandStory({
                            ...brandStory,
                            backgroundImageUrl: "",
                          });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {brandStory.backgroundImageUrl.length !== 0 ? (
                  ""
                ) : (
                  <>
                    {previousBackgroundImage?.length != 0 ? (
                      <div>
                        <div className="nav-link" href="#">
                          <img
                            alt="slider"
                            src={previousBackgroundImage}
                            className="slider-image mt-3"
                          ></img>
                          <RxCrossCircled
                            className="delteicon"
                            onClick={() => {
                              setPreviousBackgroundImage([]);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function StoryEnglishVerticallyCenteredModal(props) {
  const [updateStory] = useUpdateStoryMutation();
  const getEnglish = useGetBrandStoryEnglishQuery();
  const getStoryHindi = useGetBrandStoryHindiQuery();
  const { brandStory, setBrandStory } = useContext(Auth);
  const [previousImageUrl, setPreviousImageUrl] = useState([]);
  const [previousBackgroundImage, setPreviousBackgroundImage] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      newimageurl:
        brandStory?.imageUrl?.length !== 0
          ? brandStory?.imageUrl
          : previousImageUrl,
      newheading:
        brandStory?.heading?.length !== 0
          ? brandStory?.heading
          : props?.previousData?.heading,
      newsubheading:
        brandStory?.subHeading?.length !== 0
          ? brandStory?.subHeading
          : props?.previousData?.subheading,
      newbuttontext:
        brandStory?.buttonText?.length !== 0
          ? brandStory?.buttonText
          : props?.previousData?.buttonText,
      newbackgroundimage:
        brandStory?.backgroundImageUrl?.length !== 0
          ? brandStory?.backgroundImageUrl
          : previousBackgroundImage,
    };
    updateStory({ raw, lang: "english" })
      .unwrap()
      .then((data) => {
        props.onHide();
        getEnglish.refetch();
        getStoryHindi.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImageUrl(props?.previousData?.imageUrl);
    setPreviousBackgroundImage(props?.previousData?.backgroundImage);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Heading
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Sub Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.heading}
                    onChange={(e) =>
                      setBrandStory({ ...brandStory, heading: e.target.value })
                    }
                    type="text"
                    placeholder="Enter Heading"
                  />
                </div>

                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.subheading}
                    onChange={(e) =>
                      setBrandStory({
                        ...brandStory,
                        subHeading: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Enter Sub Heading"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div className="col-md-6">
                <Form.Label className="font-weight-bold">
                  New Text Button
                </Form.Label>
              </div>
            </div>
            <div>
              <div>
                {/* <Form.Label >Enter Your Email</Form.Label> */}
                <div className="d-flex row">
                  <div className="col-md-6">
                    <Form.Control
                      className="w-100"
                      defaultValue={props?.previousData?.buttonText}
                      onChange={(e) =>
                        setBrandStory({
                          ...brandStory,
                          buttonText: e.target.value,
                        })
                      }
                      type="text"
                      placeholder="Enter New Text Heading"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex row">
              <div className="col-md-6 mt-2">
                {/* Image url files */}
                <Form.Label className="font-weight-bold">Image Url</Form.Label>
                <Media value={"brandstoryimageurl"} />
              </div>
              <div className="col-md-6 mt-2">
                {/* for Background Url files */}
                <Form.Label className="font-weight-bold">
                  Background Image Url
                </Form.Label>
                <Media value={"brandstorybackgroundimage"} />
              </div>
            </div>

            <div className="row">
              <div className="d-flex m-2 col-md-5">
                <div className="nav-link" href="#">
                  {brandStory.imageUrl.length !== 0 ? (
                    <>
                      <img
                        src={brandStory.imageUrl}
                        className="slider-image mt-3"
                      ></img>
                      <RxCrossCircled
                        className="delteicon"
                        onClick={() => {
                          setBrandStory({ ...brandStory, imageUrl: "" });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {brandStory.imageUrl.length !== 0 ? (
                  ""
                ) : (
                  <>
                    {previousImageUrl?.length != 0 ? (
                      <div>
                        <div className="nav-link" href="#">
                          <img
                            alt="slider"
                            src={previousImageUrl}
                            className="slider-image mt-3"
                          ></img>
                          <RxCrossCircled
                            className="delteicon"
                            onClick={() => {
                              setPreviousImageUrl([]);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
              <div className=" d-flex m-2 col-md-1"></div>
              <div className="d-flex m-2 col-md-5">
                <div className="nav-link" href="#">
                  {brandStory.backgroundImageUrl.length !== 0 ? (
                    <>
                      <img
                        src={brandStory.backgroundImageUrl}
                        className="slider-image mt-3"
                      ></img>
                      <RxCrossCircled
                        className="delteicon"
                        onClick={() => {
                          setBrandStory({
                            ...brandStory,
                            backgroundImageUrl: "",
                          });
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {brandStory.backgroundImageUrl.length !== 0 ? (
                  ""
                ) : (
                  <>
                    {previousBackgroundImage?.length != 0 ? (
                      <div>
                        <div className="nav-link" href="#">
                          <img
                            alt="slider"
                            src={previousBackgroundImage}
                            className="slider-image mt-3"
                          ></img>
                          <RxCrossCircled
                            className="delteicon"
                            onClick={() => {
                              setPreviousBackgroundImage([]);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for blog
export function BlogHindiVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState([]);
  const { blog, setBlog, editorState, setEditorState } = useContext(Auth);
  const [updateBlog] = useUpdateBlogPageContentMutation();
  const getAllHindiBlogs = useGetAllBlogQuery({ language: "hindi" });
  const getAllEnglishBlogs = useGetAllBlogQuery({ language: "english" });
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      blogid: props?.previousData?._id,
      newimageurl:blog?.imageUrl?.length !== 0 ? blog?.imageUrl : previousImage,
      newtitle:blog?.blogTitle?.length !== 0? blog?.blogTitle: props?.previousData?.title,
      newauthor:blog?.author?.length !== 0 ? blog?.author : props?.previousData?.author,
      newspecialtext:blog?.specialText?.length !== 0 ? blog?.specialText: props?.previousData?.specialText,
      newcontent:editorState.editorHtml?.length !== 0? editorState.editorHtml: props?.previousData?.content,
      newtags:blog?.tags?.length !== 0 ? blog?.tags : props?.previousData?.tags,
      newslug:blog?.slug?.length !== 0 ? getSlug(blog?.slug) : props?.previousData?.slug,
    };
    updateBlog(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllHindiBlogs.refetch();
        getAllEnglishBlogs.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBlog({
          ...blog,
          blogTitle: "",
          imageUrl: "",
          author: "",
          specialText: "",
          backgroundImageUrl: "",
          slug: "",
          tags: ""
        })
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setEditorState({
      ...editorState,
      editorHtml: props?.previousData?.content,
    });
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl, props?.previousData?.content]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <Form.Label>Update Hindi Blogs</Form.Label>
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Slug{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    defaultValue={props?.previousData?.title}
                    onChange={(e) => {
                      setBlog({ ...blog, blogTitle: e.target.value });
                    }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    onChange={(e) => {
                      setBlog({ ...blog, slug: e.target.value });
                    }}
                    defaultValue={props?.previousData?.slug}
                    placeholder="Enter Inner Blog Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Author Title
                  </Form.Label>
                </div>
                {/* <div className='col-md-6'>
                  <Form.Label className='font-weight-bold'>Enter Slug </Form.Label>
                </div> */}
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    defaultValue={props?.previousData?.author}
                    onChange={(e) => {
                      setBlog({ ...blog, author: e.target.value });
                    }}
                    placeholder="Enter Your Author Title"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">Tags</Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.tags}
                    onChange={(e) => {
                      setBlog({ ...blog, tags: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Tags"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Special Text
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.specialText}
                    onChange={(e) => {
                      setBlog({ ...blog, specialText: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Source Url"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Blog Inner Content
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Editor value={props?.previousData?.content} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Upload Your Blog Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {blog.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={blog.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBlog({ ...blog, imageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {blog?.imageUrl?.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function BlogEnglishVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState([]);
  const { blog, setBlog, editorState, setEditorState } = useContext(Auth);
  const [updateBlog] = useUpdateBlogPageContentMutation();
  const getAllHindiBlogs = useGetAllBlogQuery({ language: "hindi" });
  const getAllEnglishBlogs = useGetAllBlogQuery({ language: "english" });
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      blogid: props?.previousData?._id,
      newimageurl:
        blog?.imageUrl?.length !== 0 ? blog?.imageUrl : previousImage,
      newtitle:
        blog?.blogTitle?.length !== 0
          ? blog?.blogTitle
          : props?.previousData?.title,
      newauthor:
        blog?.author?.length !== 0 ? blog?.author : props?.previousData?.author,
      newspecialtext:
        blog?.specialText?.length !== 0
          ? blog?.specialText
          : props?.previousData?.specialText,
      newcontent:
        editorState.editorHtml?.length !== 0
          ? editorState.editorHtml
          : props?.previousData?.content,
      newtags: blog?.tags?.length !== 0 ? blog?.tags : props?.previousData?.tags,
      newslug:blog?.slug?.length !== 0 ? getSlug(blog?.slug) : props?.previousData?.slug,

    };
    updateBlog(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getAllHindiBlogs.refetch();
        getAllEnglishBlogs.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
        setBlog({ ...blog, 
          blogTitle: "",
          imageUrl: "", 
          author: "",
          specialText:"",
          backgroundImageUrl:"",
          slug: "",
          tags: ""
         }) 
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
  }, [props?.previousData?.imageUrl]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <Form.Label>Update Hindi Blogs</Form.Label>
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>

                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Slug{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    defaultValue={props?.previousData?.title}
                    onChange={(e) => {
                      setBlog({ ...blog, blogTitle: e.target.value });
                    }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                   
                    defaultValue={props?.previousData?.slug}
                    onChange={(e) => { setBlog({ ...blog, slug: e.target.value }) }}
                    placeholder="Enter Inner Blog Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Author Title
                  </Form.Label>
                </div>
                {/* <div className='col-md-6'>
                  <Form.Label className='font-weight-bold'>Enter Slug </Form.Label>
                </div> */}
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    defaultValue={props?.previousData?.author}
                    onChange={(e) => {
                      setBlog({ ...blog, author: e.target.value });
                    }}
                    placeholder="Enter Your Author Title"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">Tags</Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.tags}
                    onChange={(e) => {
                      setBlog({ ...blog, tags: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Tags"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Special Text
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    defaultValue={props?.previousData?.specialText}
                    onChange={(e) => {
                      setBlog({ ...blog, specialText: e.target.value });
                    }}
                    type="text"
                    placeholder="Enter Your Source Url"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Blog Inner Content
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Editor value={props?.previousData?.content} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Upload Your Blog Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {blog.imageUrl.length !== 0 ? (
                          <>
                            <img
                              src={blog.imageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBlog({ ...blog, imageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {blog?.imageUrl?.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

//for tvc
export function TvcHindiVerticallyCenteredModal(props) {
  const [previousImage, setPreviousImage] = useState([]);
  const { tvc, setTvc } = useContext(Auth);
  useEffect(() => {
    setPreviousImage(props?.previousData?.backgroudImage);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <div className=" row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    type="text"
                    defaultValue={props?.previousData?.videoUrl}
                    onChange={(e) => {
                      setTvc({ ...tvc, videoUrl: e.target.value });
                    }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Change Your Background Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {tvc?.backgroundImageUrl?.length !== 0 ? (
                          <>
                            <img
                              src={tvc.backgroundImageUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setTvc({ ...tvc, backgroundImageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {tvc?.backgroundImageUrl?.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button>Save </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function TvcEnglishVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <Form.Label>Update English Blog</Form.Label>
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Blog Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Inner Blog Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.title}
                    // onChange={(e) => { setBrandMedia({ ...brandMedia, title: e.target.value }) }}
                    placeholder="Enter Your Blog Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.heading}
                    // onChange={(e) => { setBrandMedia({ ...brandMedia, heading: e.target.value }) }}
                    placeholder="Enter Inner Blog Heading"
                  />
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className=" row">
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Your Author Title
                  </Form.Label>
                </div>
                <div className="col-md-6">
                  <Form.Label className="font-weight-bold">
                    Enter Slug{" "}
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.title}
                    // onChange={(e) => { setBrandMedia({ ...brandMedia, title: e.target.value }) }}
                    placeholder="Enter Your Author Title"
                  />
                </div>
                <div className="col-md-6">
                  <Form.Control
                    className="w-100"
                    type="text"
                    // defaultValue={props?.previousData?.heading}
                    // onChange={(e) => { setBrandMedia({ ...brandMedia, heading: e.target.value }) }}
                    placeholder="Enter Slug"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Special Text
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Control
                    className="w-100"
                    // defaultValue={props?.previousData?.url}
                    // onChange={(e) => { setBrandMedia({ ...brandMedia, sourceUrl: e.target.value }) }}
                    type="text"
                    placeholder="Enter Your Source Url"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Form.Label className="font-weight-bold">
                    Blog Inner Content
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-12">
                  <Editor />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form.Label className="text-center font-weight-bold">
                    Upload Your Blog Image{" "}
                  </Form.Label>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {/* {
                          brandMedia.image.length !== 0 ?
                            <>
                              <img src={brandMedia.image} className='slider-image mt-3'></img>
                              <RxCrossCircled className='delteicon' onClick={() => { setBrandMedia({ ...brandMedia, image: "" }) }} />
                            </> : ""
                        } */}
                      </div>
                      {/* {
                        brandMedia?.image?.length !== 0 ? "" : <>
                          {
                            previousImage?.length != 0 ? <div>
                              <div className="nav-link" href="#">
                                <img alt="slider" src={previousImage} className='slider-image mt-3'></img>
                                <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage([]) }} />
                              </div>
                            </div> : ""
                          }
                        </>
                      } */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button>Save </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
//for Footer


//for contact
export function ContactHindiVerticallyCenteredModal(props) {
  const getHindiContact = useGetContactQuery("hindi");
  const getEnglishContact = useGetContactQuery("english");
  const [updateContact] = useUpdateContactMutation();
  const { contactPage, setContactPage } = useContext(Auth);
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      mainheading:
        contactPage?.mainHeading?.length !== 0
          ? contactPage?.mainHeading
          : props?.previousData?.mainHeading,
      subheading:
        contactPage?.subHeading?.length !== 0
          ? contactPage?.subHeading
          : props?.previousData?.subHeading,
      company:
        contactPage?.industryName?.length !== 0
          ? contactPage?.industryName
          : props?.previousData?.company,
      cinnumber:
        contactPage?.cinNumber?.length !== 0
          ? contactPage?.cinNumber
          : props?.previousData?.cinNumber,
      address:
        contactPage?.address?.length !== 0
          ? contactPage?.address
          : props?.previousData?.address,
      contact:
        contactPage?.mobileNumber?.length !== 0
          ? contactPage?.mobileNumber
          : props?.previousData?.contact,
      email: [
        contactPage?.firstEmail?.length !== 0
          ? contactPage?.firstEmail
          : props?.previousData?.email[0],
        contactPage?.secondEmail?.length !== 0
          ? contactPage?.secondEmail
          : props?.previousData?.email[1],
      ],
      contactperson:
        contactPage?.enterContactPersonName?.length !== 0
          ? contactPage?.enterContactPersonName
          : props?.previousData?.contactPerson,
    };
    updateContact(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getHindiContact.refetch();
        swal({
          title: "Contact Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <Form.Label>For Hindi</Form.Label>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Choose Your Language
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Main Heading
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Sub Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.mainHeading}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        mainHeading: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Main Heading"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        subHeading: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Sub Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Industry Name
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter CIN Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Address
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.company}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        industryName: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Industry Name"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.cinNumber}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        cinNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="CIN Number"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.address}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        address: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Enter Address"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Landline Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Mobile Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Email Address 1
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        landlineNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Landline Number"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        mobileNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.email[0]}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        firstEmail: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Email Address 1"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Email Address 2{" "}
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Contact Person Name
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.email[1]}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        secondEmail: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Address line 2"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        enterContactPersonName: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Contact Person Name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function ContactEnglishVerticallyCenteredModal(props) {
  const getEnglishContact = useGetContactQuery("english");
  const [updateContact] = useUpdateContactMutation();
  const { contactPage, setContactPage } = useContext(Auth);
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      mainheading:
        contactPage?.mainHeading?.length !== 0
          ? contactPage?.mainHeading
          : props?.previousData?.mainHeading,
      subheading:
        contactPage?.subHeading?.length !== 0
          ? contactPage?.subHeading
          : props?.previousData?.subHeading,
      company:
        contactPage?.industryName?.length !== 0
          ? contactPage?.industryName
          : props?.previousData?.company,
      cinnumber:
        contactPage?.cinNumber?.length !== 0
          ? contactPage?.cinNumber
          : props?.previousData?.cinNumber,
      address:
        contactPage?.address?.length !== 0
          ? contactPage?.address
          : props?.previousData?.address,
      contact:
        contactPage?.mobileNumber?.length !== 0
          ? contactPage?.mobileNumber
          : props?.previousData?.contact,
      email: [
        contactPage?.firstEmail?.length !== 0
          ? contactPage?.firstEmail
          : props?.previousData?.email[0],
        contactPage?.secondEmail?.length !== 0
          ? contactPage?.secondEmail
          : props?.previousData?.email[1],
      ],
      contactperson:
        contactPage?.enterContactPersonName?.length !== 0
          ? contactPage?.enterContactPersonName
          : props?.previousData?.contactPerson,
    };
    updateContact(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getEnglishContact.refetch();
        swal({
          title: "Contact Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div>
              <Form.Label>For English</Form.Label>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Choose Your Language
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Main Heading
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Sub Heading
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Select aria-label="Default select example">
                    <option value="1" selected>
                      Hindi
                    </option>
                    <option value="2">English</option>
                  </Form.Select>
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.mainHeading}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        mainHeading: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Main Heading"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.subHeading}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        subHeading: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Sub Heading"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Industry Name
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter CIN Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Address
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.company}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        industryName: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Industry Name"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.cinNumber}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        cinNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="CIN Number"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.address}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        address: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Enter Address"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Landline Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Mobile Number
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Email Address 1
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        landlineNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Landline Number"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        mobileNumber: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Mobile Number"
                  />
                </div>
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.email[0]}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        firstEmail: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Email Address 1"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Email Address 2{" "}
                  </Form.Label>
                </div>
                <div className="col-md-4">
                  <Form.Label className="font-weight-bold">
                    Enter Contact Person Name
                  </Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className="d-flex row">
                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.email[1]}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        secondEmail: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Address line 2"
                  />
                </div>

                <div className="col-md-4">
                  <Form.Control
                    defaultValue={props?.previousData?.contact}
                    onChange={(e) =>
                      setContactPage({
                        ...contactPage,
                        enterContactPersonName: e.target.value,
                      })
                    }
                    className="w-100"
                    type="text"
                    placeholder="Contact Person Name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

//for update Campaign
// export function CampaignVerticallyCenteredModal(props) {
//   const { createCategoriesComic, setCreateCategoriesComic, updateTestimonial } =
//     useContext(Auth);
//   const [previousImage, setPreviousImage] = useState({
//     sliderPreviousImage: props.previousData.sliderImage,
//     sliderWebPreviousImage: props.previousData.mobileSliderImage,
//     aboutRightImage: props.previousData.aboutPage.rightImage,
//     aboutLeftImage: props.previousData.aboutPage.leftSliderImage,
//     storyRightImage: props.previousData.storyPage.rightImage,
//     storyLeftImage: props.previousData.storyPage.leftImage,
//     mainImage: props.previousData.mainImage,
//     backgroundImage: props.previousData.backgroundImage,
//   });
//   const [updateComic] = useUpdateComicMutation();
//   const getAllComic = useGetAllComicQuery();
//   const id = props?.previousData?._id;
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const raw = {
//       name: {
//         hindi:
//           createCategoriesComic?.comicName?.forHindi?.length !== 0
//             ? createCategoriesComic?.comicName?.forHindi
//             : props?.previousData?.name?.hindi,
//         english:
//           createCategoriesComic.comicName.forEnglish.length !== 0
//             ? createCategoriesComic?.comicName?.forEnglish
//             : props?.previousData?.name?.english,
//       },
//       slug: getSlug(
//         createCategoriesComic.comicName.forEnglish.length !== 0
//           ? createCategoriesComic?.comicName?.forEnglish
//           : props?.previousData?.name?.english
//       ),
//       sliderimage:
//         createCategoriesComic?.siderImages?.length !== 0
//           ? createCategoriesComic?.siderImages
//           : previousImage?.sliderPreviousImage,

//       mobilesliderimage:
//         createCategoriesComic?.mobileSliderImage?.length !== 0
//           ? createCategoriesComic?.mobileSliderImage
//           : previousImage?.sliderWebPreviousImage,
//       mainimage:
//         createCategoriesComic?.mainImage?.length !== 0
//           ? createCategoriesComic?.mainImage
//           : previousImage?.mainImage,
//       backgroundimage:
//         createCategoriesComic.backgroundImage.length !== 0
//           ? createCategoriesComic.backgroundImage
//           : previousImage?.backgroundImage,

//       aboutpage: {
//         mainHeading:
//           createCategoriesComic?.aboutMainHeading?.forHindi?.length !== 0
//             ? createCategoriesComic?.aboutMainHeading?.forHindi
//             : props?.previousData?.aboutPage?.mainHeading,
//         subHeading:
//           createCategoriesComic?.aboutsubHeading?.forHindi?.length !== 0
//             ? createCategoriesComic?.aboutsubHeading?.forHindi
//             : props?.previousData?.aboutPage?.subHeading,
//         description:
//           createCategoriesComic?.aboutDescription?.forHindi?.length !== 0
//             ? createCategoriesComic?.aboutDescription?.forHindi
//             : props?.previousData?.aboutPage?.description,
//         rightImage:
//           createCategoriesComic?.rightImage?.length !== 0
//             ? createCategoriesComic?.rightImage
//             : previousImage.aboutRightImage,
//         leftSliderImage: [
//           ...previousImage.aboutLeftImage,
//           ...createCategoriesComic.leftSliderImage,
//         ],
//         buttonText:
//           createCategoriesComic?.buttonText?.forHindi?.length !== 0
//             ? createCategoriesComic?.buttonText?.forHindi
//             : props?.previousData?.aboutPage?.buttonText,
//         buttonLink:
//           createCategoriesComic?.buttonLink?.length !== 0
//             ? createCategoriesComic?.buttonLink
//             : props?.previousData?.aboutPage?.buttonLink,
//       },

//       aboutpageenglish: {
//         mainHeading:
//           createCategoriesComic?.aboutMainHeading?.forEnglish?.length !== 0
//             ? createCategoriesComic?.aboutMainHeading?.forEnglish
//             : props?.previousData?.aboutPageEnglish?.mainHeading,

//         subHeading:
//           createCategoriesComic?.aboutsubHeading?.forEnglish?.length !== 0
//             ? createCategoriesComic?.aboutsubHeading?.forEnglish
//             : props?.previousData?.aboutPageEnglish?.subHeading,
//         description:
//           createCategoriesComic?.aboutDescription?.forEnglish?.length !== 0
//             ? createCategoriesComic?.aboutDescription?.forEnglish
//             : props?.previousData?.aboutPageEnglish?.description,
//         buttonText:
//           createCategoriesComic?.buttonText?.forEnglish?.length !== 0
//             ? createCategoriesComic?.buttonText?.forEnglish
//             : props?.previousData?.aboutPageEnglish?.buttonText,
//       },
//       storypageenglish: {
//         mainPage:
//           createCategoriesComic?.storyMainHeading?.forEnglish?.length !== 0
//             ? createCategoriesComic?.storyMainHeading.forEnglish
//             : props?.previousData?.storyPageEnglish?.mainPage,
//         subHeading:
//           createCategoriesComic?.storySubHeading?.forEnglish?.length !== 0
//             ? createCategoriesComic?.storySubHeading?.forEnglish
//             : props?.previousData?.storyPageEnglish?.subHeading,
//         description:
//           createCategoriesComic?.storyDescription?.forEnglish?.length !== 0
//             ? createCategoriesComic?.storyDescription?.forEnglish
//             : props?.previousData?.storyPageEnglish?.description,
//         buttonText:
//           createCategoriesComic?.storyButtonText?.forEnglish?.length !== 0
//             ? createCategoriesComic?.storyButtonText?.forEnglish
//             : props?.previousData?.storyPageEnglish?.buttonText,
//       },
//       storypage: {
//         mainPage:
//           createCategoriesComic?.storyMainHeading?.forHindi?.length !== 0
//             ? createCategoriesComic?.storyMainHeading?.forHindi
//             : props?.previousData?.storyPage?.mainPage,
//         subHeading:
//           createCategoriesComic?.storySubHeading?.forHindi?.length !== 0
//             ? createCategoriesComic?.storySubHeading?.forHindi
//             : props?.previousData?.storyPage?.subHeading,
//         description:
//           createCategoriesComic?.storyDescription?.forHindi?.length !== 0
//             ? createCategoriesComic?.storyDescription?.forHindi
//             : props?.previousData?.storyPage?.description,
//         rightImage:
//           createCategoriesComic?.storyRightImage?.length !== 0
//             ? createCategoriesComic?.storyRightImage
//             : previousImage.storyRightImage,
//         leftImage:
//           createCategoriesComic?.storyLeftImage?.length !== 0
//             ? createCategoriesComic.storyLeftImage
//             : previousImage.storyLeftImage,
//         buttonText:
//           createCategoriesComic?.storyButtonText?.forHindi?.length !== 0
//             ? createCategoriesComic?.storyButtonText?.forHindi
//             : props?.previousData?.storyPage?.buttonText,
//         buttonLink:
//           createCategoriesComic?.buttonStoryLink?.length !== 0
//             ? createCategoriesComic?.buttonStoryLink
//             : props?.previousData?.storyPage?.buttonLink,
//       },

//       testimonials: {
//         mainHeading:
//           createCategoriesComic?.campaignHeading?.length !== 0
//             ? createCategoriesComic?.campaignHeading
//             : props?.previousData?.testimonials?.mainHeading,
//         listing: updateTestimonial.listing,
//       },
//     };

//     updateComic({ raw, id })
//       .unwrap()
//       .then((data) => {
//         props.onHide();
//         getAllComic.refetch();
//         swal({
//           title: data.message,
//           icon: "success",
//           dangerMode: true,
//         });
//         setCreateCategoriesComic({
//           ...createCategoriesComic,
//           siderImages: "",
//           rightImage: "",
//           leftSliderImage: "",
//           storyRightImage: "",
//           storyLeftImage: "",
//           mainImage: "",
//           backgroundImage: "",
//           comicSlug: "",
//         });
//       })
//       .catch((error) => {
//         swal({
//           title: "Something Went Wrong Please Try Again",
//           icon: "error",
//           dangerMode: true,
//         });
//       });
//   };
//   useEffect(() => {
//     setPreviousImage({
//       ...previousImage,
//       sliderPreviousImage: props.previousData.sliderImage,
//     });
//   }, [props.previousData]);
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Body>
//         <div className="container">
//           <div className="row">
//              <Accordion className="mt-3">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Comic name</Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For english">
//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Your Comic Name
//                           </Form.Label>
//                         </div>
//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Slug of Your Comic
//                           </Form.Label>
//                         </div>
//                       </div>

//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Form.Control
//                             defaultValue={props?.previousData?.name?.english}
//                              onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   comicName: {
//                                     ...createCategoriesComic.comicName,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                             className="w-100 "
//                             type="text"
//                             placeholder="Comic Name"
//                           />
//                         </div>
//                         <div className="col-md-6">
//                           <Form.Control
//                             disabled
//                             defaultValue={props?.previousData?.slug}
//                             // onChange={(e) => setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: e.target.value })}
//                             className="w-100"
//                             type="text"
//                             placeholder="Enter Slug"
//                           />
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Your Comic Name
//                           </Form.Label>
//                         </div>
//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Slug of Your Comic
//                           </Form.Label>
//                         </div>
//                       </div>

//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Form.Control
//                             defaultValue={props?.previousData?.name?.hindi}
//                             onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   comicName: {
//                                     ...createCategoriesComic.comicName,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                             className="w-100 "
//                             type="text"
//                             placeholder="Comic Name"
//                           />
//                         </div>
//                         <div className="col-md-6">
//                           <Form.Control
//                             disabled
//                             defaultValue={props?.previousData?.slug}
//                             // onChange={(e) => setCreateCategoriesComic({ ...createCategoriesComic, comicSlug: e.target.value })}
//                             className="w-100"
//                             type="text"
//                             placeholder="Enter Slug"
//                           />
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>

//             {/* main image and background iamge */}
//              <Accordion className="mt-3">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Main and background image</Accordion.Header>
//                 <Accordion.Body>
//                   <div className="d-flex row">
//                     <div className="col-md-6">
//                       <Form.Label className="font-weight-bold">
//                         Main Image
//                       </Form.Label>
//                     </div>
//                     <div className="col-md-6">
//                       <Form.Label className="font-weight-bold">
//                         Background Image
//                       </Form.Label>
//                     </div>
//                   </div>

//                   <div>
//                     {/* <Form.Label >Enter Your Email</Form.Label> */}
//                     <div className="d-flex row">
//                       <div className="col-md-6">
//                         <Media value={"campaignmainImage"} />
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               <img
//                                 alt="slider"
//                                 src={
//                                   createCategoriesComic.mainImage.length !== 0
//                                     ? createCategoriesComic.mainImage
//                                     : previousImage.mainImage
//                                 }
//                                 className="slider-image mt-3"
//                               ></img>
//                               {createCategoriesComic.mainImage.length !== 0 ? (
//                                 <RxCrossCircled
//                                   className="delteicon"
//                                   onClick={() => {
//                                     setCreateCategoriesComic({
//                                       ...createCategoriesComic,
//                                       mainImage: "",
//                                     });
//                                   }}
//                                 />
//                               ) : (
//                                 ""
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="col-md-6">
//                         <Media value={"campaignbackgroundimage"} />
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               <img
//                                 alt="slider"
//                                 src={
//                                   createCategoriesComic.backgroundImage
//                                     .length !== 0
//                                     ? createCategoriesComic.backgroundImage
//                                     : previousImage.backgroundImage
//                                 }
//                                 className="slider-image mt-3"
//                               ></img>
//                               {createCategoriesComic.backgroundImage.length !==
//                               0 ? (
//                                 <RxCrossCircled
//                                   className="delteicon"
//                                   onClick={() => {
//                                     setCreateCategoriesComic({
//                                       ...createCategoriesComic,
//                                       backgroundImage: "",
//                                     });
//                                   }}
//                                 />
//                               ) : (
//                                 ""
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>

//             {/* for Slider Menu */}
            
//             <Accordion className="mt-3" >
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Slider Image</Accordion.Header>
//                 <Accordion.Body>
//                   <Form.Label className="h5 font-weight-bold text-center mt-4">
//                     Slider Menu-:
//                   </Form.Label>
//                   <div>
//                     <div className="d-flex row">
//                       <div className="col-md-6">
//                         <Form.Label className="font-weight-bold">
//                           Update Your Web Sliders Images &nbsp;
//                         </Form.Label>
//                         <Media value={"campaignsliderimage"} />
//                       </div>

//                       <div className="col-md-6">
//                         <Form.Label className="font-weight-bold">
//                           Update Your Mobile Sliders Images
//                         </Form.Label>
//                         <Media value={"campaignslidermobileimage"} />
//                       </div>
//                     </div>
//                   </div>

//                   <div>
//                     {/* <Form.Label >Enter Your Email</Form.Label> */}
//                     <div className="d-flex row">
//                       <div className="col-md-6">
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               {createCategoriesComic.siderImages.length !==
//                               0 ? (
//                                 <>
//                                   {createCategoriesComic.siderImages.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           {/* <RxCrossCircled
//                                     className="delteicon"
//                                     onClick={() => {
//                                       setCreateCategoriesComic({
//                                         ...createCategoriesComic,
//                                         siderImages:
//                                           createCategoriesComic.siderImages.filter(
//                                             function (val) {
//                                               return val !== item;
//                                             }
//                                           ),
//                                       });
//                                     }}
//                                   /> */}
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               ) : (
//                                 ""
//                               )}
//                               {createCategoriesComic?.siderImages?.length !=
//                               0 ? (
//                                 ""
//                               ) : (
//                                 <>
//                                   {previousImage?.sliderPreviousImage?.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           {/* <RxCrossCircled
//                                 className="delteicon"
//                                 onClick={() => {
//                                   setPreviousImage({
//                                     ...previousImage,
//                                     sliderPreviousImage:
//                                       previousImage.sliderPreviousImage.filter(
//                                         function (val) {
//                                           return val !== item;
//                                         }
//                                       ),
//                                   });
//                                 }}
//                               /> */}
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="col-md-6">
//                         <div className="mt-3">
//                           <ul>
//                             <li>
//                               {createCategoriesComic.mobileSliderImage
//                                 .length !== 0 ? (
//                                 <>
//                                   {createCategoriesComic.mobileSliderImage.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           {/* <RxCrossCircled
//                                       className="delteicon"
//                                       onClick={() => {
//                                         setCreateCategoriesComic({
//                                           ...createCategoriesComic,
//                                           mobileSliderImage:
//                                             createCategoriesComic.mobileSliderImage.filter(
//                                               function (val) {
//                                                 return val !== item;
//                                               }
//                                             ),
//                                         });
//                                       }}
//                                     /> */}
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               ) : (
//                                 ""
//                               )}
//                               {/* if new image will uploaded then the previous image will be gone */}
//                               {createCategoriesComic.mobileSliderImage.length !=
//                               0 ? (
//                                 ""
//                               ) : (
//                                 <>
//                                   {previousImage?.sliderWebPreviousImage?.map(
//                                     (item) => {
//                                       return (
//                                         <>
//                                           <img
//                                             alt="slider"
//                                             src={item}
//                                             className="slider-image mt-3"
//                                           ></img>
//                                           {/* <RxCrossCircled
//                                       className="delteicon"
//                                       onClick={() => {
//                                         setPreviousImage({
//                                           ...previousImage,
//                                           sliderWebPreviousImage:
//                                             previousImage.sliderWebPreviousImage.filter(
//                                               function (val) {
//                                                 return val !== item;
//                                               }
//                                             ),
//                                         });
//                                       }}
//                                     /> */}
//                                         </>
//                                       );
//                                     }
//                                   )}
//                                 </>
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
            

//             {/* for About Page */}
//              <Accordion className="mt-3">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>About Content</Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For english">
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Main Heading
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Sub Heading
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPageEnglish
//                                     ?.mainHeading
//                                 }
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutMainHeading: {
//                                       ...createCategoriesComic.aboutMainHeading,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Main Heading"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPageEnglish
//                                     ?.subHeading
//                                 }
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutsubHeading: {
//                                       ...createCategoriesComic.aboutsubHeading,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder="Sub Heading"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPageEnglish
//                                     ?.buttonText
//                                 }
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonText: {
//                                       ...createCategoriesComic.buttonText,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* description row */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <UpdateEditor
//                                 value={
//                                   props?.previousData?.aboutPageEnglish
//                                     ?.description
//                                 }
//                                 from="aboutenglish"

//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Main Heading
//                               </Form.Label>
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Sub Heading
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPage?.mainHeading
//                                 }
//                                onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutMainHeading: {
//                                       ...createCategoriesComic.aboutMainHeading,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Main Heading"
//                               />
//                             </div>
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPage?.subHeading
//                                 }
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     aboutsubHeading: {
//                                       ...createCategoriesComic.aboutsubHeading,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100"
//                                 type="text"
//                                 placeholder="Sub Heading"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* button text */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.aboutPage?.buttonText
//                                 }
//                                onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     buttonText: {
//                                       ...createCategoriesComic.buttonText,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* description row */}
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <UpdateEditor
//                                 value={
//                                   props?.previousData?.aboutPage?.description
//                                 }
//                                 from="abouthindi"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                   {/* right and left slider */}
//                   <div>
//                     <div>
//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Your Left Slider Images
//                           </Form.Label>
//                         </div>

//                         <div className="col-md-6">
//                           <Form.Label className="font-weight-bold">
//                             Enter Your Button Link
//                           </Form.Label>
//                         </div>
//                       </div>
//                     </div>
//                     <div>
//                       {/* <Form.Label >Enter Your Email</Form.Label> */}
//                       <div className="d-flex row">
//                         <div className="col-md-6">
//                           <Media value={"campaignaboutLeftimage"} />
//                           <div className="mt-3">
//                             <ul>
//                               <li>
//                                 {createCategoriesComic.leftSliderImage
//                                   .length !== 0 ? (
//                                   <>
//                                     {createCategoriesComic.leftSliderImage.map(
//                                       (item) => {
//                                         return (
//                                           <>
//                                             <img
//                                               alt="slider"
//                                               src={item}
//                                               className="slider-image mt-3"
//                                             ></img>
//                                             <RxCrossCircled
//                                               className="delteicon"
//                                               onClick={() => {
//                                                 setCreateCategoriesComic({
//                                                   ...createCategoriesComic,
//                                                   leftSliderImage:
//                                                     createCategoriesComic.leftSliderImage.filter(
//                                                       function (val) {
//                                                         return val !== item;
//                                                       }
//                                                     ),
//                                                 });
//                                               }}
//                                             />
//                                           </>
//                                         );
//                                       }
//                                     )}
//                                   </>
//                                 ) : (
//                                   ""
//                                 )}
//                                 {previousImage?.aboutLeftImage?.map((item) => {
//                                   return (
//                                     <>
//                                       <img
//                                         alt="slider"
//                                         src={item}
//                                         className="slider-image mt-3"
//                                       ></img>
//                                       <RxCrossCircled
//                                         className="delteicon"
//                                         onClick={() => {
//                                           setPreviousImage({
//                                             ...previousImage,
//                                             aboutLeftImage:
//                                               previousImage.aboutLeftImage.filter(
//                                                 function (val) {
//                                                   return val !== item;
//                                                 }
//                                               ),
//                                           });
//                                         }}
//                                       />
//                                     </>
//                                   );
//                                 })}
//                               </li>
//                             </ul>
//                           </div>
//                         </div>

//                         <div className="col-md-6">
//                           <Form.Control
//                             defaultValue={
//                               props?.previousData?.aboutPage?.buttonLink
//                             }
//                             onChange={(e) =>
//                               setCreateCategoriesComic({
//                                 ...createCategoriesComic,
//                                 buttonLink: e.target.value,
//                               })
//                             }
//                             className="w-100"
//                             type="text"
//                             placeholder=" Button Url Where You want to Navigate"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>

//             {/* Story page  */}

//              <Accordion className="mt-3">
//               <Accordion.Item eventKey="0">
//                 <Accordion.Header>Story content</Accordion.Header>
//                 <Accordion.Body>
//                   <Tabs
//                     defaultActiveKey="english"
//                     id="uncontrolled-tab-example"
//                     className="mb-3"
//                   >
//                     <Tab eventKey="english" title="For english">
//                       {/* 1st row of story  */}
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Your Main Heading{" "}
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Sub Heading{" "}
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>

//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               defaultValue={
//                                 props?.previousData?.storyPageEnglish?.mainPage
//                               }
//                              onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyMainHeading: {
//                                     ...createCategoriesComic.storyMainHeading,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Enter your main  haeding "
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               defaultValue={
//                                 props?.previousData?.storyPageEnglish?.subHeading
//                               }
//                             onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storySubHeading: {
//                                     ...createCategoriesComic.storySubHeading,
//                                     forEnglish: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter your sub heading "
//                             />
//                           </div>
//                         </div>
//                       </div>
//                                 {/* button text */}
//                                  <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.storyPageEnglish?.buttonText
//                                 }
//                                  onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     storyButtonText: {
//                                       ...createCategoriesComic.storyButtonText,
//                                       forEnglish: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                       {/* story description */}

//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Story Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <UpdateEditor
//                                 value={
//                                   props?.previousData?.storyPageEnglish?.description
//                                 }
//                                 from="storyenglish"
//                               />
                              
//                             </div>
//                           </div>
//                         </div>
//                       </div>
                     
//                     </Tab>
//                     <Tab eventKey="hindi" title="For hindi">
//                       {/* 1st row of story  */}
//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Your Main Heading{" "}
//                             </Form.Label>
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Label className="font-weight-bold">
//                               Enter Sub Heading{" "}
//                             </Form.Label>
//                           </div>
//                         </div>
//                       </div>

//                       <div>
//                         <div className="d-flex row">
//                           <div className="col-md-6">
//                             <Form.Control
//                               defaultValue={
//                                 props?.previousData?.storyPage?.mainPage
//                               }
//                               onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyMainHeading: {
//                                     ...createCategoriesComic.storyMainHeading,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100 "
//                               type="text"
//                               placeholder="Enter your main  haeding "
//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Form.Control
//                               defaultValue={
//                                 props?.previousData?.storyPage?.subHeading
//                               }
//                                onChange={(e) =>
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storySubHeading: {
//                                     ...createCategoriesComic.storySubHeading,
//                                     forHindi: e.target.value,
//                                   },
//                                 })
//                               }
//                               className="w-100"
//                               type="text"
//                               placeholder="Enter your sub heading "
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Button Text
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-6">
//                               <Form.Control
//                                 defaultValue={
//                                   props?.previousData?.storyPage?.buttonText
//                                 }
//                                 onChange={(e) =>
//                                   setCreateCategoriesComic({
//                                     ...createCategoriesComic,
//                                     storyButtonText: {
//                                       ...createCategoriesComic.storyButtonText,
//                                       forHindi: e.target.value,
//                                     },
//                                   })
//                                 }
//                                 className="w-100 "
//                                 type="text"
//                                 placeholder="Button Text"
//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       {/* story description */}

//                       <div>
//                         <div>
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <Form.Label className="font-weight-bold">
//                                 Enter Your Story Description
//                               </Form.Label>
//                             </div>
//                           </div>
//                         </div>
//                         <div>
//                           {/* <Form.Label >Enter Your Email</Form.Label> */}
//                           <div className="d-flex row">
//                             <div className="col-md-12">
//                               <UpdateEditor
//                                 value={
//                                   props?.previousData?.storyPage?.description
//                                 }
//                                from="storyhindi"

//                               />
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </Tab>
//                   </Tabs>
//                    <div>
//               <div>
//                 <div className="d-flex row">
//                   <div className="col-md-6">
//                     <Form.Label className="font-weight-bold">
//                       Enter Your Background Image
//                     </Form.Label>
//                   </div>
//                   <div className="col-md-6">
//                     <Form.Label className="font-weight-bold">
//                       Enter Your Left side Images
//                     </Form.Label>
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 {/* <Form.Label >Enter Your Email</Form.Label> */}
//                 <div className="d-flex row">
//                   <div className="col-md-6">
//                     <Media value={"storyRightImage"} />
//                     <div className="mt-3">
//                       <ul>
//                         <li>
//                           <img
//                             alt="slider"
//                             src={
//                               createCategoriesComic.storyRightImage.length !== 0 ? createCategoriesComic.storyRightImage  : previousImage.storyRightImage == ""? "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg": previousImage.storyRightImage
//                             }
//                             className="slider-image mt-3"
//                           ></img>
//                           {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                           {createCategoriesComic.storyRightImage.length !==
//                           0 ? (
//                             <RxCrossCircled
//                               className="delteicon"
//                               onClick={() => {
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyRightImage: "",
//                                 });
//                               }}
//                             />
//                           ) : (
//                            ""
//                           )}
//                           {/* {
//                             previousImage.storyRightImage.length !== 0 ? <>
//                               <img alt='slider' src={previousImage.storyRightImage} className='slider-image mt-3'></img>
//                               <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage({ ...previousImage, storyRightImage: "" }) }} />
//                             </> : ""
//                           } */}
//                         </li>
//                       </ul>
//                     </div>
//                   </div>

//                   <div className="col-md-6">
//                     <Media value={"storyLeftImage"} />
//                     <div className="mt-3">
//                       <ul>
//                         <li>
//                           <img
//                             alt="slider"
//                             src={
//                               createCategoriesComic.storyLeftImage.length !== 0
//                                 ? createCategoriesComic.storyLeftImage
//                                 : previousImage.storyLeftImage
//                             }
//                             className="slider-image mt-3"
//                           ></img>
//                           {/* <RxCrossCircled className='delteicon' onClick={()=>{ setMediaImage({ ...mediaImage, sliderWebImage: mediaImage.sliderWebImage.filter(function (val)  { return val !== item })})}} /> */}
//                           {createCategoriesComic.storyLeftImage.length !== 0 ? (
//                             <RxCrossCircled
//                               className="delteicon"
//                               onClick={() => {
//                                 setCreateCategoriesComic({
//                                   ...createCategoriesComic,
//                                   storyLeftImage: "",
//                                 });
//                               }}
//                             />
//                           ) : (
//                             ""
//                           )}
//                           {/* { 
//                             previousImage.storyLeftImage.length !== 0  ? <>
//                               <img alt='slider' src={previousImage.storyLeftImage} className='slider-image mt-3'></img>
//                               <RxCrossCircled className='delteicon' onClick={() => { setPreviousImage({ ...previousImage, storyLeftImage: "" }) }} />
//                             </> : ""
//                           } */}
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div>
//               <div>
//                 <div className="d-flex row">
                  
//                   <div className="col-md-6">
//                     <Form.Label className="font-weight-bold">
//                       Enter Your Button Link
//                     </Form.Label>
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 {/* <Form.Label >Enter Your Email</Form.Label> */}
//                 <div className="d-flex row">
                 
//                   <div className="col-md-6">
//                     <Form.Control
//                       defaultValue={props?.previousData?.storyPage?.buttonLink}
//                       onChange={(e) =>
//                         setCreateCategoriesComic({
//                           ...createCategoriesComic,
//                           buttonStoryLink: e.target.value,
//                         })
//                       }
//                       className="w-100"
//                       type="text"
//                       placeholder=" Button Url Where You want to Navigate"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//                 </Accordion.Body>
//               </Accordion.Item>
//             </Accordion>
//                 {/* Testimonials page */}
//          <Accordion className="mt-3">
//           <Accordion.Item eventKey="0">
//             <Accordion.Header>Testimonials</Accordion.Header>
//             <Accordion.Body>
//               <Form.Label className="h5 font-weight-bold text-center mt-4">
//               Testimonials-:
//             </Form.Label>
//             <Updatemultipleinput value={props?.previousData?.testimonials} />
//             </Accordion.Body>
//           </Accordion.Item>
//         </Accordion>

            
            
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button
//           onClick={(e) => {
//             handleSubmit(e);
//           }}
//         >
//           Update Comic
//         </Button>
//         <Button>Reset All </Button>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
