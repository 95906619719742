import React from "react";
import { useContext, useState, useEffect } from "react";
import swal from "sweetalert";
import { Button, Card, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import {
  useGetBrandPhilosophyEnglishQuery,
  useGetBrandPhilosophyHindiQuery,
  useUpdatePhilosophyMutation,
} from "./brandPhilosophyApiSlice";
export function PhilosophyhindiVerticallyCenteredModal(props) {
  const { brandPhilosophy, setBrandPhilosphy } = useContext(Auth);
  const [updatePhilosophy] = useUpdatePhilosophyMutation();
  const { refetch } = useGetBrandPhilosophyHindiQuery();
  const [previousImage, setPreviousImage] = useState("");
  const [mobilePreviousImage, setMobilePreviousImage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      imageurl:
        brandPhilosophy?.brandPhilosophyImage?.length !== 0
          ? brandPhilosophy?.brandPhilosophyImage
          : previousImage,
      mobileimage:
        brandPhilosophy?.brandPhilosophyMobileImage?.length !== 0
          ? brandPhilosophy?.brandPhilosophyMobileImage
          : mobilePreviousImage,
    };
    updatePhilosophy(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
    setMobilePreviousImage(props?.previousData?.mobileImage);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center font-weight-bold"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Philosophy Web Image{" "}
                  </Card.Title>

                  <div className="row">
                    <div className="col-md-5">
                      <Media value="webphilosphy" />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandPhilosophy.brandPhilosophyImage.length !== 0 ? (
                          <>
                            <img
                              src={brandPhilosophy.brandPhilosophyImage}
                              className="slider-image mt-3"
                              alt="philosophyimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandPhilosphy({
                                  ...brandPhilosophy,
                                  brandPhilosophyImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandPhilosophy.brandPhilosophyImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <Card.Title
                    className="text-center font-weight-bold"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Philosophy Mobile Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value="mobophilosphy" />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandPhilosophy.brandPhilosophyMobileImage.length !==
                        0 ? (
                          <>
                            <img
                              src={brandPhilosophy.brandPhilosophyMobileImage}
                              className="slider-image mt-3"
                              alt="philosophyimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandPhilosphy({
                                  ...brandPhilosophy,
                                  brandPhilosophyMobileImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {brandPhilosophy.brandPhilosophyMobileImage.length !==
                      0 ? (
                        ""
                      ) : (
                        <>
                          {mobilePreviousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={mobilePreviousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setMobilePreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function PhilosophyenglishVerticallyCenteredModal(props) {
  const { brandPhilosophy, setBrandPhilosphy } = useContext(Auth);
  const [previousImage, setPreviousImage] = useState("");
  const [mobilePreviousImage, setMobilePreviousImage] = useState("");

  const [updatePhilosophy] = useUpdatePhilosophyMutation();
  const { refetch } = useGetBrandPhilosophyEnglishQuery();

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      imageurl:
        brandPhilosophy?.brandPhilosophyImage?.length !== 0
          ? brandPhilosophy?.brandPhilosophyImage
          : previousImage,
      mobileimage:
        brandPhilosophy?.brandPhilosophyMobileImage?.length !== 0
          ? brandPhilosophy?.brandPhilosophyMobileImage
          : mobilePreviousImage,
    };
    updatePhilosophy(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        refetch();
        swal({
          title: "Update Successfully",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  useEffect(() => {
    setPreviousImage(props?.previousData?.imageUrl);
    setMobilePreviousImage(props?.previousData?.mobileImage);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center font-weight-bold"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Philosophy Web Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value="webphilosphy" />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandPhilosophy.brandPhilosophyImage.length !== 0 ? (
                          <>
                            <img
                              src={brandPhilosophy.brandPhilosophyImage}
                              className="slider-image mt-3"
                              alt="philosophyimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandPhilosphy({
                                  ...brandPhilosophy,
                                  brandPhilosophyImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandPhilosophy.brandPhilosophyImage.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <Card.Title
                    className="text-center font-weight-bold"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Philosophy Mobile Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value="mobophilosphy" />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {brandPhilosophy.brandPhilosophyMobileImage.length !==
                        0 ? (
                          <>
                            <img
                              src={brandPhilosophy.brandPhilosophyMobileImage}
                              className="slider-image mt-3"
                              alt="philosophyimage"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setBrandPhilosphy({
                                  ...brandPhilosophy,
                                  brandPhilosophyMobileImage: "",
                                });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {brandPhilosophy.brandPhilosophyMobileImage.length !==
                      0 ? (
                        ""
                      ) : (
                        <>
                          {mobilePreviousImage?.length !== 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={mobilePreviousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setMobilePreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
