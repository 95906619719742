import { useContext } from "react";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import {
  useUpdateHeaderMutation,
  useGetHeaderEnglishQuery,
  useGetHeaderHindiQuery,
} from "./headerApiSlice";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import { useState } from "react";
import { useEffect } from "react";

export function HeaderHindiVerticallyCenteredModal(props) {
  const [updateHeader, requiredInfo] = useUpdateHeaderMutation();
  const headerHindi = useGetHeaderHindiQuery();
  const [previousImage, setPreviousImage] = useState("");
  const { header, setHeader } = useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "hindi",
      newemail:
        header?.email?.length !== 0
          ? header?.email
          : props?.previousData?.header?.email,
      newcontact:
        header?.phoneNumber?.length !== 0
          ? header?.phoneNumber
          : props?.previousData?.header?.contact,
      newbuttontext:
        header?.buttonText?.length !== 0
          ? header?.buttonText
          : props?.previousData?.header?.buttonText,
      icon: header?.iconUrl?.length !== 0 ? header?.iconUrl : previousImage,
      newlogoalt:
        header?.logoalt?.length !== 0
          ? header?.logoalt
          : props?.previousData?.header?.logoalt,
    };
    updateHeader(raw)
      .unwrap()
      .then((data) => {
        props.onHide();

        headerHindi.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  useEffect(() => {
    setPreviousImage(props?.previousData?.header?.iconUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title className="font-weight-bold">
                    Update Your Header{" "}
                  </Card.Title>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Your Email</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Phone Number </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.email}
                          onChange={(e) => {
                            setHeader({ ...header, email: e.target.value });
                          }}
                          className="w-100 "
                          type="email"
                          placeholder="Enter Your Header Email"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.contact}
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              phoneNumber: e.target.value,
                            });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Header Phone Number "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Button Text</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Logo Alt</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.buttonText}
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              buttonText: e.target.value,
                            });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Button Text "
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.logoalt}
                          onChange={(e) => {
                            setHeader({ ...header, logoalt: e.target.value });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Logo Alt "
                        />
                      </div>
                    </div>
                  </div>
                  <Card.Title className="font-weight-bold mt-3">
                    Choose Your Logo
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {header.iconUrl.length !== 0 ? (
                          <>
                            <img
                              src={header.iconUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setHeader({ ...header, iconUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {header.iconUrl.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Card.Title>Enter Your Script Here </Card.Title>
                      <Form.Control as="textarea" rows={4} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export function HeaderEnglishVerticallyCenteredModal(props) {
  const [updateHeader] = useUpdateHeaderMutation();
  const headerEnglish = useGetHeaderEnglishQuery();

  const [previousImage, setPreviousImage] = useState("");
  const { header, setHeader } = useContext(Auth);

  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      language: "english",
      newemail:
        header?.email?.length !== 0
          ? header?.email
          : props?.previousData?.header?.email,
      newcontact:
        header?.phoneNumber?.length !== 0
          ? header?.phoneNumber
          : props?.previousData?.header?.contact,
      newbuttontext:
        header?.buttonText?.length !== 0
          ? header?.buttonText
          : props?.previousData?.header?.buttonText,
      icon: header?.iconUrl?.length !== 0 ? header?.iconUrl : previousImage,
      newlogoalt:
        header?.logoalt?.length !== 0
          ? header?.logoalt
          : props?.previousData?.header?.logoalt,
    };
    updateHeader(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        headerEnglish.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };

  useEffect(() => {
    setPreviousImage(props?.previousData?.header?.iconUrl);
  }, [props]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title>Update Your Header </Card.Title>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Your Email</Form.Label>
                      </div>
                      <div className="col-md-6">
                        <Form.Label>Phone Number </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.email}
                          onChange={(e) => {
                            setHeader({ ...header, email: e.target.value });
                          }}
                          className="w-100 "
                          type="email"
                          placeholder="Enter Your Header Email"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.contact}
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              phoneNumber: e.target.value,
                            });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Header Phone Number "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-4">
                        <Form.Label> Choose Language</Form.Label>
                      </div>
                      <div className="col-md-4">
                        <Form.Label>Button Text</Form.Label>
                      </div>
                      <div className="col-md-4">
                        <Form.Label>Logo Alt</Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    {/* <Form.Label >Enter Your Email</Form.Label> */}
                    <div className="d-flex row">
                      <div className="col-md-4">
                        <Form.Select
                          onChange={(e) => {
                            setHeader({ ...header, language: e.target.value });
                          }}
                          aria-label="Default select example"
                        >
                          <option value="Hindi">Hindi</option>
                          <option value="English" selected>
                            English
                          </option>
                        </Form.Select>
                      </div>
                      <div className="col-md-4">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.buttonText}
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              buttonText: e.target.value,
                            });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Button Text "
                        />
                      </div>
                      <div className="col-md-4">
                        <Form.Control
                          defaultValue={props?.previousData?.header?.logoalt}
                          onChange={(e) => {
                            setHeader({ ...header, logoalt: e.target.value });
                          }}
                          className="w-100"
                          type="text"
                          placeholder="Enter Your Logo Alt "
                        />
                      </div>
                    </div>
                  </div>
                  <Card.Title>Choose Your Logo</Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={props?.value} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {header.iconUrl.length !== 0 ? (
                          <>
                            <img
                              src={header.iconUrl}
                              className="slider-image mt-3"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setHeader({ ...header, iconUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {header.iconUrl.length !== 0 ? (
                        ""
                      ) : (
                        <>
                          {previousImage?.length != 0 ? (
                            <div>
                              <div className="nav-link" href="#">
                                <img
                                  alt="slider"
                                  src={previousImage}
                                  className="slider-image mt-3"
                                ></img>
                                <RxCrossCircled
                                  className="delteicon"
                                  onClick={() => {
                                    setPreviousImage([]);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Card.Title>Enter Your Script Here </Card.Title>
                      <Form.Control as="textarea" rows={4} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Update{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
