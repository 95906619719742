import React from 'react';

import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { BrandSliderVerticallyCenteredModal } from '../../Home/Brandslider/Addslider';

function Brandslidermodel() {

    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Add New Slider
            </Button>
            <BrandSliderVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandslidermodel;