import React, { useContext } from 'react';
import {  useNavigate } from 'react-router-dom';
import image from '../../images/girl-reading.png';
import  { Auth } from '../../contextapi/Authcontext/Authcontext';
import { useState } from 'react';
import Loader from '../GlobalComponent/Loader/Loader';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import { useAgentloginMutation } from '../../features/auth/authApiSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from 'sweetalert';
import { Card } from 'react-bootstrap';

function AgentLogin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [login, { isloading }] = useAgentloginMutation()
  const { adminLogin } = useContext(Auth)
  const Swal = require('sweetalert2')
  const { loader, setLoader } = useContext(Auth)
  const [userAdmin, setUserAdmin] = useState({
    userEmail: "",
    password: ""
  })
  const handlelogin = async (event) => {
    setLoader(true)
    const email = userAdmin.userEmail
    const password = userAdmin.password
    event.preventDefault()
    if (!userAdmin.userEmail && !userAdmin.password) {
      toast.warn("Username & Password Required")
      return
    } else if (!userAdmin.userEmail) {
      toast.warn("Username Required");
      return
    } else if (!userAdmin.password) {
      toast.warn("Password Required");
      return
    }

    try {
      const userData = await login({ email, password }).unwrap()
      dispatch(setCredentials({ ...userData }))
      setLoader(false);
      navigate("/agentcomicwriter");
      localStorage.setItem("userToken", JSON.stringify(userData.accesstoken));
      localStorage.setItem("userAccessToken", JSON.stringify(userData.refreshtoken));
      localStorage.setItem("userRole", JSON.stringify(userData.agent.role));
      // localStorage.setItem("userToken",JSON.stringify(userData))
 
      swal({
        title: "Login Successfully!",
        icon: "success",
        dangerMode: true,
      })

    } catch (err) {
      setLoader(false)
      if (!err?.status || err?.status === 'FETCH_ERROR') {
        swal({
          title:err.data.message,
          icon: "error",
          dangerMode: true,
        })
  
      } else if (err.status === 400) {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        })
      } else if (err.status === 401) {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        })
      } else {
        swal({
          title: err.data.message,
          icon: "error",
          dangerMode: true,
        })
      }
    }

  }
  return (
    <>
      {
        loader ? <Loader /> :
          <div className='loginbackgroundimage bg-warning'>
            <div className="container">
              <div className="LoginDiv">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 bg-transparent my-5">
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-lg-6 m-auto bg-comic bg-white p-0">
                          <div className="p-5">
                            <div className="text-center">
                              <h1 className="h2 text-gray-900 mb-4">Agent Login</h1>
                            </div>
                            {/* <hr/> */}
                            <form className="user mt-5">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control form-control-user"
                                  id="exampleInputEmail"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                  onChange={(e) => setUserAdmin({ ...userAdmin, userEmail: e.target.value })}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control form-control-user"
                                  id="exampleInputPassword"
                                  placeholder="Password"
                                  onChange={(e) => setUserAdmin({ ...userAdmin, password: e.target.value })}
                                />
                              </div>
                              {/* <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div> */}
                              <button onClick={(e) => handlelogin(e)} disabled={userAdmin.userEmail != "" && userAdmin.password != ""  ? false : true } className="mt-4 btn btn-primary btn-user btn-block">Sign In</button>
                              {/* <hr /> */}
                              {/* <a onClick={() => { navigate('/resetpass') }} className="mt-3 text-right btn-block"> Forgot Password</a> */}
                            </form>
                          </div>
                          <img src={image} alt="Loginimage" className='img-fluid w-75' />
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </>
  );
}

export default AgentLogin;