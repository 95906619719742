import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { ProductHindiVerticallyCenteredModal } from '../../Home/Bailkholuproduct/Updateproduct';

function Brandproductmodelhindi({previousData,value}) {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <ProductHindiVerticallyCenteredModal
                previousData={previousData}
                value ={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandproductmodelhindi;