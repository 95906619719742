import React from 'react';
import Brandstorymodel from '../../GlobalComponent/Model/Brandstorymodelhindi';
import { Table } from 'react-bootstrap';
import { MdFileDownloadDone, MdDeleteOutline } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useGetBrandStoryEnglishQuery, useGetBrandStoryHindiQuery } from './brandStoryApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import Brandstorymodelhindi from '../../GlobalComponent/Model/Brandstorymodelhindi';
import Brandstorymodelenglish from '../../GlobalComponent/Model/Brandstorymodelenglish';

function Brandstory() {
  const brandStoryEnglish = useGetBrandStoryEnglishQuery()
  const brandStoryHindi = useGetBrandStoryHindiQuery()
  return (
    <>
      <div className="container-fluid">
        <div>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Brand Story Section For Hindi">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Story Section For Hindi</h1>
              </div>
              <div className='row '>
                <div className=' col-md-12'>
                  <Table responsive="sm" hover>
                    <thead>
                      <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>SubHeading</th>
                        <th>New Text Button</th>
                        <th>Image Url</th>
                        <th>New Background Image Url</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        brandStoryHindi?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{brandStoryHindi?.data?.story?.heading}</td>
                            <td>{brandStoryHindi?.data?.story?.subheading}</td>
                            <td>{brandStoryHindi?.data?.story?.buttonText}</td>
                            <td><img style={{ width: "150px", height: "100px" }} src={brandStoryHindi?.data?.story?.imageUrl}></img></td>
                            <td><img style={{ width: "150px", height: "100px" }} src={brandStoryHindi?.data?.story?.backgroundImage}></img></td>
                            <td><Brandstorymodelhindi previousData={brandStoryHindi?.data?.story}  /></td>
                          </tr>
                      }
                    
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="profile" title="Brand Story Section For English">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Brand Story Section For English</h1>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Table responsive="sm" hover>
                    <thead>
                    <tr>
                        <th>S.NO</th>
                        <th>Heading</th>
                        <th>SubHeading</th>
                        <th>New Text Button</th>
                        <th>Image Url</th>
                        <th>New Background Image Url</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                        brandStoryEnglish?.isLoading ? <Loader /> :
                          <tr>
                            <td>1</td>
                            <td>{brandStoryEnglish?.data?.story?.heading}</td>
                            <td>{brandStoryEnglish?.data?.story?.subheading}</td>
                            <td>{brandStoryEnglish?.data?.story?.buttonText}</td>
                            <td><img style={{ width: "150px", height: "100px" }} src={brandStoryEnglish?.data?.story?.imageUrl}></img></td>
                            <td><img style={{ width: "150px", height: "100px" }} src={brandStoryEnglish?.data?.story?.backgroundImage}></img></td>
                            <td><Brandstorymodelenglish previousData={brandStoryEnglish?.data?.story}  /></td>
                          </tr>
                      }

                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default Brandstory;