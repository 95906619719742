import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import {
  useGetAllMediaQuery,
  useDeleteMediaMutation,
} from "./brandMediaApiSlice";
import Brandmediahindimodel from "../../GlobalComponent/Model/Brandmediahindimodel";
import Loader from "../../GlobalComponent/Loader/Loader";
import Brandmediaaddmodel from "../../GlobalComponent/Model/Brandmediaaddmodel";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
function Brandmedia() {
  const { Swal } = useContext(Auth);
  const getAllMedia = useGetAllMediaQuery();
  const [deleteMedia] = useDeleteMediaMutation();
  const deleteHandler = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteMedia(id)
          .unwrap()
          .then((data) => {
            getAllMedia.refetch();
          })
          .catch((error) => {
            alert(error);
          });
      }
    });
  };
  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Home Media Section</h1>
          <div className="text-right">
          <Brandmediaaddmodel value={"addmedia"} />
          </div>
        </div>
        <div className="row ">
          <div className=" col-md-12">
            <Table responsive="sm" hover className="w-100 table">
              <thead className="bg-black text-white">
                <tr>
                  <th>S.NO</th>
                  <th>Title</th>
                  <th>Heading</th>
                  <th>Url</th>
                  <th>Description</th>
                  <th>Source</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {getAllMedia?.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    {getAllMedia?.data?.posts.map((item, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{`${item.heading.slice(0, 10)}....`}</td>
                            <td>{`${item.url.slice(0, 10)}....`}</td>
                            <td>{`${item.description.slice(0, 10)}....`}</td>
                            <td>{item.source}</td>
                            <td>{`${item.createdAt.slice(0, 10)}`}</td>
                            <td className="d-flex">
                              <Brandmediahindimodel
                                previousData={item}
                                value={"brandmedia"}
                              />
                              <MdDelete
                                onClick={() => {
                                  deleteHandler(item._id);
                                }}
                                className="h4"
                              />
                            </td>
                            {/* <td><Brandmediahindimodel previousData={item} value={"brandmedia"} /></td> */}
                          </tr>
                        </>
                      );
                    })}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Brandmedia;
