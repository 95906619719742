import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext'
import { useContext } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import './model.css'
import { BrandAboutHindiVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
function Brandaboutmodelhindi({ previousData, value }) {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <BrandAboutHindiVerticallyCenteredModal
                value={value}
                previousData={previousData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}
export default Brandaboutmodelhindi;