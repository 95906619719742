import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Form, Button, Container, Row, Col, Modal } from "react-bootstrap";

const VoucherEdit = ({ VoucherID }) => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  const [voucherData, setVoucherData] = useState({});
  const [changes, setChanges] = useState({});
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts/${VoucherID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
        }
      );
      const result = await response.json();
      setVoucherData(result.discount);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [VoucherID]);

  const handleChange = (field, value) => {
    setVoucherData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setChanges((prevChanges) => ({
      ...prevChanges,
      [field]: value,
    }));
  };

  const handleRuleChange = (field, value) => {
    setVoucherData((prevData) => ({
      ...prevData,
      rule: {
        ...prevData.rule,
        [field]: value,
      },
    }));
    setChanges((prevChanges) => ({
      ...prevChanges,
      rule: {
        ...prevChanges.rule,
        [field]: value,
        id: voucherData.rule.id,
      },
    }));
  };

  const handleMetadataChange = (field, value) => {
    setVoucherData((prevData) => ({
      ...prevData,
      metadata: {
        ...prevData.metadata,
        [field]: value,
      },
    }));
    setChanges((prevChanges) => ({
      ...prevChanges,
      metadata: {
        ...prevChanges.metadata,
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const discountData = {
      regions: ["reg_01HBJS9WBK5R58KD5NA233GFEW"],
      // id:voucherData.id,
      ...changes,
      ...(changes.starts_at && {
        starts_at: new Date(changes.starts_at).toISOString(),
      }),
      ...(changes.ends_at && {
        ends_at: new Date(changes.ends_at).toISOString(),
      }),
      ...(changes.usage_limit && {
        usage_limit: parseInt(changes.usage_limit),
      }),
      rule: {
        ...(changes.rule?.value && {
          value: parseInt(changes.rule?.value),
        }),
        ...(changes.rule?.description && {
          description: changes.rule?.description,
        }),
        id: voucherData.id,
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/discounts/${VoucherID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify(discountData),
        }
      );

      if (!response.ok) {
        console.log(response);
        throw new Error("Error updating discount");
      }

      setSuccess("Discount updated successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 30);
    } catch (error) {
      setError(error.message);
    }
  };

  //   const StartDate = format(new Date(voucherData?.starts_at), 'MM/DD/YYYY:HH')
  // console.log("StartDate" + StartDate)
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <span onClick={handleShow}>Edit</span>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Discount</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  {error && <div className="alert alert-danger">{error}</div>}
                  {success && (
                    <div className="alert alert-success">{success}</div>
                  )}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formType">
                      <Form.Label>Discount type</Form.Label>
                      <div className="d-flex justify-content-start align-item-start Vouchertype">
                        <Form.Check
                          type="radio"
                          className="border-0"
                          label="Percentage"
                          name="typeOptions"
                          id="typePercentage"
                          value="percentage"
                          checked={voucherData.rule?.type === "percentage"}
                          onChange={(e) =>
                            handleRuleChange("type", e.target.value)
                          }
                        />
                        {/* <Form.Check
                          type="radio"
                          className="border-0"
                          label="Fixed"
                          name="typeOptions"
                          id="typeFixed"
                          value="fixed"
                          checked={voucherData.rule?.type === "fixed"}
                          onChange={(e) =>
                            handleRuleChange("type", e.target.value)
                          }
                        /> */}
                      </div>
                    </Form.Group>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formCode">
                          <Form.Label>Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter discount code"
                            value={voucherData.code || ""}
                            onChange={(e) =>
                              handleChange("code", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formValue">
                          <Form.Label>Value</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter discount value"
                            value={voucherData.rule?.value || ""}
                            onChange={(e) =>
                              handleRuleChange("value", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId="formDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter discount description"
                        value={voucherData.rule?.description || ""}
                        onChange={(e) =>
                          handleRuleChange("description", e.target.value)
                        }
                        required
                      />
                    </Form.Group>

                    <Row>
                      <Col lg={6}>
                        {/* <Form.Group controlId="formStartsAt">
                          <Form.Label>
                            Start Date {voucherData?.starts_at}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            value={voucherData.starts_at || ""}
                            onChange={(e) =>
                              handleChange("starts_at", e.target.value)
                            }
                            required
                          />
                        </Form.Group> */}
                        <Form.Group controlId="formStartsAt">
                          <Form.Label>Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={formatDate(voucherData.starts_at) || ""}
                            onChange={(e) =>
                              handleChange("starts_at", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formEndsAt">
                          <Form.Label>End Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={formatDate(voucherData.ends_at) || ""}
                            onChange={(e) =>
                              handleChange("ends_at", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formUsageLimit">
                          <Form.Label>Usage Limit</Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Enter usage limit"
                            value={voucherData.usage_limit || ""}
                            onChange={(e) =>
                              handleChange("usage_limit", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formChannel">
                          <Form.Label>Channel</Form.Label>
                          <Form.Control
                            as="select"
                            value={voucherData.metadata?.channel || "both"}
                            onChange={(e) =>
                              handleMetadataChange("channel", e.target.value)
                            }
                            required
                          >
                            <option value="both">Both</option>
                            <option value="website">Website</option>
                            <option value="app">App</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <Form.Group controlId="formUseType">
                          <Form.Label>Use Type</Form.Label>
                          <Form.Control
                            as="select"
                            value={voucherData.metadata?.usetype || "public"}
                            onChange={(e) =>
                              handleMetadataChange("usetype", e.target.value)
                            }
                            required
                          >
                            {/* <option value="public">Select</option> */}
                            <option value="public">Public</option>
                            <option value="restricted">Restricted</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group controlId="formGroupId">
                          <Form.Label>Group ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter group ID"
                            value={voucherData.metadata?.group_id || "na"}
                            onChange={(e) =>
                              handleMetadataChange("group_id", e.target.value)
                            }
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button variant="primary" type="submit" className="mt-3">
                      Update Discount
                    </Button>
                  </Form>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default VoucherEdit;
