import React from 'react';
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { TvcVerticallyCenteredModal } from '../../Home/Brandtvc/Addtvc';
function Brandaddtvcmodel() {
    const [modalShow, setModalShow]=useState(false)
    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Add New TVC
            </Button>
            <TvcVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandaddtvcmodel;