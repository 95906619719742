import React, { useState, useContext } from "react";
// import image from "../../images/2023-05-02.png";
import image from "../../images/login/nourish-login1.jpg";
import logo from "../../images/nslogo.svg";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../contextapi/Authcontext/Authcontext";
import Loader from "../GlobalComponent/Loader/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

const Login = () => {
  const { setLoader, loader, Swal } = useContext(Auth);
  const navigate = useNavigate();
  const [userAdmin, setUserAdmin] = useState({
    userEmail: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoader(true);
    if (!userAdmin.userEmail && !userAdmin.password) {
      toast.warn("Username & Password Required");
      setLoader(false);
      return;
    } else if (!userAdmin.userEmail) {
      toast.warn("Username Required");
      setLoader(false);
      return;
    } else if (!userAdmin.password) {
      toast.warn("Password Required");
      setLoader(false);
      return;
    }

    try {
      const response = await fetch("https://api.nourishstore.in/api/admin/auth/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userAdmin.userEmail,
          password: userAdmin.password,
        }),
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const userData = await response.json();

      localStorage.setItem("userRole", "NSadmin");
      localStorage.setItem("NStoken", JSON.stringify(userData.access_token));
      setLoader(false);
      navigate("/nourish-dashboard");

      // if (userData.role === "store_manager") {
      //   navigate("/order");
      // } else if (["admin", "editor", "Super_Admin"].includes(userData.role)) {
      //   navigate("/dashboard");
      // }

      swal({
        title: "Login Successfully!",
        icon: "success",
        dangerMode: true,
      });
    } catch (err) {
      setLoader(false);
      setError(err.message);
      swal({
        title: err.message,
        icon: "error",
        dangerMode: true,
      });
    }
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="loginbackgroundimage">
          <div className="container">
            <div className="LoginDiv">
              <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-lg-6">
                        <img
                          src={image}
                          alt="Loginimage"
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-6">
                        <div className="p-5">
                          <div className="text-center">
                          <img
                          src={logo}
                          alt="Loginimage"
                          className="img-fluid"
                        />
                            {/* <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1> */}
                           
                          {/* {error && <small className="error fs-6 text-danger mt-5">{error}</small>}  */}
                          </div>
                          <form className="user mt-5 text-center" onSubmit={handleLogin}>
                         
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control form-control-user"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                onChange={(e) =>
                                  setUserAdmin({
                                    ...userAdmin,
                                    userEmail: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="password"
                                className="form-control form-control-user"
                                id="exampleInputPassword"
                                placeholder="Password"
                                onChange={(e) =>
                                  setUserAdmin({
                                    ...userAdmin,
                                    password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <button
                              type="submit"
                              className="w-50 mt-4 btn btn-primary btn-user btn-block"
                              disabled={
                                userAdmin.userEmail !== "" &&
                                userAdmin.password !== ""
                                  ? false
                                  : true
                              }
                            >
                              Sign In
                            </button>
                            {/* <a
                              onClick={() => {
                                navigate("/resetpass");
                              }}
                              className="mt-3 text-right btn-block"
                            >
                              {" "}
                              Forgot Password
                            </a> */}
                          </form>
                          
                        </div>
                        <div className=""> <a
                              onClick={() => {
                                navigate("/");
                              }}
                              className="mt-3 text-center text-black btn-block"
                            >
                              {" "}
                              Click Here To Login Bailkolhu Dashboard
                            </a></div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
