import { apiSlice } from "../../component/app/api/apiSlice";
import { adminEndLogout, adminEndPoint, comicLoginEndPoint, changepass, forgetpass } from "../../contextapi/Endpoints/Endpoints";
import { store } from '../../component/app/store'
const token = store.getState().auth
export const authApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => {
                return {
                    url: adminEndPoint,
                    method: 'POST',
                    body: credentials,
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            }
        }),
        agentlogin: builder.mutation({
            query: credentials => {
                return {
                    url: comicLoginEndPoint,
                    method: 'POST',
                    body: credentials,
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            }
        }),
        resetpassword: builder.mutation({
            query: (credentials) => {
                return {
                    url: changepass,
                    method: 'PATCH',
                    body: credentials,
                }
            }
        }),
        forgetpass: builder.mutation({
            query: (email) => {
                return {
                    url: forgetpass,
                    method: 'PATCH',
                    body: email,
                }
            }
        }),
        logout: builder.mutation({
            query: () => {
                return {
                    url: adminEndLogout,
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token.refreshToken}`
                    }
                }
            }
        })

    })
})
export const { useLoginMutation, useAgentloginMutation, useResetpasswordMutation, useForgetpassMutation, useLogoutMutation } = authApiSlice
