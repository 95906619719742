import React, { useMemo } from "react";
import { useGetAllFreeComicQuery } from "./formApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

function Getfreecomic() {
  const { data, isLoading, isFetching, refetch } = useGetAllFreeComicQuery();
  // Download csv Code Start

  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) {
      return "";
    }

    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); // Use array instead of data

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  const columns = [
    {
      name: "S No.",
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "Origin",
      selector: (row) => row.origin,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Phone",
      selector: (row) => row.mobile,
    },
    {
      name: "Address",
      wrap:true,
      selector: (row) => row.address,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "District",
      selector: (row) => row.district,
    },
    {
      name: "State",
      selector: (row) => row.state,
    },
    {
      name: "Pincode",
      selector: (row) => row.pincode,
    },
    {
      name: "Language",
      selector: (row) => row.language,
    },
  ];
  const queries = data?.subs || [];
  const Tabledata = queries.slice().reverse().map((item, index) => ({
    i: index + 1,
    origin: item.origin,
    // item.origin !== undefined ? (
    //   <small className="alert alert-success" role="alert">
    //     {" "}
    //     {item.origin}{" "}
    //   </small>
    // ) : (
    //   <span className="alert alert-danger"> {"BK Website"}</span>
    // ),
    createdAt: format(new Date(item.createdAt), "MM/dd/yyyy HH:mm:ss"),
    name: item.name,
    mobile: item.mobile,
    email: item.email,
    address: item.address,
    city: item.city,
    district: item.district,
    state: item.state,
    pincode: item.pincode,
    language: item.language,
  }));

  function downloadCSV() {
    const csvContent = [
      "\uFEFF" + ["S No.", "Date", "Origin", "Name", "Email", "Phone", "Address", "City", "District", "State", "Pincode", "Language"].join(","),
      ...Tabledata.map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? `"${value}"` : value))
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "FreeComicData.csv");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Get Free Comic</h1>
        
        <button
          className="btn btn-sm btn-primary shadow-sm"
          onClick={downloadCSV}
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Export Data
        </button>
      </div>
      <div className="row">
        <div className="col-md-12">
          {isFetching && isLoading ? (
            <Loader />
          ) : (
            <DataTable
                className="TableNs"
              columns={columns}
              data={Tabledata}
              pagination
              defaultSortAsc={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Getfreecomic;
