import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Page404() {
    const navigate =useNavigate()
    return (
        <div className="container-fluid">
            <div className="text-center mt-5 pt-5">
                <div className="error mx-auto" data-text="404">404</div>
                <p className="lead text-gray-800 mb-5">Page Not Found</p>
                <p className="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                {/* <Button onClick={()=>{navigate('/')}}>&larr; Back to Dashboard</Button> */}
            </div>

        </div>
    );
}
export default Page404;