export const getSlug = (value) => {
    if(value.split(' ').length == 1){
        return value
    }else{
        if(value.split(' ').join('-').includes("?")){
            return value.split(' ').join('-').replace('?','')
        }
        return (value.split(' ').join('-'))
    }
}
