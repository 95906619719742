import { contactEndPoints,contactUpdateEndPoints, } from "../../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../../app/api/apiSlice";
export const brandContactApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContact: builder.query({
            query: (lang) => {
                return {
                    url: `${contactEndPoints}/${lang}`,
                    method: 'GET',
                }
            }
        }),
        updateContact: builder.mutation({
            query: (raw) => {
                return {
                    url: contactUpdateEndPoints,
                    method: 'PATCH',
                    body: raw,
                }
            }
        }),
    })
})
export const { useGetContactQuery,useUpdateContactMutation } = brandContactApiSlice