import React, { useState, useEffect } from "react";
import "./dashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pageredirect from "./PageRedrection";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Card } from "react-bootstrap";

function Dashboard() {
  // const [Orderdata, setData] = useState([]);
  // const [filter, setFilter] = useState({
  //   method: "today",
  //   method: "yesterday",
  //   method: "monthwise",
  //   method: "yearwise",
  //   month: "1",
  //   month: "2",
  //   month: "3",
  //   month: "4",

  //   });
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  const [Orderdata, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  // const formatDate = (date) => {
  //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   return new Intl.DateTimeFormat("en-US", options).format(date);
  // };

  const formatDate = (date) => {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [filter, setFilter] = useState({
    method: "today",
    fromDate: formatDate(startDate),
    toDate: formatDate(endDate),
    year: "",
  });

  const medusatoken = JSON.parse(localStorage.getItem("medusatoken"));

  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${medusatoken}`,
    },
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://dev.bailkolhu.com/admin/report/consolidated?method=today",
          `${process.env.REACT_APP_BASE_URL}admin/report/consolidated?method=${filter.method}&from_date=${filter.fromDate}&to_date=${filter.toDate}`,
          requestOptions
        );
        const result = await response.json();
        setData(result);

        // console.log("SetData" + JSON.stringify(result));
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [filter]); // Fetch data whenever the filter changes

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  return (
    <>
      <div className="container px-4 row ">
        <div className="col-lg-6 mb-3  ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Visitors Overview
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?forceView=1&viewDataTable=sparklines&module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=VisitsSummary&actionToWidgetize=get&idSite=1&period=day&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-6 mb-3  ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Ecommerce OVerview
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&containerId=EcommerceOverview&disableLink=1&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=1&period=year&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-12 mb-3  ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Ecommerce Sale
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?forceView=1&viewDataTable=tableGoals&module=Widgetize&action=iframe&documentationForGoalsPage=1&idGoal=ecommerceOrder&segmented_visitor_log_segment_suffix=visitEcommerceStatus%3D%3Dordered&disableLink=1&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getReferrerType&idSite=1&period=year&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-12 mb-3 ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Behaviour Transition
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="650"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Transitions&actionToWidgetize=getTransitions&idSite=1&period=month&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-12 mb-3  ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Pages Overview
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350px"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Actions&actionToWidgetize=getPageUrls&idSite=1&period=day&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-8 mb-3 ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Referrer
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Referrers&actionToWidgetize=getAll&idSite=1&period=day&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="col-lg-4 mb-3 ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Visitor map
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="350px"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=visitorMap&idSite=1&period=day&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-12 mb-3 ">
          <Card>
            <Card.Body>
              <CardHeader className="text-center font-weight-bold">
                Ecommerce Log
              </CardHeader>
              <div id="widgetIframe">
                <iframe
                  width="100%"
                  height="450"
                  src="https://web-analytics.leadsbrandconnect.com/index.php?module=Widgetize&action=iframe&disableLink=1&widget=1&moduleToWidgetize=Ecommerce&actionToWidgetize=getEcommerceLog&idSite=1&period=day&date=yesterday&token_auth=c2374a6ca223eeed2979a88c58b44c77"
                  scrolling="yes"
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      {/* <p>Selected date: {formatDate(startDate)}</p>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
              <a
                href="#"
                className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              >
                <i className="fas fa-download fa-sm text-white-50"></i> Generate
                Report
              </a>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                          (Monthly)
                        </div>
                        Total Sales
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          4000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          (Monthly)
                        </div>
                        Total Orders
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          15,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                          (Monthly)
                        </div>
                        Total customer
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          15,000
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                          Pending Requests
                        </div>
                        Total Pending Orders
                        <div className="h5 mb-0 font-weight-bold text-gray-800">
                          18
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-comments fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label>
          Select Method:
          <select
            name="method"
            value={filter.method}
            onChange={handleFilterChange}
          >
            <option value="today">today</option>
            <option value="yesterday">yesterday</option>
            <option value="monthwise">Monthwise</option>
            <option value="yearwise">Yearwise</option>
            <option value="custom">custom</option>
              </select>
        </label>

        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
        />
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
        />

     
        <table>
          <thead>
            <tr>
              <th>Count</th>
              <th>Order Year</th>
              <th>Year Total</th>
            </tr>
          </thead>
          <tbody>
            ksjdfkjhsdf
             {Orderdata.data &&
              Orderdata.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.count}</td>
                  <td>{item.order_year}</td>
                  <td>{item.year_total}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
    </>
  );
}

export default Dashboard;
