import { mediaEndPoint } from "../../../contextapi/Endpoints/Endpoints";
import { authApiSlice } from "../../../features/auth/authApiSlice";
import { apiSlice } from "../../app/api/apiSlice";

export const mediaApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllImages: builder.query({
            query: () => ({
                url: mediaEndPoint,
                method: 'GET'
            })
        }),
        importImage: builder.mutation({
            query: (newImages) => {
                return {
                    url: mediaEndPoint,
                    method: 'POST',
                    body: newImages,
                }
            }
        }),
        deleteImages: builder.mutation({
            query: (raw) => {
                return {
                    url: mediaEndPoint,
                    method: 'DELETE',
                    body: raw
                }
            }
        })
    })
})
export const { useGetAllImagesQuery, useImportImageMutation, useDeleteImagesMutation, } = authApiSlice