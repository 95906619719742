import { useContext } from "react";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useUpdateFooterMutation, useGetFooterQuery } from "./footerApiSlice";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";

export function FooterVerticallyCenteredModal(props) {
  const { contact, setContact } = useContext(Auth);
  const getFooter = useGetFooterQuery();
  const [updateFooter] = useUpdateFooterMutation();
  const id = props.previousData._id;
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      newfacebook:
        contact.facebook?.length !== 0
          ? contact.facebook
          : props?.previousData?.facebook,
      newtwitter:
        contact.twitter?.length !== 0
          ? contact.twitter
          : props?.previousData?.twitter,
      newinstagram:
        contact.instragram?.length !== 0
          ? contact.instragram
          : props?.previousData?.instagram,
      newyoutube:
        contact.youtube?.length !== 0
          ? contact.youtube
          : props?.previousData?.youtube,
      newlinkedin:
        contact.linkedin?.length !== 0
          ? contact.linkedin
          : props?.previousData?.linkedin,
      newwiki:
        contact.wikipedia?.length !== 0
          ? contact.wikipedia
          : props?.previousData?.wiki,
      newtrademark: "",
    };
    updateFooter({ raw, id })
      .unwrap()
      .then((data) => {
        props.onHide();
        getFooter.refetch();
        swal({
          title: data.message,
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "success",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <Card style={{ width: "40rem" }}>
                <Card.Body md="3">
                  <Card.Title>Update Your New Footer </Card.Title>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Your Facebook Link</Form.Label>
                      </div>

                      <div className="col-md-6">
                        <Form.Label>Enter Your Twitter Link </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.facebook}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              facebook: e.target.value,
                            });
                          }}
                          className="w-100 "
                          type="text"
                          placeholder=" Facebook Link"
                        />
                      </div>

                      <div className="col-md-6">
                        <Form.Control
                          defaultValue={props?.previousData?.twitter}
                          onChange={(e) => {
                            setContact({ ...contact, twitter: e.target.value });
                          }}
                          className="w-100"
                          type="text"
                          placeholder=" Twitter Link   "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Your Instragram Link</Form.Label>
                      </div>

                      <div className="col-md-6">
                        <Form.Label>Enter Your Youtube Link </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100 "
                          defaultValue={props?.previousData?.instagram}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              instragram: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder=" Facebook Link"
                        />
                      </div>

                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={props?.previousData?.youtube}
                          onChange={(e) => {
                            setContact({ ...contact, youtube: e.target.value });
                          }}
                          type="text"
                          placeholder=" Youtube Link   "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Label>Enter Your Linkedin Link</Form.Label>
                      </div>

                      <div className="col-md-6">
                        <Form.Label>Enter Your wikipedia Link </Form.Label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex row">
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100 "
                          defaultValue={props?.previousData?.linkedin}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              linkedin: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder="Linkedin Link"
                        />
                      </div>
                      <div className="col-md-6">
                        <Form.Control
                          className="w-100"
                          defaultValue={props?.previousData?.wiki}
                          onChange={(e) => {
                            setContact({
                              ...contact,
                              wikipedia: e.target.value,
                            });
                          }}
                          type="text"
                          placeholder=" Wikipedia Link"
                        />
                      </div>
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Card.Title>Enter Your Script Here </Card.Title>
                      <Form.Control as="textarea" rows={4} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
