import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Authcontext, { Auth } from '../../../contextapi/Authcontext/Authcontext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import './multiple.css'
Quill.register('modules/imageResize', ImageResize);
function Updatemultipleinput({ value }) {
  const { updateTestimonial, setUpdateTestimonial } = useContext(Auth)
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];
  const onhandleInput = (newValue, index) => {
    const { name, value } = newValue
    const tempUpdateTestimonial = { ...updateTestimonial }
    let newListing = [...tempUpdateTestimonial.listing][index]
    newListing = { ...newListing, [name]: value }
    let tempList = [...tempUpdateTestimonial.listing]
    tempList[index] = newListing
    setUpdateTestimonial((prevState) => ({ ...prevState, listing: [...tempList] }))
  }
  const onhandleEditor = (newValue, name, index) => {
    const tempUpdateTestimonial = { ...updateTestimonial }
    let newListing = [...tempUpdateTestimonial.listing][index]
    newListing = { ...newListing, [name]: newValue }
    let tempList = [...tempUpdateTestimonial.listing]
    tempList[index] = newListing
    setUpdateTestimonial((prevState) => ({ ...prevState, listing: [...tempList] }))
  }
  useEffect(() => {
    setUpdateTestimonial(value)
  }, [value])
  return (

    <div className="container">
      <div className="row">
        <div className="row">
          <div>
            <div>
              <div className='d-flex row'>
                <div className='col-md-12'>
                  <Form.Label className='font-weight-bold' >Enter Your Main Heading</Form.Label>
                </div>
              </div>
            </div>
            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className='d-flex row'>
                <div className='col-md-12'>
                  <Form.Control
                    defaultValue={updateTestimonial?.mainHeading}
                    onChange={(e)=>{setUpdateTestimonial({ ...updateTestimonial, mainHeading: e.target.value })}}
                    className='w-100 ' type="text" placeholder="Main Heading" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div>
            {
              updateTestimonial?.listing?.map((item, index) => {
                return (
                  <>
                    <div className='d-flex row'>
                      <div className='col-md-5'>
                        <Form.Label className='font-weight-bold' >Enter Your Inner Heading</Form.Label>
                      </div>
                      <div className='col-md-5'>
                        <Form.Label className='font-weight-bold'>Enter Your Sub Heading</Form.Label>
                      </div>
                    </div>
                    <div>
                      {/* <Form.Label >Enter Your Email</Form.Label> */}
                      <div className='d-flex row'>
                        <div className='col-md-5'>
                          <input
                            onChange={(e) => { onhandleInput(e.target, index) }}
                            defaultValue={item?.mainHeading}
                            type="text" name="mainHeading" className="form-control" placeholder="mainHeading" />
                        </div>
                        <div className='col-md-5'>
                          <input
                            onChange={(e) => { onhandleInput(e.target, index) }}
                            defaultValue={item?.subHeading}
                            name="subHeading" className="form-control" placeholder="subHeading"
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        {/* {console.log(item)} */}
                        <Form.Label className='font-weight-bold' >Enter Your Description</Form.Label>
                        <ReactQuill
                          style={{ height: 'auto' }}
                          theme='snow'
                          name="description"
                          onChange={(html) => { onhandleEditor(html, "description", index) }}
                          defaultValue={item?.description}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                  </>
                )
              })
            }
            <hr className='mt-5 button-line '></hr>
          </div>
        </div>
      </div>
      {/* {(value.listing.length !== 1) ? <div className="col-sm-4">  </div>: ''} */}

    </div>

  )
}

export default Updatemultipleinput;