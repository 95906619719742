import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { BlogEnglishVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { TbEdit } from 'react-icons/tb';
function Brandblogmodelenglish({ previousData, value }) {
    const [modalShow, setModalShow] = useState(false)
    return (
        <>
            <TbEdit className='h3' onClick={() => setModalShow(true)} variant="primary"></TbEdit>
            <BlogEnglishVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}
export default Brandblogmodelenglish;