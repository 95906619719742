import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import AddSingleData from "./AddSingleData";
import swal from "sweetalert";

function AgentUploadWriterData() {
  const Agenttoken = JSON.parse(localStorage.getItem("userToken"));
  const [selectedfile, setselectedFile] = useState(null);

  const handleFileChange = (e) => {
    setselectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (!selectedfile) {
      swal({
        title: "Please select a file to upload.",
        icon: "warning",
        dangerMode: true,
      });
      // alert("Please select a file to upload.");
      return;
    }

    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Agenttoken}`);

    const formData = new FormData();
    formData.append("excelfile", selectedfile);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/comicagent/excel`, {
        method: 'POST',
        body: formData,
        headers: myHeaders,
        redirect: 'follow',
      })
      .then((response) => response.json())
      .then((result) => {
        if (result.status && result.statusCode === 200) {
          swal({
            title: result.message,
            icon: "success",
            dangerMode: true,
          });
        } else {
          swal({
            title: result.message,
            icon: "error",
            dangerMode: true,
          });
        }
      })
     

      // if (response.ok) {
      //   const result = await response.text();
      //   swal({
      //     title: result.message,
      //     icon: "success",
      //     dangerMode: true,
      //   });
      //   console.log(result);
      // } else {
      //   swal({
      //     title: response.message,
      //     icon: "error",
      //     dangerMode: true,
      //   });
      //   // console.error('Error:', response.status, response.statusText);
      // }
    } catch (error) {
      swal({
        title: "Something went wrong",
        icon: "error",
        dangerMode: true,
      })
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 bg-success-subtle p-2">
          <h5 className="h5 mb-0 text-gray-800">
            Upload Registration Comic Writer Data
          </h5>
          <form onSubmit={handleSubmit} className="bg-white p-2">
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              filename="selectedfile"
              onChange={handleFileChange}
            />
            <Button type="submit">
              Upload
            </Button>
          </form>
          <AddSingleData />
        </div>

        {/* Rest of your component */}
      </div>
    </>
  );
}

export default AgentUploadWriterData;