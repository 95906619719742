import React from 'react';

function Adminfooter() {
    return (
        <>
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                  <div className="copyright text-center my-auto">
                    <span> Bailkholu Copyright &copy; Your Website 2021</span>
                    
                  </div>
                </div>
              </footer>
        </>
    );
}

export default Adminfooter;