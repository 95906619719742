import React from 'react';
import { useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { CampaignVerticallyCenteredModal } from '../../Campaign/Updatecampaign';
function BrandUpdateCampaignmodel({previousData}) {
    const [modalShow, setModalShow]=useState(false)
    return (
        <>
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <CampaignVerticallyCenteredModal
            previousData={previousData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default BrandUpdateCampaignmodel;