import React, { useState } from 'react';
import { ContactEnglishVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { TbEdit } from 'react-icons/tb';

function Brandcontactmodelenglish({previousData}) {
    const [ modalShow, setModalShow]=useState(false)
    return (
        <>  
            <TbEdit className='h3' onClick={() => setModalShow(true)} variant="primary"></TbEdit>

            <ContactEnglishVerticallyCenteredModal
                previousData={previousData}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandcontactmodelenglish;