import React from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import {  BiEditAlt } from 'react-icons/bi';
import { PhilosophyhindiVerticallyCenteredModal } from '../../Home/Brandphilosophy/Updatephilosophy';


function Brandphilosophyhindimodel({ previousData, value }) {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
         
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <PhilosophyhindiVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandphilosophyhindimodel;