import React, { useContext, useState } from "react";
import { blockInvalidChar } from "./blockInvalidChar";
import swal from "sweetalert";

function ComicAgentCreateForm({}) {
  const token = JSON.parse(localStorage.getItem("userToken"));
  const [contentData, setContentData] = useState("");
  const validateEmail = (email) => {
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validationPhone = (number) => {
    if (number.length === 10) {
      return true;
    } else {
      return false;
    }
  };
  const validateZip = (zip) => {
    if (zip.length === 6) {
      return true;
    } else {
      return false;
    }
  };

  const [regComicWriter, setregComicWriter] = useState({
    name: "",
    contact: "",
    email: "",
    password: "",
  });
  const handleregComicWriter = (e, regComicWriter) => {
    e.preventDefault();
    // console.log("agent create toke" + JSON.stringify(token))
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    var raw = JSON.stringify({
      name: regComicWriter.name,
      contact: regComicWriter.contact,
      email: regComicWriter.email,
      password: regComicWriter.password,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    if (
      regComicWriter.name != "" &&
      regComicWriter.contact != "" &&
      regComicWriter.email != "" &&
      regComicWriter.password != ""
    ) {
      const ValidateEmail = validateEmail(regComicWriter.email);
      const ValidatePhone = validationPhone(regComicWriter.contact);
      if (ValidateEmail) {
        if (ValidatePhone) {
          fetch(`${process.env.REACT_APP_BASE_URL}api/admin/agents`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              // console.log(result.statusCode)
              if (result.status && result.statusCode === 200) {
                swal({
                  title: result.message,
                  icon: "success",
                  dangerMode: true,
                });
              } else {
                swal({
                  title: result.message,
                  icon: "error",
                  dangerMode: true,
                });
              }
            })
            .catch((error) =>
              swal({
                title: "Something went wrong",
                icon: "error",
                dangerMode: true,
              })
            );
        } else {
          swal({
            title: "Phone number should be 10 digit",
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        swal({
          title: "Email is not valid",
          icon: "error",
          dangerMode: true,
        });
      }
    } else {
      swal({
        title: "Please Fill All Field",
        icon: "error",
        dangerMode: true,
      });
    }
  };
  return (
    <>
      <form id="registration_form" className="subscribe_form">
        <div id="error-message" className="notification-div"></div>
        <div className="success-message" />
        <div className="form-row row">
          <div className="form-group col-lg-3 col-md-3 col-12 ">
            <input
              type="text"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  name: e.target.value,
                });
              }}
              className="form-control input-form"
              id="name"
              name="name"
              placeholder="Enter Name"
              required
            />
          </div>
          <div className="form-group col-lg-2 col-md-2 col-12 ">
            <input
              type="email"
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  email: e.target.value,
                });
              }}
              className="form-control input-form"
              id="email"
              name="email"
              placeholder="Enter Email"
              required
            />
          </div>
          <div className="form-group col-lg-2 col-md-2 col-12 ">
            <input
              type="number"
              onKeyDown={blockInvalidChar}
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  contact: e.target.value,
                });
              }}
              className="form-control input-form"
              required
              minLength="10"
              maxLength="10"
              id="contact_number"
              name="contact_number"
              placeholder="contact Number"
            />
          </div>
          <div className="form-group col-lg-2 col-md-2 col-12 ">
            <input
              type="text"
              onKeyDown={blockInvalidChar}
              onChange={(e) => {
                setregComicWriter({
                  ...regComicWriter,
                  password: e.target.value,
                });
              }}
              className="form-control input-form"
              required
              id="password"
              name="password"
              placeholder="Password"
            />
          </div>

          <div className="col-lg-3 text-center">
            <button
              onClick={(e) => {
                handleregComicWriter(e, regComicWriter);
              }}
              className="btn bg-warning text-black "
            >
              <b className=""> Create Agent</b>
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ComicAgentCreateForm;
