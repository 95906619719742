import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./component/Login/Login";
import NourishLogin from "./component/Login/NourishLogin";
import Resetpassword from "./component/Resetpassword/Resetpassword";
import Dashboard from "./component/Dashboard/Dashboard";
import Header from "./component/Common/Header.js/Header";
import Model from "./component/GlobalComponent/Model/Headermodelhindi";
import Authcontext from "./contextapi/Authcontext/Authcontext";
import Footer from "./component/Common/Footer/Footer";
import Footermodel from "./component/GlobalComponent/Model/Footermodel";
import Brandslider from "./component/Home/Brandslider/Brandslider";
import Brandabout from "./component/Home/Brandabout/Brandabout";
import Editorpage from "./component/GlobalComponent/Editor/Editor";
import Brandproduct from "./component/Home/Bailkholuproduct/Brandproduct";
import Brandphilosophy from "./component/Home/Brandphilosophy/Brandphilosophy";
import Brandaboutoil from "./component/Home/Brandaboutoil.js/Brandaboutoil";
import Brandstory from "./component/Home/Brandstory/Brandstory";
import Brandblogs from "./component/Home/Brandblogs/Brandblogs";
import Brandtvc from "./component/Home/Brandtvc/Brandtvc";
import Brandcontact from "./component/Home/Brandcontact/Brandcontact";
import "react-toastify/dist/ReactToastify.css";
import Brandmedia from "./component/Home/Brandmedia/Brandmedia";
import Layout from "./component/GlobalComponent/Layout/Layout";
import Page404 from "./page404.js";
import Servererror from "./Servererror";
import Brandcampaignthumbnail from "././component/Home/Brandcampaignthumbnail/Brandcampaignthumbnail";
import Changepassword from "./component/Changepassword/Changepassword";
import Media from "./component/GlobalComponent/Media/Media";
import Imageshowing from "./component/Modelhelper/Imageshowing";
import Campaign from "./component/Campaign/Campaign";
import Multipleinput from "./component/GlobalComponent/Multipleinput.js/Multipleinput";
import Contactforms from "./component/Forms/Contactforms";
import Getfreecomic from "./component/Forms/Getfreecomic";
import JointheJourney from "./component/Forms/JointheJourney";
import Testingeditor from "./component/GlobalComponent/Editor/Testingeditor";
import ComicRegistrationData from "./component/Forms/comicRegistrationData";
import Order from "./component/Ecommerce/Order";
import PendingOrder from "./component/Ecommerce/PendingOrder.js";
import AgentLogin from "./component/Login/AgentLogin";
import PrivateRoute from "./PrivateRoute";
import WriterComicDashboard from "./component/ComicWrite/ComicWriteDashboard";
import CreateAgent from "./component/Admin/CreateAgent";
import SchooLibData from "./component/Forms/SchooLibData";
import RegUserList from "./component/Ecommerce/RegUserList.js";
import NourishOrder from "./component/Ecommerce/NourishEcom/NourishOrder.js";
import NourishRegUserList from "./component/Ecommerce/NourishEcom/NourishRegUserList.js";
import Pageredirect from "./component/Dashboard/PageRedrection.js";
import NSDashboard from "./component/Dashboard/NSDashboard.js";
import NourishFailedOrder from "./component/Ecommerce/NourishEcom/NourishFailedOrder.js";
import VoucherDashborad from "./component/Ecommerce/NourishEcom/VoucherDashborad.js";
import CustomerGroupCreation from "./component/Ecommerce/NourishEcom/CustomerGroupCreation.js";
function App() {
  const Loader = React.lazy(() =>
    import("./component/GlobalComponent/Loader/Loader")
  );
  let userRole = localStorage.getItem("userRole");
  const NStoken = JSON.parse(localStorage.getItem("NStoken"));
  let accesstoken = localStorage.getItem("accesstoken");
  let medusatoken = localStorage.getItem("medusatoken");

  // const userRole = userRole.replace(/["']/g, "");
  console.log("Token === " + NStoken);
  return (
    <>
      <BrowserRouter>
        <Authcontext>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="nslogin/" element={<NourishLogin />} />
            <Route path="/agent-login" element={<AgentLogin />} />
            <Route path="/table" element={<Testingeditor />}></Route>
            <Route path="/resetpass" element={<Resetpassword />} />
            <Route path="/loader" element={<Loader />} />

            <>
              <Route path="/" element={<Layout />}>
                {/* {NStoken !== null ? ( */}
                <>
                  {" "}
                  {/* {
                      userRole === null ||
                      userRole === "NSadmin" ||
                      userRole === "store_manager" ||
                      userRole === "admin" ||
                      userRole === "editor" ? (
                        <> */}
                  <Route
                    path="/nourish-failed-order"
                    element={<NourishFailedOrder />}
                  ></Route>
                  <Route
                    path="/nourish-order"
                    element={<NourishOrder />}
                  ></Route>
                  <Route
                    path="/nourish-dashboard"
                    element={<NSDashboard />}
                  ></Route>
                  <Route
                path="/nourish-userlist"
                element={<NourishRegUserList />}
              ></Route>
                <Route
                path="/nourish-Voucher"
                element={<VoucherDashborad />}
              ></Route>
                <Route
                path="/nourish-customer-group"
                element={<CustomerGroupCreation />}
              ></Route>
                  {/* </>
                      ) : null
                    } */}
                </>
                {/* ) : null} */}
                {/* <Route
                path="/nourish-userlist"
                element={<NourishRegUserList />}
              ></Route> */}
                {/* {accesstoken !== null || medusatoken !== null ? ( */}
                <>
                  {userRole === "store_manager" ? (
                    <Route path="/order" element={<Order />}></Route>
                  ) : null}

                  {userRole === "admin" ? null : (
                    <>
                      <Route path="/order" element={<Order />}></Route>
                      <Route
                        path="/page-redirection"
                        element={<Pageredirect />}
                      ></Route>
                      <Route
                        path="/Pending-Order"
                        element={<PendingOrder />}
                      ></Route>
                      <Route path="/userlist" element={<RegUserList />}></Route>
                      {/* <Route
                    path="/nourish-order"
                    element={<NourishOrder />}
                  ></Route>
                  <Route
                    path="/nourish-userlist"
                    element={<NourishRegUserList />}
                  ></Route> */}
                    </>
                  )}

                  {/* role base section ================================ */}
                  {userRole === "comic_agent" ? null : (
                    <Route
                      path="/agentcomicwriter"
                      element={<WriterComicDashboard />}
                    ></Route>
                  )}
                  {userRole === "admin" ? null : (
                    <Route
                      path="/create-agent"
                      element={<CreateAgent />}
                    ></Route>
                  )}

                  {userRole === "admin" || userRole === "editor" ? (
                    <>
                      <Route path="/" element={<Login />} />
                      <Route path="/nslogin" element={<NourishLogin />} />
                    </>
                  ) : (
                    <>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route
                        path="/philosophy"
                        element={<Brandphilosophy />}
                      ></Route>
                      <Route
                        path="/brandproduct"
                        element={<Brandproduct />}
                      ></Route>
                      <Route path="/media" element={<Media />} />
                      <Route path="/image" element={<Imageshowing />} />
                      <Route path="/header" element={<Header />} />
                      <Route path="/model" element={<Model />} />
                      <Route path="/footer" element={<Footer />} />
                      <Route path="/footermodel" element={<Footermodel />} />
                      <Route
                        path="/brandslider"
                        element={<Brandslider />}
                      ></Route>
                      <Route
                        path="/brandabout"
                        element={<Brandabout />}
                      ></Route>
                      <Route path="/editor" element={<Editorpage />}></Route>
                      <Route
                        path="/brandcampaignthumbnail"
                        element={<Brandcampaignthumbnail />}
                      ></Route>
                      <Route
                        path="/changepassword"
                        element={<Changepassword />}
                      ></Route>

                      <Route
                        path="/aboutoil"
                        element={<Brandaboutoil />}
                      ></Route>
                      <Route path="/story" element={<Brandstory />}></Route>
                      <Route path="/blog" element={<Brandblogs />}></Route>
                      <Route path="/tvc" element={<Brandtvc />}></Route>
                      <Route path="/contact" element={<Brandcontact />}></Route>
                      <Route
                        path="/brandmedia"
                        element={<Brandmedia />}
                      ></Route>

                      <Route path="/campaign" element={<Campaign />}></Route>
                      <Route
                        path="/multipleinput"
                        element={<Multipleinput />}
                      ></Route>
                      <Route
                        path="/contactform"
                        element={<Contactforms />}
                      ></Route>
                      <Route
                        path="/freecomic"
                        element={<Getfreecomic />}
                      ></Route>
                      <Route
                        path="/jointhejourney"
                        element={<JointheJourney />}
                      ></Route>
                      <Route
                        path="/comicwriterdata"
                        element={<ComicRegistrationData />}
                      ></Route>
                      <Route
                        path="/school-lib-Reg-data"
                        element={<SchooLibData />}
                      ></Route>
                    </>
                  )}
                </>
                {/* ) : null} */}
                {/* <Route path="/order" element={<Order />}></Route> */}
              </Route>
            </>

            <Route path="/*" element={<Page404 />} />
            <Route path="/servererror" element={<Servererror />} />
          </Routes>
        </Authcontext>
      </BrowserRouter>
    </>
  );
}

export default App;
