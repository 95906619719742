import React from 'react';
import { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { StoryHindiVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { BiEditAlt } from 'react-icons/bi';
function Brandstorymodelhindi({previousData,value}) {
    const { modalShow, setModalShow } = useContext(Auth)
    return (
        <>
          
            <BiEditAlt className='h3' onClick={() => setModalShow(true)} />
            <StoryHindiVerticallyCenteredModal
            previousData={previousData}
            value={value}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default Brandstorymodelhindi;