import React, { useState, useEffect, useMemo } from "react";

import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import OrderEditStatusChanger from "./orderStatusChange";
import OrderInvoice from "./orderInvoice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="search"
      placeholder="Search by ID, Email"
      aria-label="Search Input"
      defaultValue={filterText}
      onChange={onFilter}
      className="w-25 form-control float-left"
      autoComplete="off"
    />
    {/* <button type="button" className="btn bg-black text-white mr-5" onClick={onClear} disabled>
     <MdSearch/>
    </button> */}
  </>
);

const NourishFailedOrder = () => {
  const nourishmedusatoken = JSON.parse(localStorage.getItem("NStoken"));
  // console.log("nourishmedusatoken key ---" + nourishmedusatoken)
  const [OrderData, setOrderData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [pending, setPending] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const filterProperties = ["email", "name", "id", "title", "phone", "city"];
  const resetComponent = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterText("");
    setResetPaginationToggle(!resetPaginationToggle);
  };
  const DateFilterForm = ({ onDateChange }) => {
    const handleDateChange = () => {
      onDateChange(startDate, endDate);
    };
    const handleClear = () => {
      resetComponent();
    };

    return (
      <>
        <button
          type="button"
          className="btn bg-warning text-black ml-1"
          onClick={handleClear}
        >
          Reset Filter
        </button>
        {/* <button
          type="button"
          className="btn bg-black text-white ml-1"
          onClick={handleDateChange}
        >
          Apply
        </button> */}
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          placeholderText="End Date"
          className=" form-control"
        />

        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className=" form-control"
        />
      </>
    );
  };

  const navigate = useNavigate();
  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  // Create Remark
  const [noteValue, setNoteValue] = useState("");
  const [rowNoteValues, setRowNoteValues] = useState({});
  const [rowNotes, setRowNotes] = useState({});

  const setRowNoteValue = (orderId, value) => {
    setRowNoteValues((prevRowNoteValues) => ({
      ...prevRowNoteValues,
      [orderId]: value,
    }));
  };

  const createNote = async (orderId) => {
    const raw = JSON.stringify({
      failure_id: `${orderId}`,
      remark: rowNoteValues[orderId],
    });

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptionsNote = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/failure`,
        requestOptionsNote
      );
      const result = await response.json();
      // setRowNotes((prevRowNotes) => ({
      //   ...prevRowNotes,
      //   [orderId]: result,
      // }));
    } catch (error) {
      console.log("error", error);
      // Handle error here
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
  
    
    return () => clearTimeout(timeout);
  }, []);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${nourishmedusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_Nourish_URL}/admin/failure?limit=100000000`,
          requestOptions
        );
        const result = await response.json();
        setOrderData(result);
      } catch (error) {
        console.log("error", error);
        if (!error?.status || error?.status === "FETCH_ERROR") {
          navigate("/nslogin");
          swal({
            title: "Session Expired Please Login Again",
            icon: "error",
            dangerMode: true,
          });
        } else if (error.status === 401) {
          navigate("/nslogin");
          // swal({
          //   title: err.data.message,
          //   icon: "error",
          //   dangerMode: true,
          // });
        } else {
          swal({
            title: "Someting went wrong1",
            icon: "error",
            dangerMode: true,
          });
        }
      }
    };

    fetchData();
  }, []);

  const filteredItems = OrderData?.data?.filter((item) =>
    filterProperties.some(
      (property) =>
        new Date(item.created_at) > (startDate || new Date(0)) &&
        new Date(item.created_at) < (endDate || new Date()) &&
        new Date(item.created_at) > new Date("2024-07-23 23:29:37") &&
        item[property] &&
        item[property].toLowerCase().includes(filterText.toLowerCase())
    )
  );

  // console.log(filteredItems + "filteredItems");
  // console.log("Note----------" + JSON.stringify(OrderData));
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="SubFilterheader w-100 d-inline border p-3">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          className=""
        />
        <div className="dateSection d-flex flex-row-reverse w-75">
          <DateFilterForm onDateChange={handleDateChange} />{" "}
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle, startDate, endDate]);

  // Data Table Order Section

  const columns = [
    {
      name: "Date",
      selector: (row) => format(new Date(row?.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Order ID",
      selector: (row) => row?.id,
      sortable: true,
      grow: 3,
    },
    {
      name: "Channel",
      selector: (row) => (row?.data?.cart?.cart ? "Website" : "App"),
      sortable: true,
      grow: 3,
    },
    {
      name: "Order Fail Reason",
      selector: (row) => row?.reason,
      sortable: true,
      grow: 3,
    },
    {
      name: "City",
      grow: 2,
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? row?.data?.cart?.cart?.shipping_address?.city
          : " " + row?.data?.cart?.cartData !== undefined
          ? row?.data?.cart?.cartData?.cart?.shipping_address?.city
          : " ",
      sortable: false,
    },
    {
      name: "Phone",
      grow: 2,
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? row?.data?.cart?.cart?.shipping_address?.phone
          : " " + row?.data?.cart?.cartData !== undefined
          ? row?.data?.cart?.cartData?.cart?.shipping_address?.phone
          : " ",
      sortable: false,
    },
    {
      name: "Name",
      grow: 2,
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? row?.data?.cart?.cart?.shipping_address?.first_name
          : " " + row?.data?.cart?.cartData !== undefined
          ? row?.data?.cart?.cartData?.cart?.shipping_address?.first_name
          : " ",
      sortable: false,
    },

    // {
    //   name: "Phone",
    //   // grow: 2,
    //   selector: (row) => row.customer.phone,
    // },
    {
      name: "Cart Product",
      grow: 8,
      wrap: true,
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? row?.data?.cart?.cart?.items
              ?.map(
                (name) => name?.variant?.title
                // +
                //   " x " + name?.variant?.product?.metadata?.item_weight
              )
              .join(" , ")
          : " " + row?.data?.cart?.cartData !== undefined
          ? row?.data?.cart?.cartData?.cart?.items
              ?.map(
                (name) => name?.variant?.title
                // +
                //   " x " +
                //   name?.variant?.metadata
                //     ?.item_weight
              )
              .join(" , ")
          : " ",

      sortable: false,
    },

    {
      name: "Price",
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? `₹${parseFloat(row?.data?.cart?.cart?.total / 100).toFixed(2)}`
          : " " + row?.data?.cart?.cartData !== undefined
          ? `₹${parseFloat(
              row?.data?.cart?.cartData?.cart?.total / 100
            ).toFixed(2)}`
          : " ",
    },
    {
      name: "Discounts",
      selector: (row) =>
        row?.data?.cart?.cart !== undefined
          ? row?.data?.cart?.cart?.discounts[0]?.code
          : " " + row?.data?.cart?.cartData !== undefined
          ? row?.data?.cart?.cartData?.cart?.discounts[0]?.code
          : "",
    },
    {
      name: "Add Note",
      grow: 4,
      selector: (row) => (
        <div className="w-100 d-flex my-2">
          <textarea
            className="form-control"
            style={{ fontSize: "12px" }}
            placeholder="Enter Remark"
            value={row.noteValue}
            onChange={(e) => setRowNoteValue(row.id, e.target.value)}
          />
          <button
            className="btn btn-primary mx-2"
            style={{ fontSize: "12px" }}
            onClick={() => createNote(row.id, noteValue)}
          >
            Add
          </button>
        </div>
      ),
    },
    {
      name: "Remark",
      selector: (row) => row?.remark,
      sortable: false,
      grow: 2,
    },
  ];

  const downloadReport = () => {
    const csvContent = prepareCSVData(filteredItems);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, "order_report.csv");
    } else {
      link.href = URL.createObjectURL(blob);
      link.download = "order_report.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const prepareCSVData = (data) => {
    const headers = [
      "Date",
      "Order ID",
      "Name",
      "Email",
      "Phone",
      "Pincode",
      "City",
      "Price",
      "Payment Status",
      "Order Fulfillment Status",
      "Product Name",
      "Product Weight",
      "Product Qty",
    ];

    const csvRows = [];
    csvRows.push(headers.join(","));

    data.forEach((order) => {
      const commonDetails = [
        format(new Date(order.created_at), "MM/dd/yyyy HH:mm:ss"),
        order.id,
        `${order.customer.first_name} ${order.customer.last_name}`,
        order.email,
        order.shipping_address.phone,
        order.shipping_address.postal_code,
        order.shipping_address.city,
        `${(order.payments[0]?.amount / 100).toFixed(2)}`,
        order.payments[0]?.data?.status || "Unknown",
        order.status,
      ];

      order.items.forEach((item, index) => {
        const productDetails = [
          item.title,
          item?.variant?.metadata?.item_weight || "N/A",
          item.quantity,
        ];

        const columns =
          index === 0
            ? [...commonDetails, ...productDetails]
            : [...new Array(commonDetails.length).fill(""), ...productDetails];

        csvRows.push(columns.join(","));
      });
    });

    return csvRows.join("\n");
  };

  // Example usage
  const data = [
    // Your orders data here
  ];

  console.log(prepareCSVData(data));

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Order Data</h1>
        {/* <button
          className="btn btn-sm btn-primary shadow-sm"
          onClick={downloadReport}
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Download
          Report
        </button> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <DataTable
            className="TableNs"
            columns={columns}
            data={filteredItems}
            defaultSortAsc={true}
            progressPending={pending}
            paginationTotalRows={filteredItems?.length}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
            // paginationRowsPerPageOptions={[100]}
          />
        </div>
      </div>
    </div>
  );
};

export default NourishFailedOrder;
