import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../../images/resetpasswordimage.png'
import { Auth } from '../../contextapi/Authcontext/Authcontext';
import Loader from '../GlobalComponent/Loader/Loader';
import { useForgetpassMutation } from '../../features/auth/authApiSlice';

function Resetpassword() {
  const [resetPassword, requireInfo] = useForgetpassMutation()
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const handleChangeassword = (e) => {
    e.preventDefault()
    resetPassword({ email: email })
  }
  return (
    <>

      <div className="row">
        <div className="col-lg-6"  >
          <img src={image} alt="Girl in a jacket" width="600" height="550" />
        </div>
        <div className="col-lg-6">
          <div className="p-5">
            <div className="text-center">
              <h1 className="h4 text-gray-900 mb-2">
                Forgot Your Password?
              </h1>
              <p className="mb-4">
                We get it, stuff happens. Just enter your email address
                below and we'll send you a link to reset your password!
              </p>
            </div>
            <form className="user">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-control-user"
                  id="exampleInputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter Email Address..."
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </div>

              <button
                onClick={(e) => { handleChangeassword(e) }}
                className="btn btn-primary btn-user btn-block"
              >
                Reset Password
              </button>
            </form>
            <hr />
            <div className="text-center">

            </div>
            <div className="text-center">
              <a href='/' onClick={() => { navigate('/') }} className="small">
                Already have an account? Login!
              </a>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}

export default Resetpassword;