import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { BlogAddVerticallyCenteredModal } from '../../Modelhelper/Modelhelper';
function Brandblogmodel({ value }) {
    const [modalShow, setModalShow] = useState(false)
    const {editorState, setEditorState}=useContext(Auth)
    return (
      <>
        <Button
          variant="primary"
          onClick={() => {
            setModalShow(true);
            setEditorState({ ...editorState, editorHtml: "" });
          }}
        >
          Add Hindi Blog
        </Button>
        <BlogAddVerticallyCenteredModal
          dialogClassName="modal-90w"
          value={value}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </>
    );
}
export default Brandblogmodel;