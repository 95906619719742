import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import './multiple.css'
Quill.register('modules/imageResize', ImageResize);
function Multipleinput() {
  const { createCategoriesComic, setCreateCategoriesComic,inputFields, setInputFields } = useContext(Auth)
  const addInputField = () => {
    setInputFields([...inputFields, {
      mainHeading: '',
      subHeading: '',
      description: ''
    }])
  }
  const removeInputFields = (data) => {
    setInputFields(inputFields.filter((item) => { return item != data }))
  }
  const handleChange = (index, evnt) => {
    if (typeof evnt == 'object') {
      const { name, value } = evnt.target;
      const list = [...inputFields];
      list[index][name] = value;
      setInputFields(list);
    } else {
      const list = [...inputFields];
      list[index]['description'] = evnt;
      setInputFields(list);
    }
  }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video'
  ];
  return (

    <div className="container">
      <div className="row">
        <div className="row">
          <div className="col-sm-12">
            <button className="btn btn-outline-success " onClick={addInputField}>Add New</button>
          </div>
          <div>
            <div>
              <div className='d-flex row'>
                <div className='col-md-12'>
                  <Form.Label className='font-weight-bold' >Enter Your Main Heading</Form.Label>
                </div>
              </div>
            </div>

            <div>
              {/* <Form.Label >Enter Your Email</Form.Label> */}
              <div className='d-flex row'>
                <div className='col-md-12'>
                  <Form.Control
                    onChange={(e) => setCreateCategoriesComic({ ...createCategoriesComic, campaignHeading: e.target.value })}
                    className='w-100 ' type="text" placeholder="Comic Name" />
                </div>

              </div>
            </div>
          </div>
        </div>
        
        <div className="col-md-12">
          {
            inputFields.map((data, index) => {
              const { mainHeading, subHeading, description } = data;
              return (
                <div key={index}>
                  <div>
                    <div>
                      <div>
                        <div className='d-flex row'>
                          <div className='col-md-5'>
                            <Form.Label className='font-weight-bold' >Enter Your Main Heading</Form.Label>
                          </div>
                          <div className='col-md-5'>
                            <Form.Label className='font-weight-bold'>Enter Your Sub Heading</Form.Label>
                          </div>
                          <div className='col-md-2'>
                            {(inputFields.length !== 1) ? <button className="btn btn-outline-danger mt-2" onClick={() => { removeInputFields(data) }}>x</button> : ''}
                          </div>
                        </div>

                      </div>
                      <div>
                        {/* <Form.Label >Enter Your Email</Form.Label> */}
                        <div className='d-flex row'>
                          <div className='col-md-5'>
                            <Form.Control
                              type="text" onChange={(evnt) => handleChange(index, evnt)} value={mainHeading} name="mainHeading" className="form-control" placeholder="mainHeading" />
                          </div>
                          <div className='col-md-5'>
                            <Form.Control
                              onChange={(evnt) => handleChange(index, evnt)} value={subHeading} name="subHeading" className="form-control" placeholder="subHeading"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <Form.Label className='font-weight-bold' >Enter Your Description</Form.Label>
                      <ReactQuill
                        style={{ height: 'auto' }}
                        theme='snow'
                        onChange={(evnt) => handleChange(index, evnt)}
                        name="description"
                        value={description}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <hr className='mt-5 '></hr>
                </div>
                
              )
            })
          }


        </div>
      </div>
      {(inputFields.length !== 1) ? <div className="col-sm-4">  </div>: ''}
     
    </div>

  )
}

export default Multipleinput;