import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { format } from "date-fns";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <input
      id="search"
      type="search"
      placeholder="Search by Name, Email, Phone "
      aria-label="Search Input"
      defaultValue={filterText}
      onChange={onFilter}
      className="w-25 form-control float-left"
      autoComplete="off"
    />
    {/* <button type="button" className="btn bg-black text-white mr-5" onClick={onClear} disabled>
     <MdSearch/>
    </button> */}
  </>
);
const RegUserList = () => {
  const medusatoken = JSON.parse(localStorage.getItem("medusatoken"));
  const [UserData, setUserData] = useState();
  const [pending, setPending] = useState(true);
  const [filterText, setFilterText] = useState("");
  const filterProperties = ["email", "name", "phone"];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);


  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${medusatoken}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}admin/customers?limit=100000000`,
          requestOptions
        );
        const result = await response.json();
        setUserData(result);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);


  const columns = [

    // {
    //   name: "S. No.",
    //   selector: (row) => row.index,
    //   sortable: true,
    // },
    {
      name: "Create Date",
      selector: (row) => format(new Date(row.created_at), "MM/dd/yyyy"),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.first_name + row.last_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
      grow: 2,
    },
    {
      name: "Phone",
      sortable: true,
      // grow: 2,
      selector: (row) => row.phone,
    },
    // {
    //   name: "Address",
    //   selector: (row) => row.address,
    // },
   
  
  ];

  const filteredItems = UserData?.customers?.filter((item, index) =>
  filterProperties.some(
    (property) =>
      new Date(item.created_at) > new Date("11/04/2023 01:29:37") &&
      item[property] &&
      item[property].toLowerCase().includes(filterText.toLowerCase())
  )
);
  const Tabledata = UserData?.customers.filter((item) => new Date(item.created_at) > new Date("11/02/2023 01:29:37")).map((item, index) => ({
      i: index+1,
      name: item.first_name + " " + item.last_name,
      email: item.email,
      phone: item.phone,
      created_at: item.created_at
      
    }));
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {
        if (filterText) {
         
          setFilterText("");
        }
      };
      return (
        <div className="SubFilterheader w-100 d-inline border p-3">
          <FilterComponent
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
            className=""
          />
          
        </div>
      );
    }, [filterText]);
  

  return (
    <div>
    <div className="row">
        <div className="col-md-12">
          <DataTable
            columns={columns}
            data={filteredItems}
            defaultSortAsc={true}
            progressPending={pending}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            pagination
            paginationComponentOptions={{
              rowsPerPageText: "Rows per page:",
              rangeSeparatorText: "of",
              selectAllRowsItem: true,
              selectAllRowsItemText: "All",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegUserList;
