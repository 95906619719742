import React, { useState } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { MediaHindiVerticallyCenteredModal } from '../../Modelhelper/Updatemodelhelper';
import { BiEditAlt } from 'react-icons/bi';

function Brandmediahindimodel({ previousData, value }) {
   const [updateHindiModelShow, setUpdateHindiModelShow]=useState(false)

    return (
        <>
            <BiEditAlt className='h3' onClick={() => setUpdateHindiModelShow(true)} />
            <MediaHindiVerticallyCenteredModal
                previousData={previousData}
                value={value}
                show={updateHindiModelShow}
                onHide={() => setUpdateHindiModelShow(false)}
            />
        </>
    );
}

export default Brandmediahindimodel;