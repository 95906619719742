import {getOrders } from "../../contextapi/Endpoints/Endpoints";
import { apiSlice } from "../app/api/apiSlice";
export const mediaApiSlice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        getAllOrder: builder.query({
            query: () => ({
                url: getOrders,
                method: 'GET'
            })
        })
    })
})
export const { useGetAllOrderQuery} = mediaApiSlice