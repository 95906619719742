import { createSlice } from "@reduxjs/toolkit";
const persistedStorage = JSON.parse(localStorage.getItem("userToken"))
const persisteAccessToken = JSON.parse(localStorage.getItem("userAccessToken"))
const initialState = {
    // accesstoken: persistedStorage != null || persistedStorage === undefined ? persistedStorage.accesstoken : null,
    // refreshToken: persistedStorage != null || persistedStorage === undefined ? persistedStorage.refreshtoken : null
    accesstoken: persistedStorage ,
    refreshToken: persisteAccessToken
}
console.log(initialState)
const authSlice = createSlice({
    initialState: { ...initialState },
    name: "auth",
    reducers: {
        setCredentials: (state, action) => {
            const { accesstoken, refreshtoken } = action.payload
            state.accesstoken = accesstoken
            state.refreshToken = refreshtoken
        },
        logOut: (state, action) => {
            state.refreshToken = null
            state.accesstoken = null
        }
    },
})
export const { setCredentials, logOut } = authSlice.actions
export default authSlice.reducer
export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
