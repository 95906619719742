export const adminEndPoint = "admin/login"
export const comicLoginEndPoint = "/comicagent"
export const adminEndLogout ="admin/logout"
export const getHeader ="admin/getheader"
export const updateHeader ="admin/updateheader"
export const forgetpass ="admin/forgotpassword"
export const changepass ="admin/updatepassword"
export const addsliderendPoint ="admin/addbanner"
export const getBannerEndPoint="admin/getbanners"
export const deleteBannerEndPoint= "admin/deletebanner"
export const updateBannerEndPoint ="admin/updatebanner"
export const mediaEndPoint ="admin/s3"

export const aboutBrandEndPoints="admin/getbrandabout"
export const updateAboutBrandEndPoints = "admin/updatebrandabout"
export const productBrandEndPoints ="admin/getproductpage"
export const updateProductBrandEndPoints="admin/updateproductpage"
export const philosophyEndPoints="admin/getphilosophypage"
export const updatePhilosophyEndPoints="admin/updatebrandphilosophy"

export const getAllAboutOil="admin/getabout"
export const addAboutOil ="admin/addaboutcard"
export const deleteAboutOil ="admin/deleteaboutcard"
export const updateAboutOil ="admin/updateaboutcard"

export const getStoryEndPoints= 'admin/getstories'
export const updateStoryEndPoints='admin/updatestory'

export const mediaEndPoints="admin/mediapost"

export const blogEndPoints ="admin/blog"

export const tvcEndPoints="admin/gettvcvideos"
export const addTvcEndPoints="admin/addtvcvideo"
export const deleteTvcEndPoints="admin/deletetvcvideo"

export const contactEndPoints= "admin/getcontactpage"
export const contactUpdateEndPoints="admin/updatecontactpage"

export const footerEndPoints="admin/getfooter"
export const updateFooterEndPoints ="admin/updatefooter"

export const comicEndPoints ="admin/campaign"

export const getfreecomic="admin/comic"
export const getcontactform ="admin/contactus"
export const jointhejourney="admin/connectwithus"
// Comic Website eND pOINT

export const comicuser="/admin/comics"
export const regDataUploadEndPoint="/comicagentdata"
export const schoollibDataEndPoint="/admin/connectentries"
export const AgentListEndPoint="/admin/agents"
export const ComicRegistrationEndPoint="form/comicuser"


//admin ecommerce
export const getOrders="/admin/orders"




