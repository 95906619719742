import React, { useState } from "react";

const OrderEditStatusChanger = ({ orderId, nourishmedusatoken, orderStatus }) => {
  // console.log(orderId)
  const [orderEditId, setOrderEditId] = useState("");
  const [newStatus, setNewStatus] = useState(""); // State to store the selected status

  const statusOptions = [
    // "Ordered",
    "Dispatched",
    "Out for Delivery",
    "Delivered",
  ];

  const handleChangeStatus = async (event) => {
    event.preventDefault();
    try {
      // Create a request object with the new status
      const orderEditData = {
        order_id: orderId,
        order_status: newStatus, // Use the selected status
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_Nourish_URL}admin/orderstatus`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${nourishmedusatoken}`,
          },
          body: JSON.stringify(orderEditData),
        }
      );

      if (response.ok) {
        // Status change successful
        console.log("Order edit status changed successfully");
      } else {
        // Handle error if status change failed
        console.error("Failed to change order edit status");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setTimeout(() => {
      window.location.reload();
    }, 30);
    return false;
  };

  return (
    <div className="d-flex justify-content-around">
      {/* <input
        type="text"
        placeholder="Order Edit ID"
        value={orderId}
        onChange={(e) => setOrderEditId(e.target.value)}
      /> */}

      <select
        className="form-control"
        style={{ width: "150px", fontSize: "13px" }}
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value)}
      >
        <option value="" className="">
          Select Status
        </option>
        {statusOptions.filter((status) => orderStatus !== status).map((status) => (
          <option key={status} value={status}>
            {status}
          </option>
        ))}
      </select>
      <button
        type="button"
        onClick={handleChangeStatus}
        className="text-wrap btn btn-warning bold "
      >
        <small style={{ fontSize: "12px" }}>Submit</small>
      </button>
      {/* <button
        type="button"
        onClick={handleChangeStatus}
        className="text-wrap btn btn-warning bold "
      >
        <small style={{ fontSize: "12px" }}>
          Mark as Order Delivered to User{" "}
        </small>
      </button> */}
    </div>
  );
};

export default OrderEditStatusChanger;
