import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios';
function Testingeditor() {
  const [editorContent, setEditorContent] = useState('')
  // console.log(editorContent)
  const imageSender = async (file) => {
    const accessToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0OTAzOGUzOWQ3NmQ0MGE0NGEwMzEzNyIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY4ODI5NDkyOCwiZXhwIjoxNjg4NzI2OTI4fQ.qTctAUQOdyfRI5Qb-QlmY5D_W97_l49jLTr6ngCmaDc'
    return await axios.post('https://www.bailkolhu.com/api/admin/s3', file, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': accessToken
      }
    }).then(function (response) {
      return response
    })
      .catch(function (error) {
        return error
      })
  }
  const handleUpload = async (file) => {
    const formImage = new FormData()
    formImage.append('image', file)
    const response = await imageSender(formImage)
    // console.log(response, "responseee responseee")
    if (response) {
      return response.data
    }
    return response.data
  }
  return (
    <>
      <Editor
        value={editorContent}
        onEditorChange={e => setEditorContent(e)}
        apiKey="ir61kxcs1i792eyfzgxybd3k850ojqeojrosj6x7v1l8fagv"
        init={{
          height: 350,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'table', 'image', 'link'
          ],
          toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist | ' +
            'table |link | image',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          default_link_target: '_blank',
          automatic_uploads: true,
          file_picker_types: 'image',
          image_uploadtab: true,
          file_picker_callback: function (callback, value, meta) {
            if (meta.filetype == 'image') {
              const input = document.createElement('input')
              input.setAttribute('type', 'file')
              input.setAttribute('accept', 'image/*')
              input.click()
              input.onchange = async function () {
                const file = input.files[0]
                const reader = new FileReader()
                const fileUrl = await handleUpload(file)
                // console.log(fileUrl,"outputttt")
                callback(fileUrl.uploaded[0], {
                  alt: file.name
                })
                reader.readAsDataURL(file)
              }
            }
          }
        }}
      />
    </>
  );
}

export default Testingeditor;