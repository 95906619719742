import React from "react";
import { useContext } from "react";
import swal from "sweetalert";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Media from "../../GlobalComponent/Media/Media";
import { RxCrossCircled } from "react-icons/rx";
import { Auth } from "../../../contextapi/Authcontext/Authcontext";
import { useAddTvcMutation, useGetTvcQuery } from "./tvcApiSlice";
export function TvcVerticallyCenteredModal(props) {
  const { tvc, setTvc } = useContext(Auth);
  const [addTvc] = useAddTvcMutation();
  const getTvc = useGetTvcQuery();
  const handleSubmit = (e) => {
    e.preventDefault();
    const raw = {
      videourl: tvc.videoUrl,
      backgroundimage: tvc.backgroundImageUrl,
    };
    addTvc(raw)
      .unwrap()
      .then((data) => {
        props.onHide();
        getTvc.refetch();
        swal({
          title: "Tvc Added Successfully!",
          icon: "success",
          dangerMode: true,
        });
      })
      .catch((error) => {
        swal({
          title: "Something Went Wrong Please Try Again",
          icon: "error",
          dangerMode: true,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Card.Title
                    className="text-center"
                    style={{ marginTop: "20px" }}
                  >
                    Upload Your Background TVC Video Image{" "}
                  </Card.Title>
                  <div className="row">
                    <div className="col-md-5">
                      <Media value={"addtvc"} />
                    </div>
                    <div className="col-md-2"></div>
                    <div className=" d-flex m-2 col-md-5">
                      <div className="nav-link" href="#">
                        {tvc.backgroundImageUrl.length !== 0 ? (
                          <>
                            <img
                              src={tvc.backgroundImageUrl}
                              className="slider-image mt-3"
                              alt="imageslider"
                            ></img>
                            <RxCrossCircled
                              className="delteicon"
                              onClick={() => {
                                setTvc({ ...tvc, backgroundImageUrl: "" });
                              }}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-12">
              <Card style={{ marginTop: "20px" }}>
                <Card.Body md="3 ">
                  <Form>
                    <Form.Group
                      className="mb-2"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Card.Title>Add Youtube Embedded Code</Card.Title>
                      <Form.Control
                        onChange={(e) => {
                          setTvc({ ...tvc, videoUrl: e.target.value });
                        }}
                        as="textarea"
                        rows={1}
                      />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save{" "}
        </Button>
        <Button>Reset All </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}