import React from "react";
import { Button } from "react-bootstrap";

import { useState } from "react";
import { ChooseEditorVerticallyCenteredModal } from "../../../Modelhelper/Modelhelper";

function Commoneditor({ value }) {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Button variant="primary" onClick={() => setModalShow(true)}>
        + Editor
      </Button>

      <ChooseEditorVerticallyCenteredModal
        value={value}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
export default Commoneditor;
