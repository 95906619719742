import React, { useMemo, useState } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useGetAllComicUserQuery } from "./formApiSlice";
import Loader from "../GlobalComponent/Loader/Loader";
import { Modal, Button } from "react-bootstrap";

function ComicRegistrationData() {
  const { data, isLoading, isFetching, refetch } = useGetAllComicUserQuery();
  const [showModal, setShowModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  // Function to handle file view
  const handleViewFile = (fileUrl) => {
    // Check if the file is a PDF or DOC/DOCX
    if (fileUrl.endsWith(".pdf")) {
      // For PDFs, show directly in the iframe
      setFileUrl(fileUrl);
    } else if (fileUrl.endsWith(".doc") || fileUrl.endsWith(".docx")) {
      // For DOC/DOCX, use Google Docs viewer for preview
      setFileUrl(`${fileUrl}`);
    } else {
      // Default to show the file directly
      setFileUrl(fileUrl);
    }
    setShowModal(true); // Show the modal
  };

  // Function to close the modal
  const handleClose = () => setShowModal(false);

  // console.log(JSON.stringify(data?.comicUsers + "data"));
  function convertArrayOfObjectsToCSV(array) {
    if (!array || array.length === 0) {
      return "";
    }

    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]); //Use array instead of data

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }
  // function downloadCSV(array) {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv == null) return;

  //   const filename = "ContactData.csv";

  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // }

  const columns = [
    {
      name: "S No.",
      grow: 1,
      selector: (row) => row.i,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
    },
    {
      name: "Name",
      wrap: true,
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: false,
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      sortable: false,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Standard",
      selector: (row) => row.standard,
      sortable: false,
    },
    {
      name: "School",
      grow: 2,
      wrap: true,
      selector: (row) => row.school,
      sortable: false,
    },

    {
      name: "City",
      wrap: true,
      selector: (row) => row.city,
      sortable: false,
    },
    {
      name: "Story Title",
      grow: 3,
      selector: (row) => row.storyTitle,
      sortable: false,
    },
    {
      name: "Story File",

      selector: (row) =>
        row?.storyFile == undefined ? (
          "Old / No File"
        ) : (
          <a href={row?.storyFile}
          // onClick={() => handleViewFile(row.storyFile)}
          >
            View File
          </a>
        ),
      sortable: false,
    },
    // {
    //   name: "city",
    //   selector: (row) => row.city,
    //   sortable: false,
    // },
  ];
  const queries = data?.entries || [];
  const Tabledata = queries
    .slice()
    .reverse()
    .map((item, index) => ({
      i: index + 1,
      createdAt: format(new Date(item.createdAt), "MM/dd/yyyy HH:mm:ss"),
      name: item.name,
      age: item.age,
      email: item.email,
      standard: item.standard,
      school: item.school,
      contact: item.contact,
      city: item.city,
      storyTitle: item.storyTitle,
      storyFile: item.storyFile,
    }));

  function downloadCSV() {
    const csvContent = [
      "\uFEFF" +
        [
          "S No.",
          "Date",
          "Name",
          "Age",
          "Contact",
          "Email",
          "Standard",
          "School",
          "City",
          "Story Title ",
          "Story File",
        ].join(","),
      ...Tabledata.map((row) =>
        Object.values(row)
          .map((value) => (typeof value === "string" ? `"${value}"` : value))
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "ComicRegistrationData.csv");
    document.body.appendChild(link);
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Comic Registration Form</h1>
          <button
            className="btn btn-sm btn-primary shadow-sm"
            onClick={downloadCSV}
          >
            <i className="fas fa-download fa-sm text-white-50"></i> Export Data
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            {isFetching && isLoading ? (
              <Loader />
            ) : (
              <DataTable
                className="TableNs"
                columns={columns}
                data={Tabledata}
                pagination
                defaultSortDesc={true}
              />
            )}
          </div>
        </div>
        <Modal show={showModal} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>View File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Display PDF or DOC file using iframe */}
            <iframe
              src={fileUrl}
              width="100%"
              height="500px"
              title="File Viewer"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default ComicRegistrationData;
