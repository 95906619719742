import React from 'react';
import Brandslidermodel from '../../GlobalComponent/Model/Brandslidermodel';
import { Table } from 'react-bootstrap';
import './brandslider.css'
import { MdDeleteOutline } from 'react-icons/md';
import { TbEdit } from 'react-icons/tb'
import { useContext } from 'react';
import { Auth } from '../../../contextapi/Authcontext/Authcontext';
import { useDeleteSliderMutation, useGetAllSliderQuery } from '../../../features/slider/sliderApiSlice';
import Loader from '../../GlobalComponent/Loader/Loader';
import { useState } from 'react';
import swal from 'sweetalert';
import { UpdateSliderVerticallyCenteredModal } from './Updateslider';
function Brandslider() {
  const { updateModelShow, setUpdateModelShow, loader } = useContext(Auth)
  const { Swal } = useContext(Auth)
  const [id, setId] = useState(null)
  const { data, isLoading, isFetching, refetch } = useGetAllSliderQuery()
  const getSlider = useGetAllSliderQuery()
  const [deleteSlider] = useDeleteSliderMutation()
  const getId = (item) => {
    setUpdateModelShow(true)
    setId(item)
  }
  const deleteHandler = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        deleteSlider(id).unwrap().then((data) => {
          swal({
            title: data.message,
            icon: "success",
            dangerMode: true,
          })
          getSlider.refetch()
        }).catch((error) => {
          swal({
            title: error.message,
            icon: "success",
            dangerMode: true,
          })
          getSlider.refetch()
        })
      }
    })
  }
  return (
    <>
      {
        loader ? <Loader /> :
          <div className="container-fluid">
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-0 text-gray-800">Home Slider Banners</h1>
              <Brandslidermodel />
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <Table responsive="sm" >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th> Web Image </th>
                      <th> Mobile Image </th>
                      <th>Link To</th>
                      <th>Alt</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      isFetching && isLoading ? <Loader /> : data?.banners?.map((item, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td> <img style={{ width: "auto", height: "100px" }} src={item.bannerUrl_web}></img></td>
                              <td><img style={{ width: "auto", height: "100px" }} src={item.bannerUrl_mobile}></img></td>
                              <td>{item.clickUrl}</td>
                              <td>{item.alt}</td>
                              <td>
                                {/* <TbEdit onClick={() =>  getId(item) }  /> */}
                                <TbEdit className='h3 text-success' variant="primary" onClick={() => getId(item)}>
                                  Update New Slider
                                </TbEdit>
                                <MdDeleteOutline onClick={() => { deleteHandler(item._id, item) }} className='h3 ml-2 text-danger' />
                              </td>
                            </tr>
                          </>
                        )
                      })
                    }
                    <UpdateSliderVerticallyCenteredModal
                      previousData={id}
                      show={updateModelShow}
                      onHide={() => setUpdateModelShow(false)}
                    />
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
      }
    </>
  );
}

export default Brandslider;